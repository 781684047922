import { Avatar, Box, Checkbox, Grid, IconButton, LinearProgress, MenuItem, Stack, TextField, Typography } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { SubmitButton } from 'components/Buttons'
import { isEqual, pick } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/settings/campus'
import { FaSave } from 'react-icons/fa'
import { getGradeLevels } from 'store/lookup'
import { search } from 'store/settings/academic-year'
import { enqueueSnackbar } from 'notistack'
import AvatarIcon from '@mui/icons-material/PersonOutlined'

const initialValues = {
  schoolId: null,
  buildingCode: null,
  ceebCode: null,
  name: null,
  email: null,
  phone: null,
  fax: null,
  address: null,
}

export default function SettingsSchoolManagementFormCampus({ data, schools }) {
  const { districtId, campusId } = useParams()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [academicYears, setAcademicYears] = useState()
  const [stackData, setStackData] = useState()
  const [rawData, setRawData] = useState()
  const [gradeLevelTable, setGradeLevelTable] = useState()

  const [image, setImage] = useState()

  const gradeLevels = useSelector(state => state.lookup.gradeLevels)

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
  }, [])

  const { t } = useTranslation()

  const basePath = `/setting/school-management/`

  const schema = yup.object().shape({
    schoolId: yup.string().required('Required'),
    buildingCode: yup.string().required('Required'),
    name: yup.string().required('Required'),
    address: yup.object({
      street: yup.string().required('Required'),
      city: yup.string().required('Required'),
      state: yup.string().required('Required'),
      zip: yup.string().required('Required'),
    }),
  })

  const { control, handleSubmit, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data?.id
      ? {
          ...data,
          phone: data?.phones?.find(p => p.type === 'work')?.number || null,
          fax: data?.phones?.find(p => p.type === 'other')?.number || null,
        }
      : { ...initialValues },
  })

  useEffect(() => {
    if (data?.id) {
      setImage(data?.file?.location)
    }
  }, [data])

  const handleFile = async event => {
    const file = event.target.files[0]

    const formData = new FormData()
    formData.append('afile', file)
    formData.append('campusId', +campusId)
    const url = `/api/setting/campus/upload`
    const res = await window.fetch(url, { method: 'POST', body: formData })
    if (res.status !== 200) {
      enqueueSnackbar(res.statusText, { variant: 'error' })
    }
    const data = await res.json()
    setImage(data.location)
  }

  const onSubmit = async formData => {
    const address = formData.address
    if (!address?.type) address.type = 'work'
    if (data?.address?.id) {
      address.id = data?.address?.id
    }
    const pay = pick(formData, ['schoolId', 'name', 'buildingCode', 'ceebCode', 'email'])
    pay.schoolId = +pay.schoolId
    const phones = []
    if (formData.phone) {
      const phone = { type: 'work', number: +formData.phone }
      const found = data?.phones?.find(p => p.type === 'work')
      if (found && found?.id) {
        phone.id = found?.id
      }
      phones.push(phone)
    }
    if (formData.fax) {
      const fax = { type: 'other', number: +formData.fax }
      const found = data?.phones?.find(p => p.type === 'other')
      if (found && found?.id) {
        fax.id = found?.id
      }
      phones.push(fax)
    }
    const payload = {
      ...pay,
      phones,
      address,
    }
    if (data?.id) {
      payload.id = data?.id
    }

    if (stackData && stackData?.length > 0) {
      const others = stackData?.filter(item => item.academicYearId !== gradeLevelTable?.academicYearId)
      payload.campusTargetedEnrollments = [...others, gradeLevelTable]

      let temp = []
      payload.campusTargetedEnrollments?.forEach(item => {
        const targetedEnrollments = item?.targetedEnrollments?.filter(
          te => te.targetedEnrollment !== '' && te.targetedEnrollment !== null,
        )
        const tmp = { ...item, targetedEnrollments }
        temp.push(tmp)
      })
      payload.campusTargetedEnrollments = temp
    }

    const res = await dispatch(save(payload))

    if (res.payload) {
      navigate(basePath)
    }
  }

  const onErrors = errors => console.log(errors)

  useEffect(() => {
    if (data?.id) {
      reset(data)
      if (data?.phones.length > 0) {
        setValue('phone', data?.phones?.find(p => p.type === 'work')?.number, { shouldDirty: true })
        setValue('fax', data?.phones?.find(p => p.type === 'other')?.number, { shouldDirty: true })
      }

      if (data?.schoolId && campusId) {
        dispatch(search({ schoolId: data?.schoolId, districtId: +districtId, campusId: +campusId })).then(res => {
          if (res?.payload?.rows.length > 0) {
            setAcademicYears(res?.payload?.rows)
            const temp = []
            res?.payload?.rows?.forEach(item => {
              const found = data?.campusTargetedEnrollments?.find(cte => cte.academicYearId === item?.id)
              if (found) {
                temp.push(data?.campusTargetedEnrollments?.find(cte => cte.academicYearId === item?.id))
              } else {
                temp.push({
                  id: null,
                  academicYearId: item?.id,
                  campusId: +campusId,
                  targetedEnrollments: [],
                })
              }
            })
            setStackData(temp)
            setRawData(temp)

            setGradeLevelTable({
              id:
                data?.campusTargetedEnrollments?.find(cte => cte.academicYearId === res?.payload?.rows[0]?.id)?.id ||
                null,
              academicYearId: res?.payload?.rows[0]?.id,
              campusId: +campusId,
              targetedEnrollments:
                data?.campusTargetedEnrollments?.find(cte => cte.academicYearId === res?.payload?.rows[0]?.id)
                  ?.targetedEnrollments || [],
            })
          }
        })
      }
    }
  }, [data])

  const addRemoveLevel = e => {
    let targetedEnrollments = []

    if (gradeLevelTable?.targetedEnrollments.length > 0) {
      gradeLevelTable?.targetedEnrollments.forEach(item => targetedEnrollments.push(item))
    }

    if (e.target.checked) {
      const isExists = targetedEnrollments.find(item => item.gradeLevelId === e.target.value)
      if (!isExists) {
        targetedEnrollments.push({ gradeLevelId: e.target.value, targetedEnrollment: 0 })
      }
      setGradeLevelTable({ ...gradeLevelTable, targetedEnrollments })
    } else {
      if (targetedEnrollments?.length === 1) {
        setGradeLevelTable({ ...gradeLevelTable, targetedEnrollments: [] })
      } else if (targetedEnrollments?.length > 1) {
        const isExists = targetedEnrollments.find(item => item.gradeLevelId === e.target.value)
        if (isExists) {
          targetedEnrollments = targetedEnrollments.filter(item => item.gradeLevelId !== e.target.value)
          setGradeLevelTable({ ...gradeLevelTable, targetedEnrollments })
        }
      }
    }
  }

  const setTargetedEnrollment = (key, value) => {
    const found = gradeLevelTable?.targetedEnrollments?.find(item => item?.gradeLevelId === key)
    if (found) {
      let temp = { ...gradeLevelTable }
      const others = temp?.targetedEnrollments?.filter(item => item?.gradeLevelId !== key)
      temp.targetedEnrollments = [...others, { gradeLevelId: key, targetedEnrollment: value }]
      setGradeLevelTable(temp)
    } else {
      let temp = { ...gradeLevelTable }
      temp.targetedEnrollments = temp.targetedEnrollments
        ? [...temp.targetedEnrollments, { gradeLevelId: key, targetedEnrollment: value }]
        : [{ gradeLevelId: key, targetedEnrollment: value }]
      setGradeLevelTable(temp)
    }
  }
  const changeAcademicYear = academicYearId => {
    setGradeLevelTable(undefined)
    const selected = stackData?.find(item => item?.academicYearId === academicYearId)
    const others = stackData?.filter(item => item?.academicYearId !== gradeLevelTable?.academicYearId)

    setStackData([...others, gradeLevelTable])
    setGradeLevelTable(selected)
  }

  const schoolId = useWatch({ control, name: 'schoolId' })

  const reorderArray = arr => {
    const half = Math.ceil(arr.length / 2)
    const firstHalf = arr.slice(0, half)
    const secondHalf = arr.slice(half)

    let reordered = []
    for (let i = 0; i < half; i++) {
      if (firstHalf[i] !== undefined) reordered.push(firstHalf[i])
      if (secondHalf[i] !== undefined) reordered.push(secondHalf[i])
    }

    return reordered
  }

  const isDisabled = id => {
    if (gradeLevelTable.academicYearId === +id) return false
    const rawGradeLevelTable = rawData?.find(item => item.academicYearId === gradeLevelTable.academicYearId)
    if (isEqual(rawGradeLevelTable, gradeLevelTable)) return false
    return true
  }

  if (campusId && campusId !== 'new' && !data?.id) return <LinearProgress sx={{ m: 2 }} color="success" />

  if (!gradeLevels) return null

  const gradeLevelList = reorderArray(gradeLevels)

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="SettingsSchoolManagementFormDistrict"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={+districtId && +campusId ? `Edit Campus` : t('Add Campus')} 
          avatar={
            <IconButton component="label" aria-label="district-logo" disabled={data.id ? false : true}>
            <input hidden accept="image/*" type="file" onChange={handleFile} />
            {image ? (
              <Avatar sx={{ width: 80, height: 80, fontSize: 20 }} src={image}>
                <AvatarIcon />
              </Avatar>
            ):(
              <Avatar sx={{ width: 80, height: 80 }}>
              {data?.name.split(' ')[0]}
            </Avatar>
            )}
          </IconButton>
          }
          small close borderBottom 
        />
        <Grid container sx={{ p: 3 }} spacing={2}>
          <Grid item xs={6} md={6}>
            <Controller
              name="schoolId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('School')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                    disabled={data?.schoolId}
                  >
                    {schools.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Name')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="buildingCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Building Code')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="ceebCode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('CEEB Code')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="address.street"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Address')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={7} md={7}>
            <Controller
              name="address.city"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('City')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={2.5} md={2.5}>
            <Controller
              name="address.state"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('State')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={2.5} md={2.5}>
            <Controller
              name="address.zip"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Zip')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Phone')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Controller
              name="fax"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Fax')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    size="small"
                    label={t('Email')}
                    error={!!fieldState.error}
                    {...field}
                    InputLabelProps={{ shrink: field.value ? true : false }}
                  />
                )
              }}
            />
          </Grid>
        </Grid>

        {data?.id && (
          <Box sx={{ mx: 3 }}>
            <Typography variant="h6">{t('Grade Levels')}</Typography>
            <Grid container sx={{ mt: 1 }} spacing={2}>
              <Grid item xs={12} md={12}>
                <TextField
                  disabled={!schoolId}
                  select
                  fullWidth
                  size="small"
                  label={t('Academic Year')}
                  onChange={e => changeAcademicYear(e.target.value)}
                  value={gradeLevelTable?.academicYearId || null}
                  InputLabelProps={{ shrink: gradeLevelTable?.academicYearId ? true : false }}
                >
                  {academicYears?.map((item, index) => (
                    <MenuItem key={index} value={item.id} disabled={isDisabled(item.id)}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                container
                spacing={2}
                sx={{
                  mt: 1,
                  paddingLeft: '7px',
                }}
              >
                {gradeLevelList.map((grade, index) => (
                  <Grid key={index} item xs={6} md={6}>
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Checkbox
                        disabled={!schoolId}
                        onClick={e => addRemoveLevel(e)}
                        color="primary"
                        size="small"
                        sx={{ width: '50px' }}
                        value={grade?.id}
                        checked={
                          gradeLevelTable?.targetedEnrollments?.find(item => item?.gradeLevelId == grade?.id)
                            ? true
                            : false
                        }
                      />
                      <Typography disabled={!schoolId} sx={{ width: '100px', pr: 1 }} align="right">
                        {grade.name}
                      </Typography>
                      <TextField
                        disabled={!schoolId}
                        onChange={e => setTargetedEnrollment(grade?.id, e.target.value)}
                        fullWidth
                        size="small"
                        placeholder="Targeted Enrollment"
                        value={
                          gradeLevelTable?.targetedEnrollments.find(item => item.gradeLevelId === grade?.id)
                            ?.targetedEnrollment || ''
                        }
                      />
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        )}

        <Stack flexDirection={'row'} justifyContent={'flex-end'} sx={{ mr: 3, mt: 3, mb: 3 }}>
          <SubmitButton
            onClick={handleSubmit(onSubmit)}
            startIcon={<FaSave />}
            icon={null}
            title="Save"
            color="primary"
          />
        </Stack>
      </Box>
    </BasePathProvider>
  )
}
