import { Box, Button, Step, StepLabel, Stepper } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import SchedulingCourseSessionsFormSessionDetails from './SessionDetails'
import SchedulingCourseSessionsFormAssignTeacher from './AssignTeacher'
import SchedulingCourseSessionsFormSetSchedule from './SetSchedule'
import SchedulingCourseSessionsFormEnrollStudent from './EnrollStudent'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const steps = ['Session Details', 'Assign Teacher(s)', 'Set Schedule', 'Enroll Students']

export default function SchedulingCourseSessionsForm({ control, setValue, getValues, onSubmitSessionDetails }) {
  const { t } = useTranslation()
  const { courseSessionId } = useParams()

  const theme = useSelector(state => state.session.appearance)

  const basePath = `/settings/scheduling/course-sessions/`

  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="SchedulingCourseSessionsForm"
        component="form"
        // onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header
          title={courseSessionId ? getValues()?.courseSession?.name : t('Course Sessions')}
          small
          close
          borderBottom
        />
        <Box sx={{ width: '100%', p: 3, pl: 2 }}>
          <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
            {steps.map((label, index) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={label} {...stepProps} completed={false}>
                  <StepLabel
                    disabled={true}
                    onClick={courseSessionId === 'new' && index > 0 ? null : () => setActiveStep(index)}
                    sx={{ '.Mui-active circle': { color: 'primary' } }}
                    {...labelProps}
                  >
                    <Box sx={{ cursor: 'pointer' }}>{t(label)}</Box>
                  </StepLabel>
                </Step>
              )
            })}
          </Stepper>
          {activeStep === steps.length ? (
            <>Bla 1</>
          ) : (
            <>
              {activeStep === 0 && (
                <SchedulingCourseSessionsFormSessionDetails
                  control={control}
                  setValue={setValue}
                  getValues={getValues}
                  onSubmitSessionDetails={onSubmitSessionDetails}
                />
              )}
              {activeStep === 1 && <SchedulingCourseSessionsFormAssignTeacher control={control} />}
              {activeStep === 2 && <SchedulingCourseSessionsFormSetSchedule control={control} />}
              {activeStep === 3 && <SchedulingCourseSessionsFormEnrollStudent control={control} />}
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                {activeStep > 0 && (
                  <Button
                    size="small"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, color: theme === 'dark' ? 'rgba(255, 255, 255, 0.38)' : 'rgba(0, 0, 0, 0.38)' }}
                  >
                    {/* {t('Previous')} */}
                  </Button>
                )}

                <Box sx={{ flex: '1 1 auto' }} />

                <Button size="small" color="primary" onClick={handleNext}>
                  {/* {activeStep === steps.length - 1 ? t('Close') : t('Next')} */}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </BasePathProvider>
  )
}
