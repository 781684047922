import { Autocomplete, Box, Button, Grid, LinearProgress, MenuItem, TextField } from '@mui/material';
import { BasePathProvider, useBasePath } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Header from 'components/Header'
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux';
import { save, getIncidentItems, destroy } from 'store/discipline/incident'
import { getAllStudents, getLocations, getDiscipineIncidentCategories } from "store/lookup";
import { useEffect, useState } from 'react';
import Confirm from 'components/Dialog';
import { FaSave } from 'react-icons/fa'
import { DeleteIcon } from 'components/Icons'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import _ from 'lodash'

export default function DisciplineAddIncidentForm({ disciplineIncident }) {
    const { t } = useTranslation()
    const basePath = useBasePath()
    const { id } = useParams()
    const [confirm, setConfirm] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { academicYear, person } = useSelector(state => state.session)
    const allStudents = useSelector(state => state.lookup.allStudents.rows)
    const location = useSelector(state => state.lookup.location)
    const incidentItems = useSelector((state) => state.discipline.disciplineIncident.incidentItems);
    const discipineIncidentCategory = useSelector(state => state.lookup.discipineIncidentCategory)
    const [filteredIncidentItems, setFilteredIncidentItems] = useState([]);

    useEffect(() => {
        allStudents ||
          dispatch(getAllStudents({ limit: 1000 }))
          .catch(error => {
              console.error(error)
          })
      }, [dispatch, Boolean(allStudents), academicYear.id])

      useEffect(() => {
        location ||
          dispatch(getLocations()).catch(error => {
            console.error(error)
          })
      }, [dispatch, Boolean(location)])

      useEffect(() => {
        discipineIncidentCategory ||
          dispatch(getDiscipineIncidentCategories()).catch(error => {
            console.error(error)
          })
      }, [dispatch, Boolean(discipineIncidentCategory)])

    useEffect(() => {
        incidentItems ||
          dispatch(getIncidentItems()).catch(error => {
            console.error(error)
          })
      }, [dispatch, Boolean(incidentItems)])

    const selectedIncidentItem = incidentItems?.find(
        (item) => item.id === disciplineIncident?.incidentItemId
    );
    
    const meritType = selectedIncidentItem?.meritType || ''; 
    const incidentCategoryId = selectedIncidentItem?.incidentCategoryId || null;
      
    const schema = yup.object().shape({
        studentIds: yup.array().of(yup.number()).required('Required'),
        staffId: yup.number().required('Required'),
        incidentItemId: yup.number().required('Required'),
        incidentDate: yup.date().required('Required'),
        explanation: yup.string(),
        locationId: yup.string().required('Required'),
        meritType: yup.string().required('Required'),
        incidentCategoryId: yup.string().required('Required'),
    })

    const { control, formState, watch, handleSubmit } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            ...disciplineIncident,
            staffId: person.id,
            incidentDate: disciplineIncident?.incidentDate ? dayjs(disciplineIncident.incidentDate).isValid() ? dayjs(disciplineIncident.incidentDate) : null : dayjs().startOf('minute'),
            studentIds: disciplineIncident?.studentId ? [disciplineIncident.studentId] : [],
            meritType,
            incidentCategoryId,
        },
    })

    const onSubmit = async formData => {
        const data = {
            ...formData,
            academicYearId: academicYear.id,
        }
        let exceptions = ['incidentCategoryId', 'meritType']
        if (id !== 'new') {
            exceptions.push('student', 'staff', 'incidentItem', 'studentId', 'studentIds', 'staffId', 'academicYearId')
          }

        dispatch(save(_.omit(data, exceptions)))
        navigate(`${basePath}`)
    }

    const onErrors = errors => console.log(errors)

    const onDestroy = async () => {
        setConfirm(false)
        await dispatch(destroy({ id: disciplineIncident.id }))
        navigate(basePath)
      }

    const selectedMeritType = watch('meritType');
    const selectedIncidentCategoryId = watch('incidentCategoryId');

    useEffect(() => {
        if (incidentItems) {
            const filtered = incidentItems.filter(item => {
                const matchesMeritType = selectedMeritType ? item.meritType === selectedMeritType : true;
                const matchesCategory = selectedIncidentCategoryId ? item.incidentCategoryId === selectedIncidentCategoryId : true;
                return matchesMeritType && matchesCategory;
            });
            setFilteredIncidentItems(filtered);
        }
    }, [incidentItems, selectedMeritType, selectedIncidentCategoryId]);

    const isDataLoading = !(
        allStudents && 
        location && 
        discipineIncidentCategory && 
        incidentItems
    );

    if (isDataLoading) {
        return (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )
      }

    return (
        <BasePathProvider value={`${basePath}`}>
            <Box
                data-component="DisciplineAddIncidentForm"
                component="form"
                onSubmit={handleSubmit(onSubmit, onErrors)}
                noValidate
                sx={{ overflowY: 'scroll', border: 'none' }}
                >
                <Header title={id === 'new' ? t('Add New Incident') : t('Edit Incident')} small close borderBottom />
            <Grid container spacing={1} sx={{ p: 2 }}>
            
                <Grid item xs={12} >
                    <Controller
                        name='staffId'
                        rules={{ required: t('Staff is required') }}
                        control={control}
                        render={({ field: { ...field }, fieldState }) => {

                                return (
                                    <TextField
                                        fullWidth
                                        {...field}
                                        size='small'
                                        value={`${person?.lastName}, ${person?.firstName}`}
                                        label={t('Staff')}
                                        error={Boolean(fieldState.error)}
                                        helperText={fieldState.error ? fieldState.error.message : ''}
                                        disabled
                                    />
                                );
        
                        }}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Controller
                        name='studentIds'
                        rules={{ required: t('Student is required') }}
                        control={control}
                        render={({ field: { ...field }, fieldState }) => {
                            return (
                                <Autocomplete 
                                    multiple
                                    options={allStudents}
                                    getOptionLabel={(option) => `${option?.person?.lastName}, ${option?.person?.firstName}`}
                                    getOptionSelected={(option, value) => option.personId === value.personId}
                                    onChange={(event, value) => {
                                        field.onChange(value.map(item => item.personId))
                                    }}
                                    value={
                                        (allStudents || []).filter(item => (field.value || []).includes(item.personId)) || []
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Select Student(s)')}
                                            error={Boolean(fieldState.error)}
                                            helperText={fieldState.error?.message}
                                            size='small'
                                        />
                                    )}
                                    disabled={!isNaN(parseInt(id))}
                                />
                            );
                        }}
                    />
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%', pl: 1, pt: 1}} >
                    <Grid item xs={6}>                        
                        <Controller
                            name='incidentDate'
                            rules={{ required: t('Date/Time is required') }}
                            control={control}
                            render={({ field: field, onChange, value, fieldState }) => {
                                return (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            label={t('Date/Time')}
                                            sx={{ width: '100%' }}
                                            value={value}
                                            onChange={(newValue) => { 
                                                onChange(newValue);
                                            }}
                                            {...field}
                                            slotProps={{
                                                textField: {
                                                size: 'small',
                                                helperText: fieldState.error?.message,
                                                error: !!fieldState.error
                    
                                                }
                                            }}
                                        />
                                    </LocalizationProvider>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>                        
                        <Controller
                            name='locationId'
                            rules={{ required: t('Location is required') }}
                            control={control}
                            render={({ field: field, fieldState }) => {
                                return (
                                    <Autocomplete
                                        options={location || []}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        isOptionEqualToValue={(option, value) => option.id === value?.id} 
                                        value={location?.find(item => item.id === field.value) || null}
                                        onChange={(event, newValue) => { 
                                            field.onChange(newValue ? newValue.id : null);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...field}
                                                label={t('Location')}
                                                error={Boolean(fieldState.error)}
                                                helperText={fieldState.error?.message}
                                                size='small'
                                            />
                                        )}
                                />
                                );
                            }}
                        />
                    </Grid>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%', pl: 1, pt: 1}} >
                    <Grid item xs={6}>                        
                        <Controller
                            name='meritType'
                            rules={{ required: t('Merit Type is required') }}
                            control={control}
                            render={({ field: field, fieldState }) => {
                                return (
                                    <TextField
                                    select
                                    fullWidth
                                    size="small"
                                    label={t('Type')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    {...field}
                                  >
                                    <MenuItem value={'merit'}>{t('Merit')}</MenuItem>
                                    <MenuItem value={'demerit'}>{t('Demerit')}</MenuItem>
                                  </TextField>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>                        
                        <Controller
                            name='incidentCategoryId'
                            rules={{ required: t('Category is required') }}
                            control={control}
                            render={({ field: field, fieldState }) => {
                                return (
                                    <Autocomplete
                                        options={discipineIncidentCategory || []}
                                        getOptionLabel={(option) => option.name}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        isOptionEqualToValue={(option, value) => option.id === value?.id} 
                                        value={discipineIncidentCategory?.find(item => item.id === field.value) || null}
                                        onChange={(event, newValue) => { 
                                            field.onChange(newValue ? newValue.id : null);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...field}
                                                label={t('Category')}
                                                error={Boolean(fieldState.error)}
                                                helperText={fieldState.error?.message}
                                                size='small'
                                            />
                                        )}
                                />
                                );
                            }}
                        />
                    </Grid>
                </Box>
                <Grid item xs={12}>                        
                        <Controller
                            name='incidentItemId'
                            rules={{ required: t('Incident Item is required') }}
                            control={control}
                            render={({ field: field, fieldState }) => {
                                return (
                                    <Autocomplete
                                        options={filteredIncidentItems || []}
                                        getOptionLabel={(option) => `${option.name} (${option.point})`}
                                        getOptionSelected={(option, value) => option.id === value.id}
                                        isOptionEqualToValue={(option, value) => option.id === value?.id} 
                                        value={filteredIncidentItems?.find(item => item.id === field.value) || null}
                                        onChange={(event, newValue) => { 
                                            field.onChange(newValue ? newValue.id : null);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                {...field}
                                                label={t('Discipline Item')}
                                                error={Boolean(fieldState.error)}
                                                helperText={fieldState.error?.message}
                                                size='small'
                                            />
                                        )}
                                />
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="explanation"
                            control={control}
                            rules={{ required: 'Explanation is required' }}
                            render={({ field, fieldState }) => {
                            return (
                                <TextField
                                fullWidth
                                multiline
                                size='small'
                                minRows={3}
                                label={t('Explanation')}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                {...field}
                                >
                                </TextField>
                            )
                            }}
                        />
                </Grid>
                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: '10px', pt: 2}}>
                    <Button
                        size="medium"
                        variant="contained"
                        color="error"
                        disabled={!disciplineIncident}
                        onClick={() => setConfirm(true)}
                        startIcon={<DeleteIcon />}
                        title="Delete"
                    >
                        {t('DELETE')}
                    </Button>
                    <Button
                        type="submit"
                        size="medium"
                        variant="contained"
                        startIcon={<FaSave />}
                        disabled={!formState.isDirty} >
                        {t('Save')}
                    </Button>
                </Box>
                <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
            </Grid>
        </Box>
        </BasePathProvider>
    );
};

