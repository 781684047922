import { Button, CircularProgress } from '@mui/material'
import { useState } from 'react'
import { AddIcon, DeleteIcon, PersonAddIcon } from './Icons'
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next'

function FormButton({ onClick, ...props }) {
  const [isPending, setIsPending] = useState()

  const handleClick = async () => {
    try {
      setIsPending(true)
      await onClick()
    } finally {
      setIsPending(false)
    }
  }

  return (
    <Button disabled={isPending} type="submit" onClick={handleClick} variant="contained" {...props}>
      {isPending && <CircularProgress color="inherit" size="1rem" sx={{ marginRight: '5px' }} />}

      {!isPending && props.icon}

      {props.title}
    </Button>
  )
}

export function SubmitButton({ onClick, ...props }) {
  const { t } = useTranslation()
  return (
    <FormButton 
      onClick={onClick} 
      icon={<SaveIcon sx={{mr: 1, fontSize: '20px'}}/>} 
      title={t("Submit")} 
      {...props} />
  )
}

export function DeleteButton({ onClick, ...props }) {
  const { t } = useTranslation()
  return <FormButton onClick={onClick} icon={<DeleteIcon />} title={t("Delete")} {...props} />
}

export function CreateButton({ onClick, ...props }) {
  const { t } = useTranslation()
  return <FormButton onClick={onClick} icon={<AddIcon />} title={t("Create")} {...props} />
}

export function AddPersonButton({ onClick, ...props }) {
  const { t } = useTranslation()
  return <FormButton onClick={onClick} startIcon={<PersonAddIcon />} title={t("Add Person")} {...props} />
}
