import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { search } from 'store/settings/district'
import PageHeader from 'components/AppBar/PageHeader'
import { BasePathProvider } from 'components/Providers'
import { getCountry } from 'store/lookup'
import DistrictListSkeleton from './List/ListSkeleton'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Card, Drawer } from '@mui/material'
import DistrictListIndex from './List'
import DistrictFormIndex from './Form'
import { useTranslation } from 'react-i18next'

const baseUrl = '/settings/district'

export default function DistrictPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const districts = useSelector(state => state.settings.district.list)
  const { country } = useSelector(state => state.lookup)
  const { id } = useParams()
  const { t } = useTranslation()

  const [isLoaded, setIsLoaded] = useState()

  useEffect(() => {
    if (!districts) {
      setIsLoaded(false)
      dispatch(search())
    }
  }, [districts])

  useEffect(() => {
    if (!country) {
      setIsLoaded(false)
      dispatch(getCountry())
    }
  }, [country])

  useEffect(() => {
    if (districts && country) {
      setIsLoaded(true)
    }
  }, [districts, country])

  return (
    <BasePathProvider value={`${baseUrl}`}>
      <Box sx={{ my: 2, mx: -3 }}>
        <PageHeader title={'District'} />
      </Box>
      <Card data-component="DistrictList" sx={{ minHeight: '50%', mx: -3 }}>
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, mr: 2 }}>
          <Button onClick={() => navigate(`${baseUrl}/new`)} sx={{ width: '5em' }} variant="contained" color="primary">
            {t('Add')}
          </Button>
        </Box>
        {isLoaded ? <DistrictListIndex /> : <DistrictListSkeleton />}
      </Card>
      <Drawer
        PaperProps={{
          sx: {
            width: 600,
          },
        }}
        open={Boolean(id)}
        anchor="right"
        onClose={() => navigate(baseUrl)}
      >
        <DistrictFormIndex />
      </Drawer>
    </BasePathProvider>
  )
}
