import {Route, Routes } from 'react-router-dom'
import PageHeader from 'components/AppBar/PageHeader'
import { BasePathProvider } from 'components/Providers'
import { Box, Paper } from '@mui/material' 
import Incidents from './Incident'
import DisciplineTabs from './Tabs'

export default function Discipline() {
const basePath = '/discipline'
  return (
    <BasePathProvider value={`${basePath}/`}>
        <Box sx={{ mb: 2}}>
            <PageHeader title={'Discipline'} />
        </Box>
        <Paper sx={{width: '100%', minHeight: '500px', p: 2, mb: 2}}>
            <DisciplineTabs />
            <Routes>
                <Route path="/">
                    <Route path="" element={<Incidents />} />
                    <Route path={"incidents/*"} element={<Incidents />} />
                </Route>
            </Routes>
        </Paper>
    </BasePathProvider>
  )
}
