import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBasePath, BasePathProvider } from 'components/Providers'
import { useForm, Controller } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import * as yup from 'yup'
import Header from 'components/Header'
import { Button, Card, CardActions, CardContent, Grid, TextField } from '@mui/material'
import { save } from 'store/settings/room'
import { FaSave } from 'react-icons/fa'
import { DeleteIcon } from 'components/Icons'
import { destroy } from 'store/settings/room'
import Confirm from 'components/Dialog'

export default function RoomEditForm({ room }) {
  const [confirm, setConfirm] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()

  const schema = yup.object().shape({
    name: yup.string().required('Required'),
  })

  const { control, handleSubmit, reset, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { roomId: room?.id },
  })

  useEffect(() => {
    reset(room)
  }, [room, reset])

  const onSubmit = async data => {
    const res = await dispatch(save(_.omit(data, 'campus', 'campusId')))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onConfirm = async () => {
    setConfirm(false)
    dispatch(destroy({ id: room.id }))
    navigate(basePath)
  }

  const onError = errors => console.error(errors)

  return (
    <BasePathProvider value={`${basePath}/rooms`}>
      <Card
        data-component="RoomEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        sx={{ minHeight: '100%' }}
      >
        <Header title={t('Add Room')} small close />
        <CardContent>
          <Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Controller
                name="name"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <TextField
                      autoFocus
                      fullWidth
                      size="small"
                      label={t('Room Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      value={value || ''}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', mr: 1 }}>
          <Button
            variant="contained"
            color="error"
            disabled={!room}
            onClick={() => setConfirm(true)}
            startIcon={<DeleteIcon />}
          >
            {t('DELETE')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!formState.isDirty}
            startIcon={<FaSave />}
          >
            {t('SAVE')}
          </Button>
          <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
        </CardActions>
      </Card>
    </BasePathProvider>
  )
}
