import { Box, IconButton, Stack, Typography } from '@mui/material'
import { AddIcon, CloseIcon, PrintIcon, PrevIcon, NextIcon } from './Icons'
import { useBasePath } from './Providers'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

const Header = ({
  title,
  subtitle,
  printTitle,
  printSubtitle,
  small,
  add = null,
  close = null,
  print = null,
  printfunc,
  prev,
  next,
  avatar = null,
  borderBottom,
  children,
  ...props
}) => {
  const basePath = useBasePath()
  const navigate = useNavigate()
  const theme = useTheme()

  return (
    <Box
      data-component="Header"
      sx={{ p: 2, borderBottom: borderBottom ? '1px solid' : 'none', borderColor: theme.palette.action.disabled }}
      {...props}
    >
      <Stack sx={{ displayPrint: 'none' }} direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
        {avatar}
        <Box sx={{ flexGrow: 1, alignSelf: "center"}}>
          <Typography
            aria-label={title}
            variant={small ? 'h6' : 'h4'}
            sx={{ fontWeight: small ? 300 : 400 }}
          >
            {title}
          </Typography>
          <Typography variant="body" gutterBottom sx={{ fontWeight: 400, displayPrint: 'none' }}>
            {subtitle}
          </Typography>
          {children}
        </Box>
        <Box>
          {prev && (
            <IconButton aria-label="prev" onClick={prev}>
              <PrevIcon />
            </IconButton>
          )}
          {next && (
            <IconButton aria-label="next" onClick={next}>
              <NextIcon />
            </IconButton>
          )}
          {add && (
            <IconButton
              aria-label="add"
              onClick={() => {
                typeof add === 'function' ? add(`${basePath}/new`) : navigate(`${basePath}/new`)
              }}
            >
              <AddIcon />
            </IconButton>
          )}
          {close && (
            <IconButton aria-label="close" onClick={() => navigate(`${basePath}/..`)}>
              <CloseIcon />
            </IconButton>
          )}
          {print && (
            <IconButton aria-label="print" onClick={printfunc}>
              <PrintIcon />
            </IconButton>
          )}
        </Box>
      </Stack>

      <Stack
        sx={{ display: 'none', displayPrint: 'block' }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        {printTitle} {printSubtitle}
      </Stack>
    </Box>
  )
}

export default Header
