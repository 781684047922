import { Box, Button } from "@mui/material";
import Header from "components/Header";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBasePath } from "components/Providers";
import IncidentsList from "./List";
import IncidentFilter from "../IncidentFilter";
import { useState } from "react";
import moment from "moment";

const getDefaultDateRange = () => {
    const today = moment();
    const startDate = moment().subtract(7, 'days');
    return {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: today.format('YYYY-MM-DD'),       
    };
  };
  
export default function IncidentListIndex() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const basePath = useBasePath()
    const [dateRange, setDateRange] = useState(getDefaultDateRange());

    const handleDateChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
              <Header title={t('Incidents')} small />
              <Button
                size='small'
                variant='contained'
                color='primary'
                onClick={() => navigate(`${basePath}/new`)}
                sx={{ mr: 2 }}
            >
                {t('Add Incident')}
            </Button>
            </Box>
                <IncidentFilter dateRange={dateRange} onDateChange={handleDateChange} />
                <IncidentsList dateRange={dateRange} />
            </Box>
    )
}
