import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CardContent, Grid, TextField, Button, Card, CardActions, MenuItem } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { DeleteIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { destroy } from 'store/nurse/nurse-health-condition'
import { save } from 'store/nurse/nurse-health-condition'
import { getNurseConditionTypes } from 'store/lookup'
import { FaSave } from 'react-icons/fa'

export default function NurseAddConditionForm({ healthCondition, selected }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const basePath = useBasePath()
  const { id } = useParams()
  const [confirm, setConfirm] = useState(false)
  const nurseConditionType = useSelector(state => state.lookup.nurse.conditionType)

  const schema = yup.object().shape({
    visibleToStaff: yup.bool().required('Required'),
    conditionTypeId: yup.string().required('Required'),
    explanation: yup.string().required('Required'),
    medication: yup.string().required('Required'),
    administer: yup.string().required('Required'),
  })

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...healthCondition,
      studentName: `${selected.person.lastName}, ${selected.person.firstName}`,
      healthConditionId: healthCondition?.id,
      studentId: selected.personId,
    },
  })

  const onSubmit = async data => {
    let exceptions = ['studentName', 'healthConditionId']
    if (id !== 'new') {
      exceptions.push('studentId')
    }

    const res = await dispatch(save(_.omit(data, exceptions)))
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onDestroy = async () => {
    setConfirm(false)
    await dispatch(destroy({ id: healthCondition.id }))
    navigate(basePath)
  }

  useEffect(() => {
    nurseConditionType ||
      dispatch(getNurseConditionTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, nurseConditionType])

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box sx={{ overflowY: 'scroll', border: 'none' }}>
        <Header title={id === 'new' ? t('Add Condition') : t('Edit Condition')} small close borderBottom />
      </Box>
      <Card data-component="NurseAddConditionForm" elevation={0} component="form" onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={12} xs={12} md={12}>
              <Controller
                name="studentName"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Student')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disabled
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <Controller
                name="visibleToStaff"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Visible To Staff')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      <MenuItem value={true}>{t('Yes')}</MenuItem>
                      <MenuItem value={false}>{t('No')}</MenuItem>
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <Controller
                name="conditionTypeId"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      select
                      fullWidth
                      size="small"
                      label={t('Type')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                      {nurseConditionType?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <Controller
                name="explanation"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Explanation')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <Controller
                name="medication"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Medication')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} xs={12} md={12}>
              <Controller
                name="administer"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      size="small"
                      label={t('Administer')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', mr: 1 }}>
          <Button
            size="mdium"
            variant="contained"
            color="error"
            disabled={!healthCondition}
            onClick={() => setConfirm(true)}
            startIcon={<DeleteIcon />}
            title="Delete"
          >
            {t('DELETE')}
          </Button>
          <Button 
            type="submit" 
            size="medium" 
            variant="contained" 
            startIcon={<FaSave />} 
            disabled={!formState.isDirty} >
            {t('Save')}
          </Button>
        </CardActions>
        <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
      </Card>
    </BasePathProvider>
  )
}
