import {
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CourseList from './CourseList'
import { debounce, omit } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { search } from 'store/provider/course'
import { getCourseSubjects } from 'store/lookup'

export default function SchedulingCoursesFormSearchBar({
  courseType,
  setCourseType,
  selectedCourse,
  setSelectedCourse,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const theme = useSelector(state => state.session.appearance)
  const courseSubjects = useSelector(state => state.lookup.courseSubjects)
  const { query } = useSelector(state => state.provider.course)
  const [que, setQue] = useState(query.name || '')

  const applyFilter = async (key, value) => {
    if (value) {
      dispatch(search({ ...query, [key]: value, offset: 0 }))
    } else {
      dispatch(search({ ...omit(query, key), offset: 0 }))
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('name', que), 500)
  }, [dispatch])

  useEffect(() => {
    courseSubjects || dispatch(getCourseSubjects())
  }, [])

  return (
    <Grid container sx={{ p: 3 }} spacing={2}>
      <Grid item xs={12} md={12}>
        <Typography
          sx={{
            color: theme === 'dark' ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '12px',
            letterSpacing: '0.15px',
          }}
        >
          {t('Course Type')}
        </Typography>
        <RadioGroup row sx={{ pl: 2 }}>
          <FormControlLabel
            value={courseType}
            control={<Radio color="primary" />}
            label={t('Core or Elective Course')}
            checked={courseType === 'sced'}
            onChange={() => {
              // applyFilter('courseType', event.target.value)
              setCourseType('sced')
            }}
          />
          <FormControlLabel
            value={courseType}
            control={<Radio color="primary" />}
            label={t('Non-SCED Course')}
            checked={courseType === 'non-sced'}
            onChange={() => {
              // applyFilter('courseType', event.target.value)
              setSelectedCourse(null)
              setCourseType('non-sced')
            }}
          />
        </RadioGroup>
      </Grid>
      {courseType === 'sced' && (
        <Grid item xs={12} md={12}>
          <TextField
            color="primary"
            size="small"
            fullWidth
            select
            label={t('Subject Area')}
            value={query?.subjectId || 'all'}
            onChange={event => {
              applyFilter('subjectId', +event.target.value || '')
            }}
          >
            <MenuItem value={'all'}>
              <em>All</em>
            </MenuItem>
            {courseSubjects.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
      {courseType === 'sced' && (
        <Grid item xs={12} md={12}>
          <TextField
            size="small"
            value={que}
            onChange={event => {
              setQue(event.target.value)
              delayedSearch(event.target.value, applyFilter)
            }}
            fullWidth
            placeholder="Course Name"
            label={t('Search')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <CloseIcon
                  onClick={() => {
                    setQue('')
                    applyFilter('name', '')
                  }}
                  sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
                />
              ),
            }}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        {courseType === 'sced' && <CourseList selectedCourse={selectedCourse} setSelectedCourse={setSelectedCourse} />}
      </Grid>
    </Grid>
  )
}
