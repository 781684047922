import { Box, Button, CardContent, Grid, Table, TableBody, TableHead, TableRow } from '@mui/material'
import { useSelector } from 'react-redux'
import TableHeadCell from 'components/TableHeadCell'
import GradeSectionItem from './ListItem'
import { useTranslation } from 'react-i18next'
import Header from 'components/Header'
import { useNavigate } from 'react-router-dom'
import { useBasePath } from 'components/Providers'

export default function GradeSectionList() {
  const gradeSections = useSelector(state => state.settings.gradeLevelSection.list)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const basePath = useBasePath()

  return (
    <Box data-component="GradeSectionList" sx={{ minHeight: '50%' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} sx={{ pt: 2, }}>
        <Header title={t('Grade Section')} small />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => navigate(`${basePath}/new`)}
          sx={{ mr: 2 }}
        >
          {t('Add Grade Section')}
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item xs={12} md={12}>
          <Box sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pr: 0, pl: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell sx={{ typography: 'body1', width: '22%'}}>{t('Section Name')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '22%' }}>{t('Grade Level')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '22%' }}>{t('Maximum Seats')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '22%' }}>{t('Default Schedule')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '10%' }}>{t('Actions')}</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gradeSections?.map(item => {
                    return <GradeSectionItem key={item.id || item.uid} gradeSection={item} />
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
