import { Route, Routes } from 'react-router-dom'
import EnrollmentsPage from './Page'
import EnrollmentsFormIndex from './Form'

export default function Enrollments() {
  return (
    <Routes>
      <Route path="/" element={<EnrollmentsPage />}>
        <Route path="/:details" element={<EnrollmentsFormIndex />} />
      </Route>
    </Routes>
  )
}
