import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Stack, Typography } from '@mui/material'
import { DownIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FeildSkeleton from './FieldSkeleton'

export default function GradingListCoefficient({ data }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useSelector(state => state.session.appearance)

  const basePath = '/settings_/grading'

  if (!data) return null

  return (
    <Accordion defaultExpanded sx={{ mb: 1 }}>
      <AccordionSummary expandIcon={<DownIcon />}>
        <Typography>Grading and Weighted GPA</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
          <Button
            onClick={() => navigate(`${basePath}/coefficient/edit`)}
            size="small"
            variant="contained"
            color="primary"
          >
            {t('Edit')}
          </Button>
        </Stack>

        <Grid container spacing={2}>
          <Grid item sm={4} xs={4} md={4}>
            <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
              {t('AP Course Coefficient')}
            </Typography>
            <Typography variant="body1" className={`grid-table-title-value`}>
              {data?.ap || <FeildSkeleton />}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} md={4}>
            <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
              {t('Honors Course Coefficient')}
            </Typography>
            <Typography variant="body1" className={`grid-table-title-value`}>
              {data?.honors || <FeildSkeleton />}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} md={4}>
            <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
              {t('College Prep. Course Coefficient')}
            </Typography>
            <Typography variant="body1" className={`grid-table-title-value`}>
              {data?.collegePrep || <FeildSkeleton />}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} md={4}>
            <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
              {t('Unweighted Course Coefficient')}
            </Typography>
            <Typography variant="body1" className={`grid-table-title-value`}>
              {data?.unweighted || <FeildSkeleton />}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} md={4}>
            <Typography className={`grid-table-title-${theme}`} variant="body2" sx={{ fontSize: '12px' }}>
              {t('Min Failing Grade')}
            </Typography>
            <Typography variant="body1" className={`grid-table-title-value`}>
              {data?.minGrade || <FeildSkeleton />}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
