import { useTheme } from '@emotion/react'
import { Button, Card, CardActions, CardContent, CardHeader, Divider, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function PreviewCard({
  children,
  title,
  buttonLabel,
  buttonNavigate,
  buttonDisabled,
  buttonColor,
  otherButton,
  size,
  actionChildren,
  onlyReadable,
}) {
  const theme = useTheme()
  const themeMode = useSelector(state => state.session.appearance)

  const actions = []
  if (otherButton) {
    actions.push(otherButton)
  }
  if (!onlyReadable && buttonNavigate) {
    actions.push({ buttonLabel, buttonNavigate, buttonDisabled, buttonColor })
  }

  return (
    <Stack alignItems="stretch" sx={{ height: '100%' }}>
      <Card
        sx={{
          width: size === 'small' ? '50%' : '100%',
          height: '100%',
          borderRadius: '5px',
          boxShadow: 'none !important',
          border: themeMode === 'dark' ? 'none' : '1px solid',
          borderColor: theme.palette.action.disabled,
        }}
      >
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: 'h6', fontWeight: 500 }}
          sx={{ px: 3 }}
          action={actions.map((act, ind) => (
            <Button
              key={ind}
              size="small"
              disabled={act.buttonDisabled}
              component={Link}
              to={act.buttonNavigate}
              variant="contained"
              color={act.buttonColor || 'primary'}
              sx={{ /* borderRadius: '20px', */ mr: 1, mt: 0.5 }}
              // disableElevation={true}
            >
              {act.buttonLabel}
            </Button>
          ))}
        />
        <Divider />
        <CardContent sx={{ px: 3 }}>{children}</CardContent>
        {actionChildren && (
          <>
            <Divider />
            <CardActions sx={{ p: 2, display: 'flex', flexWrap: 'wrap' }}>{actionChildren}</CardActions>{' '}
          </>
        )}
      </Card>
    </Stack>
  )
}
