import { Card, CardContent, Grid, Box, Table, TableBody, TableHead, TableRow, Button } from '@mui/material'
import Header from 'components/Header'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TableHeadCell from 'components/TableHeadCell'
import PeriodItem from './ListItem'
import { useTranslation } from 'react-i18next'
import { useBasePath } from 'components/Providers'

export default function PeriodList() {
  const periods = useSelector(state => state.settings.period.list)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const basePath = useBasePath()

  return (
    <Box data-component="PeriodList" sx={{ minHeight: '50%' }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} sx={{ ml: -1 }}>
        <Header title={t('Periods')} small />
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => navigate(`${basePath}/new`)}
          sx={{ mr: 2 }}
        >
          {t('Add Period')}
        </Button>
      </Box>
      <Grid container spacing={2} sx={{ mt: 0 }}>
        <Grid item sm={12} xs={12} md={12}>
          <Card sx={{ minHeight: '600px' }}>
            <CardContent sx={{ pl: 0, pr: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>{t('Name')}</TableHeadCell>
                    <TableHeadCell>{t('Type')}</TableHeadCell>
                    <TableHeadCell>{t('Daily Attendance')}</TableHeadCell>
                    <TableHeadCell>{t('Regular')}</TableHeadCell>
                    <TableHeadCell>{t('Early Dismissal')}</TableHeadCell>
                    <TableHeadCell>{t('Delayed Opening')}</TableHeadCell>
                    <TableHeadCell sx={{ width: '10%', textAlign: 'center'}}>{t('Actions')}</TableHeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {periods?.map(item => {
                    return <PeriodItem key={item.id || item.uid} period={item} />
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}
