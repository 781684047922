import { Box, Button, Stack } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SchedulingEnrollBySessionSearch from './Search'
import SchedulingCourseSessionInfo from './CourseSessionInfo'
import SchedulingEnrollSessionEnrolled from './Enrolled'

export default function SchedulingEnrollCourseListIndex({ selectedCourseSession, setSelectedCourseSession }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const basePath = '/settings/scheduling/enroll-session/'

  return (
    <Box>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Header title={t('Enroll By Session')} small />
        <Button disabled={!selectedCourseSession} size="small" variant="contained" color="primary" onClick={() => navigate(`${basePath}add`)}>
          {t('Enroll')}
        </Button>
      </Stack>
      <Box>
        <SchedulingEnrollBySessionSearch
          selectedCourseSession={selectedCourseSession}
          setSelectedCourseSession={setSelectedCourseSession}
        />
        <SchedulingCourseSessionInfo selectedCourseSession={selectedCourseSession} />
        <SchedulingEnrollSessionEnrolled selectedCourseSession={selectedCourseSession} setSelectedCourseSession={setSelectedCourseSession} />
      </Box>
    </Box>
  )
}
