import { Box, CardContent, Grid, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TableHeadCell from 'components/TableHeadCell'
import { search } from 'store/discipline/incident'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import IncidentItem from './ListItem';
import moment from 'moment';
import NoRecords from 'components/NoRecords';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

const headers = ['Student', 'Class', 'Staff', 'Date', 'Incident', 'Point', 'Actions'];
export default function IncidentsList({ dateRange }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const disciplineIncident = useSelector(state => state.discipline.disciplineIncident.list);

  const startDate = dateRange?.startDate ? moment(dateRange.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
  const endDate = dateRange?.endDate ? moment(dateRange.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

  useEffect(() => { 
    if (dateRange?.startDate && dateRange?.endDate) {
      dispatch(search({ startDate, endDate }));
    }
  }, [dispatch, dateRange, startDate, endDate, Boolean(disciplineIncident)]);

    return (
        <Box sx={{ display: 'flex', padding: '10px', flexDirection: 'column', alignItems: 'flex-start', gap: '10px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <CardContent sx={{ pr: 0, pl: 0 }}>
              <Table>
                <TableHead>
                  <TableRow>
                      {headers.map((item, index) => (
                          <TableHeadCell key={index}>
                              {t(item)}
                          </TableHeadCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disciplineIncident && disciplineIncident.length > 0 ? 
                    disciplineIncident?.map(item => (
                      <IncidentItem key={item.id || item.uid} item={item} />
                    )) : (
                      <TableRow align="center" >
                      <TableCell colSpan={7} align="center" sx={{ border: 'none' }}> 
                        <NoRecords 
                          message={`No Incidents between ${moment(startDate).format('MM-DD-YYYY')} and ${moment(endDate).format('MM-DD-YYYY')}`}
                          icon={<SentimentSatisfiedAltIcon sx={{ fontSize: 40, mb: 1, color: "grey" }} />}
                        />
                      </TableCell>
                    </TableRow>
                    )}
                </TableBody>
              </Table>
            </CardContent>
          </Grid>
        </Grid>
      </Box>
    );
}
