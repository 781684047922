import {
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material'
import { PersonAddIcon } from 'components/Icons'
import TableHeadCell from 'components/TableHeadCell'
import dayjs from 'dayjs'
import _, { debounce, omit } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getGradeLevelAndSections, getGradeLevels, getStudents, studentsQuery, studentsSelected } from 'store/lookup'
import { search, save as saveCourseSessionEnrollments } from 'store/settings/course-session-enrollments'
import { get } from 'store/settings/course-session'

export default function SchedulingEnrollSessionFormAddStudent({ selectedCourseSession, setSelectedCourseSession }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = `/settings/scheduling/enroll-session/`

  const academicYearId = useSelector(state => state.session?.academicYear?.id)

  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const gradeLevelSections = useSelector(state => state.lookup.gradeLevelAndSections)
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const enrolledIds = useSelector(state => state.settings?.courseSessionsEnrollments?.list)
    ?.filter(cs => cs.courseSessionId === +selectedCourseSession.id)
    ?.map(ids => ids.schoolEnrollment?.id)
  const { rows: students, count } = useSelector(state => state.lookup.students)
  const {
    que,
    limit,
    offset,
    grade,
    section,
    selected: selectedStudent,
  } = useSelector(state => state.lookup.studentsQuery)

  const [quue, setQuue] = useState()
  const [gradeValue, setGradeValue] = useState()

  useEffect(() => {
    gradeLevelSections || dispatch(getGradeLevelAndSections())
    gradeLevels || dispatch(getGradeLevels())
    dispatch(studentsQuery({ que: null, limit: 10, offset: 0, grade: null, section: null, selected: null }))
    dispatch(studentsSelected(null))
    dispatch(getStudents({ que: null, offset: 0, limit: 10, grade: null, section: null }))
  }, [])

  useEffect(() => {
    if (selectedCourseSession) {
      dispatch(search({ courseSessionId: +selectedCourseSession.id, academicYearId: +academicYearId }))
    }
  }, [selectedCourseSession])

  useEffect(() => {
    if (quue) {
      dispatch(studentsQuery({ que: quue, limit, offset, grade, section }))
    } else {
      dispatch(studentsQuery({ que: null, limit, offset, grade, section }))
    }
  }, [quue])

  const addRemoveStudent = e => {
    if (selectedStudent?.find(ss => ss.schoolEnrollmentId === +e.target.value)) {
      if (!e.target.checked) {
        dispatch(studentsSelected([...selectedStudent.filter(ss => ss.schoolEnrollmentId !== +e.target.value)]))
      }
    } else {
      if (e.target.checked) {
        if (selectedStudent) {
          dispatch(
            studentsSelected([
              ...selectedStudent,
              { ...students.find(st => st.schoolEnrollmentId === +e.target.value) },
            ]),
          )
        } else {
          dispatch(studentsSelected([{ ...students.find(st => st.schoolEnrollmentId === +e.target.value) }]))
        }
      }
    }
  }

  const createEnrollments = async () => {
    const payload = {
      schoolEnrollmentId: selectedStudent.map(s => s.schoolEnrollmentId),
      startDate: dayjs(new Date()).format('YYYY-MM-DD'),
      endDate: null,
      substitute: false,
      roleId: null,
    }

    const res = await dispatch(saveCourseSessionEnrollments({ courseSessionId: +selectedCourseSession.id, ...payload }))
    if (res.payload) {
      await dispatch(get({ id: +selectedCourseSession.id })).then(response => {
        if (response.payload) {
          setSelectedCourseSession(response.payload)
        }
      })
      await dispatch(search({ courseSessionId: +selectedCourseSession.id, academicYearId: +academicYearId }))
      navigate(`${basePath}/`)
    }
  }

  const applyFilter = async (key, value) => {
    if (typeof value === 'object') {
      dispatch(getStudents({ que, limit, ...value, offset: 0 }))
      return
    }
    if (value) {
      dispatch(getStudents({ que, limit, grade, section, [key]: value, offset: 0 }))
    } else {
      dispatch(getStudents({ ...omit(que, limit, offset, grade, section, key), limit, offset: 0, grade, section }))
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  const handleChangePage = (event, page) => {
    dispatch(getStudents({ que, limit: limit || 10, offset: limit * page, grade }))
    dispatch(studentsQuery({ que, limit: limit || 10, offset: limit * page, grade, selected: selectedStudent }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(getStudents({ que, offset: 0, limit, grade }))
  }

  const selectAll = e => {
    if (e.target.checked) {
      const sceIds = students
        ?.map(s => s?.schoolEnrollmentId)
        ?.filter(s => !enrolledIds?.includes(s))
        ?.filter(s => !selectedStudent?.map(ss => ss.schoolEnrollmentId)?.includes(s))
      const temp = students.filter(s => sceIds.includes(s.schoolEnrollmentId))
      if (selectedStudent) {
        dispatch(studentsSelected([...selectedStudent, ...temp]))
      } else {
        dispatch(studentsSelected([...temp]))
      }
    } else {
      dispatch(studentsSelected(null))
    }
  }

  const gradeFilter = event => {
    let grade = parseInt(event.target.value)
    if (!grade || isNaN(grade)) {
      return 'null'
    }
    const section = event.target.value.replace(grade, '')
    grade = String(grade)
    return { grade, section }
  }

  if (!(students && gradeLevelSections && gradeLevels)) return null

  const sections = []

  gradeLevelSections?.forEach(gs => {
    sections.push({ id: String(gs.id), name: gs.name })
    if (gs?.section) {
      gs?.section.forEach(section => {
        sections.push({ id: `${gs.id}${section}`, name: `${gs.name}${section}` })
      })
    }
  })

  return (
    <Box>
      <Grid container sx={{ p: 3, mt: 1 }} spacing={2}>
        <Grid item xs={6} md={6}>
          <TextField
            value={quue}
            onChange={event => {
              setQuue(event.target.value)
              delayedSearch(event.target.value, applyFilter)
            }}
            label="Search"
            size="small"
            fullWidth
            placeholder="Name"
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            select
            label="Grade Section"
            size="small"
            fullWidth
            value={gradeValue}
            onChange={event => {
              const { grade, section } = gradeFilter(event)
              if (grade && section) {
                setGradeValue(`${String(grade)}${String(section)}`)
                dispatch(studentsQuery({ que: quue || null, limit, offset, grade, section, selected: selectedStudent }))
                applyFilter(['gradeSection'], { grade, section })
              } else if (grade && !section) {
                setGradeValue(`${String(grade)}`)
                dispatch(
                  studentsQuery({
                    que: quue || null,
                    limit,
                    offset,
                    grade: event.target.value,
                    section: null,
                    selected: selectedStudent,
                  }),
                )
                applyFilter(['gradeSection'], { grade: event.target.value, section: null })
              } else {
                setGradeValue('null')
                dispatch(
                  studentsQuery({
                    que: quue || null,
                    limit,
                    offset,
                    grade: null,
                    section: null,
                    selected: selectedStudent,
                  }),
                )
                applyFilter(['gradeSection'], { grade: null, section: null })
              }
            }}
          >
            <MenuItem value={'null'}>{t('All')}</MenuItem>
            {sections?.map((item, index) => (
              <MenuItem key={index} value={String(item.id)}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer>
            <Table
              size="small"
              sx={{
                border: '1px solid',
                borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
                mt: 2,
              }}
            >
              <TableHead>
                <TableRow>
                  <TableHeadCell sx={{ width: '3%', p: 0 }}>
                    <Checkbox
                      color="primary"
                      size="small"
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                      onClick={selectAll}
                    />
                  </TableHeadCell>
                  <TableHeadCell sx={{ width: '67%', fontWeight: '500 !important' }}>{t('Student Name')}</TableHeadCell>
                  <TableHeadCell sx={{ width: '30%', fontWeight: '500 !important' }}>{t('Grade Level')}</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedStudent &&
                  selectedStudent?.map(item => (
                    <TableRow hover key={item}>
                      <TableCell sx={{ width: '5%', p: 0 }}>
                        <Checkbox
                          color="primary"
                          onClick={e => addRemoveStudent(e)}
                          value={item.schoolEnrollmentId}
                          size="small"
                          checked={true}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '65%' }}>
                        {item?.lastName || item?.person?.lastName}, {item?.firstName || item?.person?.firstName}
                      </TableCell>
                      <TableCell sx={{ width: '30%' }}>
                        {gradeLevels?.find(gl => gl.id === item?.academicYearEnrollment?.gradeLevelId)?.name}
                      </TableCell>
                    </TableRow>
                  ))}
                {students
                  ?.filter(sl => !selectedStudent?.find(ss => ss.schoolEnrollmentId === sl.schoolEnrollmentId))
                  .map((item, index) => (
                    <TableRow hover key={index}>
                      <TableCell sx={{ width: '5%', p: 0 }}>
                        <Checkbox
                          color="primary"
                          onClick={e => addRemoveStudent(e)}
                          value={item?.schoolEnrollmentId}
                          size="small"
                          checked={
                            selectedStudent?.find(ss => ss.schoolEnrollmentId === item?.schoolEnrollmentId) ||
                            enrolledIds?.includes(item?.schoolEnrollmentId)
                          }
                          disabled={enrolledIds?.includes(item?.schoolEnrollmentId)}
                        />
                      </TableCell>
                      <TableCell sx={{ width: '65%' }}>
                        {item?.person?.lastName}, {item?.person?.firstName}
                      </TableCell>
                      <TableCell sx={{ width: '30%' }}>
                        {gradeLevels?.find(gl => gl.id === item?.academicYearEnrollment?.gradeLevelId)?.name}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              sx={{
                border: '1px solid',
                borderTop: 'none',
                borderColor: theme === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
              }}
              component="div"
              count={count || 0}
              page={limit ? offset / limit : 0}
              onPageChange={handleChangePage}
              rowsPerPage={limit || 10}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={_.uniq([limit, 10, 15, 20, 25, 50, 100])}
            />
          </TableContainer>
        </Grid>
      </Grid>
      <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 3 }}>
        <Button
          onClick={createEnrollments}
          size="small"
          variant="contained"
          color="primary"
          startIcon={<PersonAddIcon />}
        >
          {t('Enroll')}
        </Button>
      </Stack>
    </Box>
  )
}
