import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import Header from 'components/Header'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SettingsSchoolManagementList from './List'
import { search as searchDistrict } from 'store/settings/district'
import { search as searchCampus } from 'store/settings/campus'
import { search as searchSchools } from 'store/settings/school'

export default function SettingsSchoolManagementListIndex() {
  const basePath = '/setting/school-management/'
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const districts = useSelector(state => state.settings.district.list)
  const schools = useSelector(state => state.settings.school.list)
  const campuses = useSelector(state => state.settings.campus.list)

  useEffect(() => {
    dispatch(searchDistrict())
    dispatch(searchSchools())
    dispatch(searchCampus())
  }, [dispatch])

  if (!(districts && campuses && schools)) return null

  return (
    <Box>
      <Paper>
        <Header title={t('School Management')} small />
        <Stack sx={{ mx: 2 }} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Box sx={{ px: 1.5 }}>
            <Typography variant="subtitle1">{t('Districts')}</Typography>
          </Box>
          <Box>
            <Button size="small" variant="contained" color="primary" onClick={() => navigate(`${basePath}district/new`)}>
              {t('Add')}
            </Button>
          </Box>
        </Stack>
        <SettingsSchoolManagementList districts={districts} campuses={campuses} schools={schools} />
      </Paper>
    </Box>
  )
}
