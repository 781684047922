import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { DownIcon } from 'components/Icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import GradingListScalesItem from './ListItem'
import TableHeadCell from 'components/TableHeadCell'

export default function GradingListScales({ list }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const basePath = '/settings_/grading/scales'

  if (!list) return null

  const actives = list.filter(item => item.enabled === true)
  const inactives = list.filter(item => item.enabled === false)

  return (
    <Box>
      <Paper>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<DownIcon />}>
            <Typography>Active Grading Scales</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack flexDirection={'row'} alignItems={'flex-end'} justifyContent={'flex-end'}>
              <Button onClick={() => navigate(`${basePath}/new`)} size="small" variant="contained" color="primary">
                {t('Add')}
              </Button>
            </Stack>

            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell sx={{ width: '90%' }}>{t('Name')}</TableHeadCell>
                  <TableHeadCell sx={{ width: '10%' }}>{t('Action')}</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actives && actives?.map((scale, index) => <GradingListScalesItem key={index} gradeScale={scale} />)}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Paper>
      <Paper sx={{ mt: 1 }}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<DownIcon />}>
            <Typography>Inactive Grading Scales</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeadCell sx={{ width: '90%' }}>{t('Name')}</TableHeadCell>
                  <TableHeadCell sx={{ width: '10%' }}>{t('Action')}</TableHeadCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inactives &&
                  inactives?.map((scale, index) => <GradingListScalesItem key={index} gradeScale={scale} />)}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Box>
  )
}
