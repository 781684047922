import { forwardRef } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Confirm({ open, onClose, onConfirm }) {
  const { t } = useTranslation()
  return (
    <Dialog data-component="Confirm" open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>Are you sure to delete selected row?</DialogContent>
      <DialogActions>
        <Button aria-label="cancel" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button aria-label="confirm" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export function ConfirmLeave({ open, validation, onClose, onConfirm }) {
  const { t } = useTranslation()

  const getMessage = () => {
    let message = ''
    if (validation?.isEventConflict) {
      message += t('- EVENT before/after condition is met. ') + '<br /><br />'
    }
    if (validation?.isConsecutive) {
      message += t('- CONSECUTIVE LEAVE condition is met.') + '<br /><br />'
    }
    if (validation?.isLastThreeWeeks) {
      message += t('- LAST THREE WEEKs condition is met.') + '<br /><br />'
    }
    if (validation?.isFirstTwoWeeks) {
      message += t('- FIRST TWO WEEKs condition is met.') + '<br /><br />'
    }
    message = message || t('UNKNOWN conditions are met.') + '<br />'
    message += '<br />' + t('Are you sure to continue?')

    return message
  }

  return (
    <Dialog
      data-component="ConfirmLeave"
      open={open}
      validation={validation}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogTitle>Warning</DialogTitle>
      <DialogContent dangerouslySetInnerHTML={{ __html: getMessage() }}/>
      <DialogActions>
        <Button aria-label="cancel" onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button aria-label="confirm" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
