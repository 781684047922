import _ from 'lodash'
import { Box, Chip, Stack, Typography } from '@mui/material'
import ProfilePicture from './ProfilePicture'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getGradeLevels } from 'store/lookup'
import { useMember, useMembershipType } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'

import * as action from 'store/member'
import { searchByName } from 'store/report/reports'
import { retrieve } from 'store/report/steps'
import ReportExportPDF from 'pages/Report/PDF'
import { createRoot } from 'react-dom/client'

export default function PersonHeader() {
  const dispatch = useDispatch()
  const member = useMember()
  const { t } = useTranslation()
  const ids = useSelector(state => state.member.list?.map(p => p.id))
  const { count } = useSelector(state => state.member)
  const { '*': tabName } = useParams()
  const navigate = useNavigate()
  const membershipType = useMembershipType()

  const [studentCardButton, setStudentCardButton] = useState(false)

  const query = useSelector(state => state.member.query[membershipType])

  const gradeLevels = useSelector(state => state.lookup.gradeLevels)
  const basePath = '/member/' + membershipType

  useEffect(() => {
    gradeLevels || dispatch(getGradeLevels())
  }, [])

  const page = dir => {
    if (!(ids && member)) return
    const idx = ids.indexOf(member.id)
    const n = idx + dir

    if (n < 0) {
      const offset = query?.offset - query.limit
      if (offset < 0) {
        navigate(basePath)
        return
      }
      dispatch(action.search({ ...query, offset })).then((res, err) => {
        if (err) return
        let n = res.payload.rows.length - 1
        if (n < 0) return
        const id = _.get(res, `payload.rows.${n}.id`)
        if (id) {
          navigate([basePath, id, tabName].join('/'))
        } else {
          navigate(basePath)
        }
      })
      return
    }
    if (n >= ids.length) {
      const offset = query?.offset + query.limit
      if (offset >= count) {
        navigate(basePath)
        return
      }
      dispatch(action.search({ ...query, offset })).then((res, err) => {
        if (err) return
        const id = _.get(res, 'payload.rows.0.id')
        if (id) {
          navigate([basePath, id, tabName].join('/'))
        } else {
          navigate(basePath)
        }
      })
      return
    }
    if (n < 0 || n >= ids.length) return
    if (tabName) {
      navigate([basePath, ids[n], tabName].join('/'))
    } else navigate([basePath, ids[n]].join('/'))
  }
  const gradeLevel = gradeLevels?.find(item => item.id === member?.academicYearEnrollment?.gradeLevelId)
  const gradeLevelName = gradeLevel?.name
  const gradeSection = member?.academicYearEnrollment?.gradeSection
  const idTitle = member.membership?.type === 'student' ? 'Student ID' : 'Staff ID'

  const studentCardPDF = async () => {
    setStudentCardButton(true)
    const getReport = await dispatch(searchByName({ name: 'Student Card' }))
    if (getReport) {
      const runReport = await dispatch(retrieve({ id: getReport?.payload.id, enrollment_view_id: member?.id }))
      const reportDatas = { data: runReport?.payload, exportNow: true }
      const container = document.createElement('div')
      const root = createRoot(container)
      root.render(<ReportExportPDF {...reportDatas} />, container)
    }
    setStudentCardButton(false)
  }

  if (!gradeLevels)
    return (
      <Header avatar={''} title={'NotFound'} prev={() => page(-1)} next={() => page(1)} close>
        <Box>
          <Typography variant="body1" gutterBottom>
            <Typography component="span" display="inline-block" sx={{ mr: 2 }}></Typography>
            <Typography component="span" display="inline-block"></Typography>
          </Typography>
          <Typography variant="body1" gutterBottom></Typography>
        </Box>
      </Header>
    )

  return (
    <Header
      avatar={<ProfilePicture />}
      title={
        member
          ? `${member.person?.firstName}  ${member?.person?.middleName ?? ''} ${member.person?.lastName}`
          : t('New Person')
      }
      prev={() => page(-1)}
      next={() => page(1)}
      close
    >
      <Box>
        <Typography variant="body1" gutterBottom>
          {member.membership?.type === 'student' && (
            <Typography component="span" display="inline-block" sx={{ mr: 2 }}>
              {gradeLevelName} | {gradeSection}
            </Typography>
          )}
          <Typography component="span" display="inline-block">
            {idTitle}:{' '}
            {member.membership?.type === 'staff' ? member.membership.stateId : member?.schoolEnrollment?.enrollmentId}
          </Typography>
        </Typography>
        <Stack direction="row" spacing={2}>
        <Stack direction="row" spacing={1}>
          <Typography variant="body1" gutterBottom>
            {t('Status')}:
          </Typography>
          <Chip
            label={member?.schoolEnrollment?.endDate ? t('Unenrolled') : t('Enrolled')}
            size="small"
            color={member?.schoolEnrollment?.endDate ? 'default' : 'success'}
            sx={{ ml: 1 }}
          />
        </Stack>
          <Stack direction="row" spacing={1}>
            <Typography variant="body1" >
              {t('ID Card')}:
            </Typography>
            {member?.membership?.type === 'student' && <Chip variant="outlined" disabled={studentCardButton} onClick={() => studentCardPDF()} size="small" color="primary" label={t('View/Print Card')} />}
          </Stack>
        </Stack>
      </Box>
    </Header>
  )
}

