import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { get } from 'store/discipline/incident'
import { BasePathProvider, useBasePath } from 'components/Providers'
import DisciplineAddIncidentForm from './Form'
import DisciplinIncidentEditSkeleton from './FormSekeleton'

export default function DisciplineAddIncidentFormIndex() {
  const dispatch = useDispatch()
  const [disciplineIncident, setDisciplineIncident] = useState(null)
  const basePath = useBasePath()
  const { id } = useParams()

  useEffect(() => {
    if (id && id !== 'new') {
      dispatch(get({ id })).then(res => {
        setDisciplineIncident(res.payload)
      })
    } else {
        setDisciplineIncident(null)
    }
  }, [dispatch, id])

  if (( disciplineIncident) || id === 'new' ) {
    return (
      <BasePathProvider value={`${basePath}`}>
        <DisciplineAddIncidentForm disciplineIncident={disciplineIncident} />
      </BasePathProvider>
    );
  }

  if (!disciplineIncident) {
    return < DisciplinIncidentEditSkeleton />
  }
}
