import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function CalendarListCampuses({ control, data, title, setValue, getValues, clients, campuses }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [locationIds, setLocationIds] = useState(getValues().locationIds)

  const handleClick = () => {
    setOpen(true)
  }
  const handleDelete = e => {
    const list = getValues()?.locationIds?.filter(item => item !== e)
    setValue('locationIds', [...list])
  }

  const handleAllCampus = (event, d) => {
    if (event.target.checked && data[d]) {
      let temp = new Set()
      data[d].map(item => temp.add(item.id))
      if (locationIds && locationIds?.length > 0) {
        locationIds.map(item => temp.add(item))
      }
      setLocationIds([...temp])
    } else if (!event.target.checked && data[d]) {
      let temp = new Set()
      if (locationIds.length > 0) {
        locationIds.map(item => temp.add(item))
      }
      data[d].map(item => temp.delete(item.id))
      setLocationIds([...temp])
    }
  }

  const handleCampus = (event, id) => {
    let temp = new Set()
    if (locationIds && locationIds?.length > 0) {
      locationIds.map(item => temp.add(item))
    }
    if (event.target.checked) {
      temp.add(id)
    } else {
      temp.delete(id)
    }
    setLocationIds([...temp])
  }

  useEffect(() => {
    if (locationIds) {
      setValue('locationIds', [...locationIds])
    }
  }, [locationIds])

  const checkAllSelected = id => {
    if (!locationIds) return false
    const ids = data[id]?.map(function (item) {
      return item.id
    })
    let count = 0
    ids.forEach(item => {
      if (locationIds?.includes(item)) {
        count++
      }
    })
    if (count === 0) return false
    else if (ids.length === count) return true
    else return false
  }

  const checkIndeterminate = id => {
    if (!locationIds) return false
    const ids = data[id]?.map(function (item) {
      return item.id
    })
    let count = 0
    ids.forEach(item => {
      if (locationIds?.includes(item)) {
        count++
      }
    })
    if (count === 0) return false
    else if (ids.length > count) return true
  }

  const handleCampusSelect = id => {
    if(!locationIds) {
      setLocationIds([id])
      return
    }
    if (locationIds?.includes(id)) {
      setLocationIds(locationIds.filter(item => item !== +id))
    } else {
      setLocationIds([...locationIds, id])
    }
  }

  if (!(control && data && title && clients)) return null

  return (
    <Grid item xs={12} md={12} sx={{ mt: 2 }}>
      <Controller
        name="locationIds"
        control={control}
        render={({ field: { value, ...field }, fieldState }) => {
          return (
            <FormControl sx={{ width: '100%' }}>
              <InputLabel id="multiple-chip-label" sx={{ width: 300, mb: 1, top: '-7px' }}>
                {t(title)}
              </InputLabel>
              <Select
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                size="small"
                multiple
                label={t(title)}
                error={!!fieldState.error}
                value={value || []}
                {...field}
                renderValue={selected => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected?.map(select => {
                      const found = campuses?.find(c => c.id === select)
                      return found ? (
                        <Chip
                          key={select}
                          variant="outlined"
                          label={`${found.name}`}
                          onClick={() => handleClick(select)}
                          onDelete={() => handleDelete(select)}
                          onMouseDown={event => {
                            event.stopPropagation()
                          }}
                        />
                      ) : null
                    })}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {Object.keys(data)?.map(d => [
                  <ListSubheader key={d}>
                    <Checkbox
                      color="primary"
                      indeterminate={checkIndeterminate(d)}
                      checked={checkAllSelected(d)}
                      onChange={event => handleAllCampus(event, d)}
                    />
                    {clients.find(c => c.id === +d)?.name}
                  </ListSubheader>,
                  data[d]?.map(item => (
                    <MenuItem
                      key={item?.id}
                      value={item?.id}
                      sx={{ pl: 4 }}
                      onClick={() => handleCampusSelect(item?.id)}
                    >
                      <Checkbox
                        checked={locationIds?.indexOf(item?.id) > -1}
                        color="primary"
                        onChange={event => handleCampus(event, item.id)}
                      />
                      <ListItemText primary={item?.name} />
                    </MenuItem>
                  )),
                ])}
              </Select>
            </FormControl>
          )
        }}
      />
    </Grid>
  )
}
