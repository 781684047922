import { Button, Chip, TableCell, TableRow, Box} from '@mui/material'
import moment from 'moment'
//import { Link as NavLink } from 'react-router-dom'
import { useBasePath } from 'components/Providers'
import { useNavigate } from 'react-router-dom';


export default function IncidentItem({ item }) {
  const basePath = useBasePath()
  const navigate = useNavigate();
  const handleMoreClick = () => {
    navigate(`${basePath}/${item.id}`);
  };

  return (
    <TableRow hover>
      <TableCell> {item?.student?.lastName}, {item?.student?.firstName}</TableCell>
      <TableCell> {item?.student?.gradeSection}</TableCell>
      <TableCell> {item?.staff?.lastName}, {item?.staff?.firstName}</TableCell>
      <TableCell>
        <Box>{moment(item?.incidentDate).format('MM/DD/YYYY')}</Box>
        <Box>{moment(item?.incidentDate).format('hh:mm A')}</Box>
      </TableCell>
      <TableCell> {item?.incidentItem?.name}</TableCell>
      <TableCell>  
        <Chip
          label={item?.incidentItem?.point}
          color={item?.incidentItem?.point >= 0 ? 'success' : 'error'}
          size='small'      
        />
      </TableCell>      
      <TableCell align="left">
        <Button 
          variant='outlined' 
          size='small'
          onClick={handleMoreClick}
        >
            More    
        </Button>        
      </TableCell>
    </TableRow>
  )
}
