import { Box, Drawer, } from '@mui/material'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import AppMenu from 'components/AppMenu'
import Memberships from '../components/Widgets/Memberships'
import Dashboard from './Dashboard'
import Staff from './Staff'
import Student from './Student'
import Settings from './Settings'
import CourseSession from './Academic/CourseSession'
import GradingReport from './Academic/FinalGrades'
import ParentalStudent from './Parent'
import TeachersStudent from './Academic/Student'
import Approval from '../components/Approval'
import { useState } from 'react'
import { Schedule } from './Academic/Schedule'
import { Attendance } from './Academic/Attendance'
import Member from './Member'
import Person from './Provider'
import NewPersonalForm from './Member/Person/Personal/Form/NewPersonalForm'
import Schools from './Schools'
import Settings2 from './Setting'
import Settings_ from './Settings_'
import Report from './Report'
import Nurse from './Nurse'
import Calendar from './Calendar'
import Discipline from './Discipline'

const drawerWidth = 240
const collapsedWidth = 64

export default function MainPage() {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);

  const menuCollapseToggle = () => {
    setIsMenuCollapsed(prevState => !prevState)
  }

  return (
    <>
      <Approval>
        <Box
          sx={{
            display: 'flex',
            backgroundColor: 'background.default',
            minHeight: '100vh',
          }}
        >
          <Box
            component="nav"
            id="app-nav"
            sx={{ displayPrint: 'none', width: { sm: isMenuCollapsed ? collapsedWidth : drawerWidth }, display: { xs: 'none', sm: 'block'}, flexShrink: { sm: 0 }, }}
          >
            
            <Drawer
              variant="permanent"
              sx={{
                width: isMenuCollapsed ? collapsedWidth : drawerWidth,
                
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  //height: '100%',
                  width: isMenuCollapsed ? collapsedWidth : drawerWidth,
                  boxSizing: 'border-box',
                  backgroundColor: 'primary.main',
                  borderRight: 'none',
                  transition: 'width 0.3s',
                },
              }}
              PaperProps={{
                sx: {
                  borderRadius: '8px',
                  //margin: '16px',
                  top: '15px',
                  height: 'calc(100vh - 30px)',
                  left: '15px',
                  overflow: 'auto',
                },
              }}
              open
            >
              <AppMenu
                  isMenuCollapsed={isMenuCollapsed}
                  menuCollapseToggle={menuCollapseToggle}
              />
            </Drawer>
          </Box>
          <Box component="main" 
            sx={{ 
              my: 2, 
              flexGrow: 1, 
              pl: { sm: 3, xs: 0}, 
              pr: { sm: 2, xs: 0 }, 
              width: {
                sm: isMenuCollapsed
                  ? `calc(100% - ${collapsedWidth}px)` 
                  : `calc(100% - ${drawerWidth}px)`,
                xs: '100%'
              },
            }}
          >
            <Routes>
              <Route path="/" element={<Outlet />}>
                <Route
                  index
                  element={
                    <Memberships>
                      <Dashboard />
                    </Memberships>
                  }
                />
                <Route path="setting/*" element={<Settings2 />} />
                <Route path="schools/*" element={<Schools />} />
                <Route path="person/*" element={<Person />} />
                <Route path="person/:personId/*" element={<Person />} />
                <Route path="member/:membershipType/new" element={<NewPersonalForm />} />
                <Route path="member/:membershipType/*" element={<Member />} />
                <Route path="member/:membershipType/:memberId/*" element={<Member />} />
                <Route path="staff/*" element={<Staff />} />
                <Route path="staff/:personId/*" element={<Staff />} />
                <Route path="student/*" element={<Student />} />
                <Route path="student/:personId/*" element={<Student />} />
                <Route path="academic/schedule" element={<Schedule />} />
                <Route path="academic/attendance" element={<Attendance />} />
                <Route path="academic/grading-report" element={<GradingReport />} />
                <Route path="academic/parent/student/:studentId/*" element={<ParentalStudent />} />
                <Route path="academic/:membershipType/*" element={<TeachersStudent />} />
                <Route path="academic/:membershipType/:id/*" element={<TeachersStudent />} />
                <Route path="academic/course-session/*" element={<CourseSession />} />
                <Route path="academic/course-session/:courseSessionId/*" element={<CourseSession />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="settings/*" element={<Settings />} />
                <Route path="settings_/*" element={<Settings_ />} />
                <Route path="report/*" element={<Report />} />
                <Route path="nurse/*" element={<Nurse />} />
                <Route path="discipline/*" element={<Discipline />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Routes>
          </Box>
        </Box>
      </Approval>
    </>
  )
}
