import { Route, Routes } from 'react-router-dom'
import DocumentPage from './Page'
import DocumentFormIndex from './Form'

export default function Documents() {
  return (
    <Routes>
      <Route path="/" element={<DocumentPage />}>
        <Route path="/:documentId" element={<DocumentFormIndex />} />
      </Route>
    </Routes>
  )
}