import { debounce, omit } from 'lodash'
import { Button, InputAdornment, Stack, TextField } from '@mui/material'
import { search } from 'store/provider/academic-year'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { useDispatch } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useBasePath } from 'components/Providers'

export default function SearchBar() {
  const dispatch = useDispatch()
  const query = { limit: 10, offset: 0 }
  const [que, setQue] = useState(query.que || '')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const basePath = useBasePath()

  const applyFilter = async (key, value) => {
    if (typeof value === 'string' && value.trim().length > 2) {
      dispatch(search({ ...query, [key]: value, offset: 0 }))
    } else if (!value || value === '' || typeof value !== 'string') {
      dispatch(search({ ...omit(query, key), offset: 0 }))
    }
  }

  useEffect(() => {
    applyFilter()
  }, [])

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  return (
    <Stack direction="row" spacing={2} sx={{ p: 2 }}>
      <TextField
        size="small"
        fullWidth
        placeholder="Search"
        value={que}
        onChange={event => {
          setQue(event.target.value)
          delayedSearch(event.target.value, applyFilter)
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <CloseIcon
              onClick={() => {
                setQue('')
                applyFilter('que', '')
              }}
              sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
            />
          ),
        }}
      />
      <Button
        onClick={() => navigate(`${basePath}/new`)}
        sx={{ width: '5em' }}
        size="small"
        variant="contained"
        color="primary"
      >
        {t('Add')}
      </Button>
    </Stack>
  )
}
