import 'utils/global.scss'
import * as yup from 'yup'
import { useEffect } from 'react'
import { FaSave } from 'react-icons/fa'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Controller, useFieldArray, useForm, useFormState } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { BasePathProvider } from 'components/Providers'
import { Box, Button, Grid, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material'

import { getAttendanceStatus } from 'store/lookup'
import { save as saveStudentAttendance } from 'store/settings/attendance'
import PeriodList from './PeriodList'
import './AttendanceDropdown.css'
import IconProvider from 'components/IconProvider'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import dayjs from 'dayjs'

function getDayOfWeek(dateString) {
  const date = new Date(dateString)
  const dayOfWeek = date.getUTCDay()
  return dayOfWeek
}

export default function DailyAttendanceForm({ basePath, selected }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { attendanceStatus, periods } = useSelector(state => state.lookup)
  const { district } = useSelector(state => state.session)

  const { id } = useParams()
  const [searchParams] = useSearchParams()

  const date = searchParams.get('date')
  const schoolEnrollmentId = searchParams.get('schoolEnrollmentId')

  const attendanceDate = date
    ? dayjs.tz(date, district.timeZone).format('MM/DD/YYYY')
    : dayjs.tz(selected?.date).format('MM/DD/YYYY')

  useEffect(() => {
    if (!attendanceStatus) {
      dispatch(getAttendanceStatus())
    }
  }, [attendanceStatus])

  const schema = yup.object().shape({
    note: yup.string().trim().nullable(),
    periods: yup.array().of(
      yup.object().shape({
        statusId: yup.string(),
      }),
    ),
  })

  const { control, handleSubmit, setValue, getValues } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      statusId: selected?.dailyAttendance?.status?.id,
      note: selected?.dailyAttendance?.note,
      periods: selected?.periodAttendance,
    },
  })

  const periodArrayControl = useFieldArray({ control, name: 'periods', keyName: 'uuid' })
  const { dirtyFields } = useFormState({ control })

  function applyToAll() {
    const periodAttendanceList = []
    const statusId = getValues().statusId
    const dailyStatus = attendanceStatus?.find(item => item.id === statusId) || { id: '0' }

    selected?.periodAttendance?.forEach(item => {
      item.courseSessionScheduleId && periodAttendanceList.push({ ...item, status: { ...dailyStatus } })
    })
    periodArrayControl.replace(periodAttendanceList)
  }

  const onSubmit = async formData => {
    const periodAttendanceList = []
    let changedValues = {}
    if (dirtyFields.periods) {
      changedValues.periods = Object.keys(dirtyFields.periods).reduce((acc, key) => {
        const index = parseInt(key, 10)
        acc[index] = getValues(`periods[${index}].status.id`)
        return acc
      }, {})
    }

    if (changedValues.periods) {
      Object.keys(changedValues.periods).forEach(key => {
        const index = parseInt(key, 10)
        const item = periods[index]

        if (selected?.periodAttendance[index]?.id) {
          periodAttendanceList.push({
            id: selected?.periodAttendance[index].id,
            periodId: item.id,
            statusId: formData.periods[index].status.id,
          })
        } else {
          parseInt(formData.periods[index]?.status.id) &&
            periodAttendanceList.push({ periodId: item.id, statusId: formData.periods[index]?.status.id })
        }
      })
    }

    Object.keys(dirtyFields).reduce((acc, key) => {
      acc[key] = getValues(key)
      changedValues[key] = getValues(key)
      return acc
    }, {})

    const payload = {
      day: date ? getDayOfWeek(date) : getDayOfWeek(dayjs()),
    }
    if (date) {
      payload.date = date
    }
    if (schoolEnrollmentId) {
      payload.schoolEnrollmentId = parseInt(schoolEnrollmentId)
    }

    if (periodAttendanceList.length) {
      payload.periodAttendanceList = periodAttendanceList
    }

    if ((changedValues.statusId === '0' && parseInt(id)) || parseInt(changedValues?.statusId)) {
      if (!payload.dailyAttendance) {
        payload.dailyAttendance = {}
      }
      payload.dailyAttendance.statusId = changedValues?.statusId
    }

    if (changedValues?.note) {
      if (!payload.dailyAttendance) {
        payload.dailyAttendance = {}
      }
      payload.dailyAttendance.note = changedValues?.note
    }

    if (parseInt(id) && (payload.dailyAttendance || payload.periodAttendanceList)) {
      payload.id = id
      Object.keys(changedValues).length > 0 && dispatch(saveStudentAttendance(payload))
      navigate(basePath)
    } else if (payload.dailyAttendance || payload.periodAttendanceList) {
      Object.keys(changedValues).length > 0 && dispatch(saveStudentAttendance(payload))
      navigate(basePath)
    } else {
      navigate(basePath)
    }
  }

  const onErrors = errors => console.log(errors)

  return (
    <Box
      data-component="AttendanceForm"
      component="form"
      onSubmit={handleSubmit(onSubmit, onErrors)}
      noValidate
      sx={{ overflowY: 'scroll', border: 'none' }}
    >
      <BasePathProvider value={`${basePath}`}>
        <Header
          title={id === 'new' ? t('Add Attendance Record') : t('Edit Attendance Record')}
          small
          close
          borderBottom
        />
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12}>
            <TextField
              sx={{ width: '100%', shrink: true }}
              readonly
              size="small"
              label={t('Student Name')}
              placeholder="Student Name"
              value={`${selected?.person?.lastName} ,${selected?.person?.firstName} `}
              color="primary"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ width: '100%', shrink: true }}
              aria-readonly
              size="small"
              label={t('Date')}
              placeholder="Date"
              value={attendanceDate}
              color="primary"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Controller
              name="statusId"
              control={control}
              render={({ field: { value, ...field }, fieldState }) => {
                return (
                  <TextField
                    select
                    sx={{ width: '100%' }}
                    size="small"
                    label={t('Daily Check')}
                    placeholder="Daily Check"
                    color="primary"
                    error={!!fieldState.error}
                    className="custom-text-field"
                    value={value}
                    helperText={fieldState.error?.message}
                    {...field}
                  >
                    <MenuItem value="0">Not Taken</MenuItem>
                    {attendanceStatus?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {<IconProvider icon={item.ui?.icon} color={item.ui?.color} />} {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button fullWidth variant="outlined" onClick={() => applyToAll()}>
              {t('APPLY TO ALL')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="note"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    label={t('Note')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ py: 2, fontWeight: '500 !important' }}>{'Period Attendance'}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ p: 0.5 }}>
            <PeriodList
              control={control}
              path="periods"
              setValue={setValue}
              periods={periods}
              attendanceStatus={attendanceStatus}
              {...periodArrayControl}
            />
          </Grid>
        </Grid>
        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 0.5, mb: 3 }}>
          <Button type="submit" color="primary" size="small" variant="contained" sx={{ mr: 2 }} startIcon={<FaSave />}>
            {t('SAVE')}
          </Button>
        </Stack>
      </BasePathProvider>
    </Box>
  )
}
