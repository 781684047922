import { useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import { Link, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useBasePath } from 'components/Providers'
import StudentProfile from '../Profile/Profile'
import { Schedule } from '../Schedule'
import FinalGrades from '../FinalGrades'
import Attendance from '../Attendance'
import StateData from '../StateData'
import { useSelector } from 'react-redux'
import 'utils/global.scss'

const PROFILE = 0
const SCHEDULE = 1
const FINALGRADES = 2
const ATTENDANCE = 3
const STATEDATA = 4

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['profile', 'schedule', 'final-grades', 'attendance', 'state-data'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function TabControl() {
  const { id, '*': tabName } = useParams()
  const theme = useSelector(state => state.session.appearance)
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  const basePath = useBasePath()
  const { t } = useTranslation()

  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', displayPrint: 'none' }}>
        <Tabs value={tabIndex} TabIndicatorProps={{ style: { background: 'primary' } }}>
          <Tab
            sx={{ color: tabIndex === 0 && 'primary !important' }}
            className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Profile')}
            value={PROFILE}
            component={Link}
            to={`${basePath}/${id}/profile`}
          />
          <Tab
            sx={{ color: tabIndex === 1 && 'primary !important' }}
            className={tabIndex === 1 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Schedule')}
            value={SCHEDULE}
            component={Link}
            to={`${basePath}/${id}/schedule`}
          />
          <Tab
            sx={{ color: tabIndex === 2 && 'primary !important' }}
            className={tabIndex === 2 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Final Grades')}
            value={FINALGRADES}
            component={Link}
            to={`${basePath}/${id}/final-grades`}
          />
          <Tab
            sx={{ color: tabIndex === 3 && 'primary !important' }}
            className={tabIndex === 3 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('Attendance')}
            value={ATTENDANCE}
            component={Link}
            to={`${basePath}/${id}/attendance`}
          />
          <Tab
            sx={{ color: tabIndex === 4 && 'primary !important' }}
            className={tabIndex === 4 ? `tab-item-selected` : `tab-item-${theme}`}
            label={t('State Data')}
            value={STATEDATA}
            component={Link}
            to={`${basePath}/${id}/state-data`}
          />
        </Tabs>
      </Box>
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route path="" element={<StudentProfile />} />
          <Route path="profile" element={<StudentProfile />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="final-grades" element={<FinalGrades />} />
          <Route path="attendance" element={<Attendance />} />
          <Route path="state-data" element={<StateData />} />
          <Route path="*" element={<StudentProfile />} />
        </Route>
      </Routes>
    </Box>
  )
}
