import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CardContent, Grid, TextField, Button, Card, CardActions, Autocomplete, Avatar, LinearProgress } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { DeleteIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { destroy } from 'store/nurse/nurse-visit'
import { save } from 'store/nurse/nurse-visit'
import { FaSave } from 'react-icons/fa'
import { getAllStudents } from "store/lookup";
import { getNurseComplaintTypes } from 'store/lookup'
import { getNurseTreatmentTypes } from 'store/lookup'
import { getNurseDispositionTypes } from 'store/lookup'
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import StudentSearchBar from 'components/StudentSearch'

export default function NurseAddVisitForm({ nurseVisit }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const basePath = useBasePath()
  const { id } = useParams()
  const [confirm, setConfirm] = useState(false)
  const nurseComplaintType = useSelector(state => state.lookup.nurse.complaintType)
  const nurseDispositionType = useSelector(state => state.lookup.nurse.dispositionType)
  const nurseTreatmentType = useSelector(state => state.lookup.nurse.treatmentType)

  const { academicYear } = useSelector(state => state.session)
  const allStudents = useSelector(state => state.lookup.allStudents.rows)
  const [loadingStudents, setLoadingStudents] = useState(true)

  useEffect(() => {
    if (!allStudents || !allStudents.length) {
      setLoadingStudents(true)
      dispatch(getAllStudents({ limit: 1000 })).finally(() => setLoadingStudents(false))
    } else {
      setLoadingStudents(false)
    }
  }, [dispatch, Boolean(allStudents), academicYear.id])

  const schema = yup.object().shape({
    studentId: yup.number().required('Required'),
    startTime: yup.date().nullable().required('Required'),
    note: yup.string().required('Required'),
    complaintTypeIds: yup.array().required('Required'),
    treatmentTypeIds: yup.array().required('Required'),
  })

  const { control, watch, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...nurseVisit,
      ...(nurseVisit?.id ? { startTime: nurseVisit.startTime ? dayjs(nurseVisit.startTime) : null } : { startTime: null }),
    },
  })

  const selectedStudentId = nurseVisit?.studentId ?? watch('studentId');
  const selectedStudent = allStudents?.find(student => student.personId === selectedStudentId) || null;

  const onSubmit = async data => {
    const payload = {
      ...data,
    };

    let exceptions = [ 'campusId', 'person'];
    if (id !== 'new') {
      exceptions.push('studentId');
    }

    const res = await dispatch(save(_.omit(payload, exceptions)));
    if (res.payload) {
      navigate(basePath);
    }
  };

  const onDestroy = async () => {
    setConfirm(false)
    await dispatch(destroy({ id: nurseVisit.id }))
    navigate(basePath)
  }

  useEffect(() => {
    nurseComplaintType ||
      dispatch(getNurseComplaintTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, nurseComplaintType])

  useEffect(() => {
    nurseDispositionType ||
      dispatch(getNurseDispositionTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, nurseDispositionType])

  useEffect(() => {
    nurseTreatmentType ||
      dispatch(getNurseTreatmentTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, nurseTreatmentType])

  if (loadingStudents) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <BasePathProvider value={`${basePath}/visits`}>
      <Box sx={{ overflowY: 'scroll', border: 'none' }}>
        <Header title={id === 'new' ? t('Add Visit') : t('Edit Visit')} small close borderBottom />
      </Box>
      <Card data-component="NurseAddVisitForm" elevation={0} component="form" onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '10px', alignSelf: 'stretch', minWidth: '100%', pl: 2, pt: 2, pb: 2 }}>
              <Avatar variant='square' sx={{ width: 160, height: 190 }} src={selectedStudent?.person?.profilePicture?.location} />

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: '1 0 0', }}>
                <Controller
                  name='studentId'
                  rules={{ required: 'Field is required' }}
                  control={control}
                  render={({ field: { onChange, value, ...field }, fieldState }) => {
                    const selectedStudent = allStudents?.find(student => student.personId === value) || null;
                    return (
                      <StudentSearchBar 
                        isOptionEqualToValue={(option, value) => option?.personId === value?.id}
                        onSelect={selectedStudent => onChange(selectedStudent ? selectedStudent.personId : null)}
                        value={selectedStudent}
                        disabled={!isNaN(parseInt(id))}
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                      />

                    );
                  }}
                />
                <Controller
                  name="startTime"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field, fieldState }) => {
                    const timeValue = field.value ? dayjs(field.value) : null;
                    useEffect(() => {
                      if (!field.value) {
                        field.onChange(dayjs());
                      }
                    }, [field]);
                    return (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          fullWidth
                          minutesStep={1}
                          label={t("Start Time")}
                          value={timeValue || dayjs()}
                          onChange={field.onChange}
                          {...field}
                          slotProps={{
                            textField: {
                              size: 'small',
                              helperText: fieldState.error?.message,
                              error: !!fieldState.error

                            }
                          }}
                        />
                      </LocalizationProvider>
                    )
                  }}
                />
                <Controller
                  name="complaintTypeIds"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      multiple
                      size='small'
                      options={nurseComplaintType || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        field.onChange(value.map(item => item.id));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={t('Complaints')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      value={
                        (nurseComplaintType || []).filter(item => (field.value || []).includes(item.id)) || []
                      }
                    />
                  )}
                />
                <Controller
                  name="treatmentTypeIds"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      multiple
                      size='small'
                      options={nurseTreatmentType || []}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        field.onChange(value.map(item => item.id));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label={t('Treatments')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                      value={
                        (nurseTreatmentType || []).filter(item => (field.value || []).includes(item.id)) || []
                      }
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: '1 0 0', pl: 2, pb: 2 }}>
              <Controller
                name="note"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      multiline
                      size='small'
                      minRows={3}
                      label={t('Notes')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                    </TextField>
                  )
                }}
              />
            </Box>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', mr: 1 }}>
          <Button
            size="mdium"
            variant="contained"
            color="error"
            disabled={!nurseVisit}
            onClick={() => setConfirm(true)}
            startIcon={<DeleteIcon />}
            title="Delete"
          >
            {t('DELETE')}
          </Button>
          <Button
            type="submit"
            size="medium"
            variant="contained"
            startIcon={<FaSave />}
            disabled={!formState.isDirty} >
            {t('Save')}
          </Button>
        </CardActions>
        <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
      </Card>
    </BasePathProvider>
  )
}

