import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Grid, MenuItem, Stack, TextField } from '@mui/material'
import Header from 'components/Header'
import { SubmitButton } from 'components/Buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { save } from 'store/person/employment'
import {
  countyCodeAssigned,
  fullTimeEquivalency,
  ageGroupTaught,
  districtCodeAssigned,
  jobCode,
  numberClassesTaught,
  titleIProgramStaffAssign,
  jobCodeSubcategory,
  credentialType,
} from '../List/Assignment'
import { makeId } from 'utils'
import { BasePathProvider } from 'components/Providers'
import { SaveAltIcon } from 'components/Icons'

const initialValues = {
  assignment: {
    countyCodeAssigned: null,
    fullTimeEquivalency: null,
    ageGroupTaught: null,

    districtCodeAssigned: null,
    jobCode: null,
    numberClassesTaught: null,

    titleIProgramStaff: null,
    jobCodeSubcategory: null,
    credentialType: null,
  },
}

export default function AssignmentForm({ data }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { clients } = useSelector(state => state.lookup)
  const { district, personId, employmentId } = useParams()
  const employments = useSelector(state => state.person2.employments)

  const basePath = `/person/${personId}/employment/`

  const schema = yup.object().shape({
    districtId: yup.number().default(+district),
  })

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data
      ? { assignment: { ...data } }
      : {
          ...initialValues,
        },
  })

  //   const onDestroy = async () => {}

  const onSubmit = async formData => {
    const assignmentId = data?.id || makeId()
    const datas = { ...formData.assignment, id: assignmentId }
    const fromStore = employments[+personId].find(e => e.id === +employmentId)?.stateData?.assignments
    let all = []
    if (fromStore && fromStore.length > 0) {
      all = fromStore?.filter(item => item.id !== assignmentId) || []
    }
    all.push(datas)

    const res = await dispatch(
      save({
        districtId: district,
        membershipId: employmentId || null,
        personId,
        data: all,
        type: 'assignments',
      }),
    )
    if (res.payload) {
      navigate(basePath)
    }
  }

  const onErrors = errors => console.log(errors)

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="PersonEmploymentForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={t('Assignment Information')} small close borderBottom />
        <Grid container spacing={2} sx={{ p: 2 }}>
          {district && (
            <Grid item xs={12} md={12}>
              <Controller
                name="districtId"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label={t('District')}
                      error={!!fieldState.error}
                      {...field}
                      value={+district}
                      disabled={true}
                    >
                      {clients?.map(item => (
                        <MenuItem key={item?.client?.id} value={item?.client?.id}>
                          {item?.client?.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.countyCodeAssigned"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('County Code Assigned')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {countyCodeAssigned.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.fullTimeEquivalency"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Full Time Equivalency')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {fullTimeEquivalency.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.ageGroupTaught"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Age Group Taught')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {ageGroupTaught.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.districtCodeAssigned"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('District Code Assigned')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {districtCodeAssigned.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.jobCode"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField size="small" fullWidth select label={t('Job Code')} error={!!fieldState.error} {...field}>
                    {jobCode.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.numberClassesTaught"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Number of Classes Taught')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {numberClassesTaught.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.titleIProgramStaff"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Title I Program Staff Category')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {titleIProgramStaffAssign.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.jobCodeSubcategory"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Job Code Subcategory')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {jobCodeSubcategory.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="assignment.credentialType"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Credential Type')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {credentialType.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
        </Grid>
        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 2 }}>
          <SubmitButton
            color="primary"
            title={t('SAVE')}
            onClick={handleSubmit(onSubmit, onErrors)}
            icon={<SaveAltIcon />}
          />
        </Stack>
      </Box>
    </BasePathProvider>
  )
}
