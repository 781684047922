import { BasePathProvider, MemberProvider, useMember } from 'components/Providers'
import EnrollmentsListIndex from './List'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import EnrollmentsFormIndex from './Form'
import { useEffect } from 'react'
import { getGradeLevels } from 'store/lookup'
import { useDispatch, useSelector } from 'react-redux'

export default function EnrollmentsPage() {
  const member = useMember()
  const dispatch = useDispatch()
  const { details } = useParams()
  const navigate = useNavigate()
  const gradeLevels = useSelector(state => state.lookup.gradeLevels)

  const basePath = `/member/${member?.membership?.type}/${member?.id}/enrollments`

  useEffect(() => {
    gradeLevels ||
      dispatch(getGradeLevels())
  }, [])

  if (!(member && gradeLevels)) return null

  return (
    <BasePathProvider value={`${basePath}`}>
      <MemberProvider member={member}>
        <EnrollmentsListIndex />
        <Drawer
          PaperProps={{
            sx: {
              minWidth: 500,
              maxWidth: 700,
            },
          }}
          open={Boolean(details)}
          anchor="right"
          onClose={() => navigate(`${basePath}`)}
        >
          <EnrollmentsFormIndex />
        </Drawer>
      </MemberProvider>
    </BasePathProvider>
  )
}
