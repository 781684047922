import { Box, Button, Stack } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
//import SchedulingEnrollStudentSearchBar from './Search'
import * as snackbar from 'utils/snackbar'
import SchedulingEnrollStudentInfo from './StudentInfo'
import SchedulingEnrollStudentList from './List'
import { destroy, update } from 'store/settings/course-session-enrollments'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import SchedulingEnrollStudentListEnrollments from './Enrollments'
import { LiaFileExportSolid } from 'react-icons/lia'
import StudentSearchBar from 'components/StudentSearch'

export default function SchedulingEnrollStudentListIndex({
  activeCourseSessionEnrollments,
  setActiveCourseSessionEnrollments,
  courseSessionEnrollments,
  setCourseSessionEnrollments,
  selected,
  setSelected,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const basePath = '/settings/scheduling/enroll-student/'
  
  const destroyEnrollment = async data => {
    await dispatch(destroy({ courseSessionId: data?.courseSessionId, id: data?.id })).then(res => {
      if (res?.payload?.id === data?.id) {
        const temp = activeCourseSessionEnrollments.filter(cse => cse.id !== data?.id)
        setActiveCourseSessionEnrollments(temp)
        setCourseSessionEnrollments(courseSessionEnrollments.filter(cse => cse.id !== data?.id))
      } else if (res.error) {
        const updatePayload = {
          endDate: dayjs(new Date()).format('YYYY-MM-DD'),
        }

        dispatch(update({ courseSessionId: data?.courseSessionId, id: data?.id, ...updatePayload })).then(() => {
          const startDate =
            updatePayload.startDate || courseSessionEnrollments.find(cse => cse.id !== data?.id)?.startDate
          const endDate = updatePayload.endDate
          setCourseSessionEnrollments([
            ...courseSessionEnrollments.filter(cse => cse.id !== data?.id),
            { ...activeCourseSessionEnrollments.find(cse => cse.id === data?.id), startDate, endDate },
          ])

          setActiveCourseSessionEnrollments(activeCourseSessionEnrollments.filter(cse => cse.id !== data?.id))
        })
      }
    })
  }

  return (
    <Box sx={{ mb: 6 }}>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Header title={t('Enroll By Student')} small />
        <Box>
          <Button
            startIcon={<LiaFileExportSolid />}
            size="small"
            variant="outlined"
            color="primary"
            sx={{ mr: 2 }}
            disabled={selected ? false : true}
            onClick={() => snackbar.warning(t('Not implemented yet'))}
          >
            {t('Print Schedule')}
          </Button>
          <Button
            disabled={selected ? false : true}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => navigate(`${basePath}new`)}
          >
            {t('Enroll')}
          </Button>
        </Box>
      </Stack>
      <StudentSearchBar 
        onSelect={setSelected}
        size='medium'
        setCourseSessionEnrollments={setCourseSessionEnrollments}
        setActiveCourseSessionEnrollments={setActiveCourseSessionEnrollments}
      />

      <SchedulingEnrollStudentInfo selected={selected} />
      <SchedulingEnrollStudentList
        activeCourseSessionEnrollments={activeCourseSessionEnrollments}
        destroyEnrollment={destroyEnrollment}
      />
      <SchedulingEnrollStudentListEnrollments
        selected={selected}
        courseSessionEnrollments={courseSessionEnrollments}
        setCourseSessionEnrollments={setCourseSessionEnrollments}
      />
    </Box>
  )
}
