import client from '../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../utils/redux-tool'

const name = 'staff'
const initialState = {
  person: {
    list: null,
    count: null,
    query: { que: null, limit: 10, offset: 0 },
  },
  clients: null,
  schools: null,
  academicYears: null,
  raceTypes: null,
  courses: null,
  courseSubjects: null,
  country: null,
  campus: null,
  markingPeriods: null,
  bellSchedules: null,
  period: null,
  attendanceStatus: null,
  relationshipTypes: null,
  courseSessionsEnrollmentType: null,
  languages: null,
  countries: null,
  countryList: null,
  stateList: null,
  countyList: null,
  cityList: null,
  gradeLevels: null,
  gradingMethods: null,
  statedataType: null,
  calendarEventType: null,
  calendar: null,
  courseLevel: null,
  gradeLevelAndSections: null,
  personCount: null,
  room: null,
  studentDemographics: null,
  students: {
    rows: null,
    count: null,
  },
  allStudents: {
    rows: null,
    count: null,
  },
  studentsQuery: {
    que: null,
    limit: 10,
    offset: 0,
    grade: null,
    section: null,
    selected: null,
  },
  teachers: {
    rows: null,
    count: null,
    query: { que: null, limit: 10, offset: 0 },
  },
  allStaff: {
    rows: null,
    count: null,
  },
  widgets: null,
  jobPosition: null,
  jobTitle: null,
  department: null,
  evaluation: null,
  periods: null,
  nurse: {
    conditionType: null,
    complaintType: null,
    dispositionType: null,
    treatmentType: null,
  },
  location: null,
  discipineIncidentCategory: null,
}

export const getEvaluation = createApiAction('lookup/evaluation', client.search('/api/lookup/evaluation'))
export const getDepartment = createApiAction('lookup/department', client.search('/api/lookup/department'))
export const getJobPositions = createApiAction('lookup/jobPosition', client.search('/api/lookup/job-position'))
export const getJobTitles = createApiAction('lookup/jobTitle', client.search('/api/lookup/job-title'))
export const getStudents = createApiAction('lookup/students', client.search('/api/lookup/students'))
export const getAllStudents = createApiAction('lookup/allStudents', client.search('/api/lookup/students'))
export const getTeachers = createApiAction('lookup/teachers', client.search('/api/lookup/teachers'))
export const getAllStaff = createApiAction('lookup/allStaff', client.search('/api/lookup/teachers'))
export const getCourseLevel = createApiAction('lookup/courseLevel', client.search('/api/lookup/course-level'))
export const getCalendar = createApiAction('lookup/calendar', client.search('/api/lookup/calendar'))
export const getCalendarEventType = createApiAction(
  'lookup/calendarEventType',
  client.search('/api/lookup/calendar-event-type'),
)
export const getStateDataType = createApiAction('lookup/stateDataType', client.search('/api/lookup/statedata-type'))
export const getCountries = createApiAction('lookup/countries', client.search('/api/lookup/countries'))
export const getLanguages = createApiAction('lookup/languages', client.search('/api/lookup/languages'))
export const getPeople = createApiAction('lookup/person', client.search('/api/lookup/person'))
export const getPersonCount = createApiAction('lookup/personCount', client.search('/api/lookup/person-count'))
export const getClients = createApiAction('lookup/clients', client.search('/api/management'))
export const getAcademicYears = createApiAction('lookup/academicYears', client.search('/api/lookup/academic-year'))
export const getMarkingPeriods = createApiAction(
  'lookup/markingPeriods',
  client.search('/api/lookup/academic-year/:id'),
)
export const getRaceTypes = createApiAction('lookup/raceTypes', client.search('/api/lookup/race-types'))
export const getCourses = createApiAction('lookup/courses', client.search('/api/lookup/course'))
export const getCourseSubjects = createApiAction('lookup/courseSubjects', client.search('/api/lookup/course-subject'))
export const getCourseSessions = createApiAction('lookup/courseSessions', client.search('/api/lookup/course-session'))
export const getCourseSessionsEnrollmentType = createApiAction(
  'lookup/courseSessionsEnrollmentType',
  client.search('/api/lookup/course-session-enrollment-type'),
)
export const getSchools = createApiAction('lookup/schools', client.search('/api/lookup/school'))
export const getCampus = createApiAction('lookup/campus', client.search('/api/lookup/campus'))
export const getCountry = createApiAction('lookup/country', client.search('/api/lookup/country'))
export const getGradeLevels = createApiAction('lookup/gradeLevels', client.search('/api/lookup/grade-level'))
export const getGradeLevelAndSections = createApiAction(
  'lookup/gradeLevelAndSections',
  client.search('/api/lookup/grade-level-section'),
)
export const getGradingMethods = createApiAction('lookup/gradingMethods', client.search('/api/lookup/grading-method'))
export const getAssessmentType = createApiAction('lookup/assessmentType', client.search('/api/lookup/assessment-type'))
export const getBuilding = createApiAction('lookup/building', client.search('/api/lookup/building'))
export const getRoom = createApiAction('lookup/room', client.search('/api/lookup/room'))
export const getBellScheduleType = createApiAction(
  'lookup/bellScheduleType',
  client.search('/api/lookup/bell-schedule-type'),
)
export const getGenderTypes = createApiAction('lookup/genderTypes', client.search('/api/lookup/gender-types'))
export const getEthnicityTypes = createApiAction('lookup/ethnicityTypes', client.search('/api/lookup/ethnicity-types'))
export const getBellSchedules = createApiAction('lookup/bellSchedules', client.search('/api/lookup/bell-schedule'))
export const getPeriod = createApiAction('lookup/period', client.search('/api/lookup/period'))
export const getPeriods = createApiAction('lookup/periods', client.search('/api/lookup/periods'))
export const getAttendanceStatus = createApiAction(
  'lookup/attendanceStatus',
  client.search('/api/lookup/attendance-status'),
)

export const getNurseConditionTypes = createApiAction(
  'lookup/nurseConditionType',
  client.search('/api/lookup/nurse-condition-type'),
)
export const getNurseComplaintTypes = createApiAction(
  'lookup/nurseComplaintType',
  client.search('/api/lookup/nurse-complaint-type'),
)
export const getNurseDispositionTypes = createApiAction(
  'lookup/nurseDispositionType',
  client.search('/api/lookup/nurse-disposition-type'),
)
export const getNurseTreatmentTypes = createApiAction(
  'lookup/nurseTreatmentType',
  client.search('/api/lookup/nurse-treatment-type'),
)
export const getLocations = createApiAction(
  'lookup/location',
  client.search('/api/lookup/location'),
)
export const getDiscipineIncidentCategories = createApiAction(
  'lookup/discipineIncidentCategory',
  client.search('/api/lookup/incident-category'),
)
export const getRelationshipTypes = createApiAction(
  'lookup/relationshipTypes',
  client.search('/api/lookup/relationship-type'),
)
export const getWidgets = createApiAction('lookup/widgets', client.search('/api/lookup/widgets'))
export const getStudentDemographics = createApiAction(
  'lookup/studentDemographics',
  client.search('/api/lookup/student-demographics'),
)

export const getAcademicYearProviders = createApiAction('lookup/academicYearProviders', client.search('/api/lookup/academic-year-provider'))


const check = action => {
  const [lookup, , result] = action.type.split('/')
  return lookup === 'lookup' && result === 'fulfilled'
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    resetBellSchedules: state => {
      state.bellSchedules = null
    },
    resetPeriod: state => {
      state.period = null
    },
    resetRoom: state => {
      state.room = null
    },
    resetCountryList: state => {
      state.countryList = null
    },
    resetStateList: state => {
      state.stateList = null
    },
    resetCountyList: state => {
      state.countyList = null
    },
    resetCityList: state => {
      state.cityList = null
    },
    studentsSelected: (state, action) => {
      state.studentsQuery.selected = action.payload
    },
    studentsQuery: (state, action) => {
      state.studentsQuery = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCountries.fulfilled, (state, action) => {
        // TODO: let's discuss this approach
        const queryCountry = action.meta.arg?.country
        const queryState = action.meta.arg?.state
        const queryCounty = action.meta.arg?.county
        if (!queryCountry && !queryState && !queryCounty) {
          state.countryList = action.payload
        } else if (queryCountry && !queryState && !queryCounty) {
          state.stateList = action.payload
        } else if (queryCountry && queryState && !queryCounty) {
          state.countyList = action.payload
        } else if (queryCountry && queryState && queryCounty) {
          state.cityList = action.payload
        }
      })
      .addCase(getPeople.fulfilled, (state, action) => {
        state.person.list = action.payload.rows
        state.person.count = action.payload.count
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.students.list = action.payload.rows
        state.students.count = action.payload.count
      })
      .addCase(getAllStudents.fulfilled, (state, action) => {
        state.allStudents.list = action.payload.rows
        state.allStudents.count = action.payload.count
      })
      .addCase(getTeachers.fulfilled, (state, action) => {
        state.teachers.rows = action.payload.rows
        state.teachers.count = action.payload.count
        state.teachers.query = action.meta.arg
      })
      .addCase(getAllStaff.fulfilled, (state, action) => {
        state.allStaff.rows = action.payload.rows
        state.allStaff.count = action.payload.count
      })
      .addCase(getWidgets.fulfilled, (state, action) => {
        state.widgets = action.payload
      })
      .addCase(getPeriods.fulfilled, (state, action) => {
        state.periods = action.payload
      })
      .addCase(getStudentDemographics.fulfilled, (state, action) => {
        state.studentDemographics = action.payload
      })
      .addCase(getNurseConditionTypes.fulfilled, (state, action) => {
        state.nurse.conditionType = action.payload
      })
      .addCase(getNurseComplaintTypes.fulfilled, (state, action) => {
        state.nurse.complaintType = action.payload
      })
      .addCase(getNurseTreatmentTypes.fulfilled, (state, action) => {
        state.nurse.treatmentType = action.payload
      })
      .addCase(getNurseDispositionTypes.fulfilled, (state, action) => {
        state.nurse.dispositionType = action.payload
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.location = action.payload
      })
      .addCase(getDiscipineIncidentCategories.fulfilled, (state, action) => {
        state.discipineIncidentCategory = action.payload
      })
    builder.addMatcher(check, (state, action) => {
      const key = action.type.split('/')[1]
      state[key] = action.payload
    })
  },
})
export const {
  resetBellSchedules,
  resetPeriod,
  resetRoom,
  resetCountryList,
  resetStateList,
  resetCountyList,
  resetCityList,
  studentsSelected,
  studentsQuery,
} = sessionSlice.actions

export default sessionSlice.reducer
