import { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'
import { getUserServiceRoles, setUserServiceRoles } from 'store/member/roles'
import { useDispatch, useSelector } from 'react-redux'
import { SubTitle } from 'components/typo'
import { useMember } from 'components/Providers'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

export default function ServiceRoles() {
  const theme = useTheme()
  const { search: roles } = useSelector(state => state.settings.role)
  const [selectedRoles, setSelectedRoles] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const member = useMember()

  const handleChange = async event => {
    const {
      target: { value },
    } = event
    setSelectedRoles(value)
    await dispatch(setUserServiceRoles({ type: member.membership.type, memberId: member.id, roles: value }))
  }

  useEffect(() => {
    const fetch = async () => {
      const { payload, error } = await dispatch(
        getUserServiceRoles({ type: member.membership.type, memberId: member.id }),
      )
      if (error) {
        console.error(error)
      }
      if (payload) {
        setSelectedRoles(payload.map(i => i.roleId))
      }
    }
    fetch()
  }, [dispatch, member.membership.type, member.person.id])

  return (
    <Box sx={{ m: 2 }}>
      <SubTitle>{t('Service Roles')}</SubTitle>
      <FormControl sx={{ m: 0, minWidth: '100%' }}>
        <Select
          multiple
          value={selectedRoles}
          onChange={handleChange}
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => {
                const role = roles.find(r => r.id === value)

                return role ? (
                  <Chip
                    key={value}
                    variant="outlined"
                    color={role.enabled ? (role.shared ? 'primary' : 'default') : 'error'}
                    label={`${role.name}`}
                  />
                ) : null
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {roles.map(role => (
            <MenuItem key={role.id} value={role.id} style={getStyles(role.name, selectedRoles, theme)}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}
