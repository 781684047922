import { Card, CardContent, Grid, Skeleton } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'

export default function DisciplinIncidentEditSkeleton() {
  const { t } = useTranslation()

  return (
    <BasePathProvider>
      <Card data-component="DisciplinIncidentEditSkeleton" elevation={0} >
        <Header title={t('Edit Incident')} small close borderBottom />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" width={250} height={30} />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" width={250} height={30} />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" width={250} height={30} />
            </Grid>
            <Grid item sm={6} xs={6} md={6}>
              <Skeleton variant="rounded" width={250} height={30} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </BasePathProvider>
  )
}
