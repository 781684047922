import { yupResolver } from '@hookform/resolvers/yup'
import { Box, CardContent, Grid, TextField, Button, Card, CardActions, Avatar, MenuItem, LinearProgress } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider, useBasePath } from 'components/Providers'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { DeleteIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { destroy } from 'store/nurse/nurse-visit'
import { save } from 'store/nurse/nurse-visit'
import { FaSave } from 'react-icons/fa'
import { getNurseComplaintTypes, getNurseTreatmentTypes, getNurseDispositionTypes, getAllStudents } from 'store/lookup'
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import StudentSearchBar from 'components/StudentSearch'

export default function NurseFinalizeVisitForm({ nurseVisit }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const basePath = useBasePath()
  const { id } = useParams()
  const [confirm, setConfirm] = useState(false)
  const nurseComplaintType = useSelector(state => state.lookup.nurse.complaintType)
  const nurseDispositionType = useSelector(state => state.lookup.nurse.dispositionType)
  const nurseTreatmentType = useSelector(state => state.lookup.nurse.treatmentType)
  const allStudents = useSelector(state => state.lookup.allStudents.rows);
  const [loadingStudents, setLoadingStudents] = useState(true)
  const selectedStudentId = nurseVisit?.studentId


  const { academicYear } = useSelector(state => state.session);

  useEffect(() => {
    if (!allStudents || !allStudents.length) {
      setLoadingStudents(true)
      dispatch(getAllStudents({ limit: 1000 })).finally(() => setLoadingStudents(false))
    } else {
      setLoadingStudents(false)
    }
  }, [dispatch, Boolean(allStudents), academicYear.id])

  const schema = yup.object().shape({
    studentId: yup.number().required('Required'),
    endTime: yup.date()
      .required('Required')
      .test('is-greater-than-startTime', 'End time must be greater than start time', function (value) {
        const startTime = nurseVisit?.startTime;
        return startTime ? value > new Date(startTime) : true;
      }),
    note: yup.string().required('Required'),
    dispositionTypeId: yup.string().required('Required'),
  })

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...nurseVisit,
      ...(nurseVisit?.id ? { endTime: nurseVisit.endTime ? dayjs(nurseVisit.endTime) : null } : { endTime: null }),
    },
  })

  const selectedStudent = allStudents?.find(student => student.personId === selectedStudentId) || null;

  const onSubmit = async data => {
    const payload = {
      ...data,
    };
    let exceptions = ['studentId', 'person', 'campusId'];
    if (id !== 'new') {
      exceptions.push('studentId');
    }

    const res = await dispatch(save(_.omit(payload, exceptions)));
    if (res.payload) {
      navigate(basePath);
    }
  };

  const onDestroy = async () => {
    setConfirm(false)
    await dispatch(destroy({ id: nurseVisit.id }))
    navigate(basePath)
  }

  useEffect(() => {
    nurseComplaintType ||
      dispatch(getNurseComplaintTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, getNurseComplaintTypes])

  useEffect(() => {
    nurseDispositionType ||
      dispatch(getNurseDispositionTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, getNurseDispositionTypes])

  useEffect(() => {
    nurseTreatmentType ||
      dispatch(getNurseTreatmentTypes()).catch(error => {
        console.error(error)
      })
  }, [dispatch, getNurseTreatmentTypes])

  if (loadingStudents) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }
  
  return (
    <BasePathProvider value={`${basePath}/visits`}>
      <Box sx={{ overflowY: 'scroll', border: 'none' }}>
        <Header title={nurseVisit.dispositionTypeId ? t('Edit Finalized Visit') : t('Finalize Visit')} small close borderBottom />
      </Box>
      <Card data-component="NurseAddVisitForm" elevation={0} component="form" onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '10px', alignSelf: 'stretch', minWidth: '100%', pl: 2, pt: 2, pb: 2 }}>
              <Avatar variant='square' sx={{ width: 160, height: 140 }} src={selectedStudent?.person?.profilePicture?.location} />
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: '1 0 0', }}>
                <Controller
                  name='studentId'
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field: { onChange, value, ...field }, fieldState }) => {
                    const selectedStudent = allStudents?.find(student => student.personId === value) || null;
                    return (
                      <StudentSearchBar 
                        isOptionEqualToValue={(option, value) => option?.personId === value?.id}
                        onSelect={selectedStudent => onChange(selectedStudent ? selectedStudent.personId : null)}
                        value={selectedStudent}
                        disabled={!isNaN(parseInt(id))}
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : ''}
                      />
                    );
                  }}
                />
                <Controller
                  name="endTime"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field, fieldState }) => {
                    const timeValue = field.value ? dayjs(field.value) : null;
                    useEffect(() => {
                      if (!field.value) {
                        field.onChange(dayjs());
                      }
                    }, [field]);
                    return (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          fullWidth
                          minutesStep={1}
                          //defaultValue={dayjs()}
                          label={t("End Time")}
                          error={!!fieldState.error}
                          value={timeValue || dayjs()}
                          onChange={field.onChange}
                          helperText={fieldState.error?.message}
                          {...field}
                          slotProps={{
                            textField: {
                              size: 'small',
                              helperText: fieldState.error?.message,
                              error: !!fieldState.error
                            }
                          }}
                        />
                      </LocalizationProvider>
                    )
                  }}
                />

                <Controller
                  name="dispositionTypeId"
                  control={control}
                  rules={{ required: 'Field is required' }}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        select
                        fullWidth
                        size='small'
                        label={t('Disposition')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                      >
                        {nurseDispositionType?.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', flex: '1 0 0', pl: 2, pb: 2 }}>
              <Controller
                name="note"
                control={control}
                rules={{ required: 'Field is required' }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      fullWidth
                      multiline
                      size='small'
                      minRows={3}
                      label={t('Notes')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    >
                    </TextField>
                  )
                }}
              />
            </Box>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', mr: 1 }}>
          <Button
            size="mdium"
            variant="contained"
            color="error"
            disabled={!nurseVisit}
            onClick={() => setConfirm(true)}
            startIcon={<DeleteIcon />}
            title="Delete"
          >
            {t('DELETE')}
          </Button>
          <Button
            type="submit"
            size="medium"
            variant="contained"
            startIcon={<FaSave />}
            disabled={!formState.isDirty} >
            {t('Save')}
          </Button>
        </CardActions>
        <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
      </Card>
    </BasePathProvider>
  )
}