import { Box, Button, Stack } from '@mui/material'
import Header from 'components/Header'
import { useTranslation } from 'react-i18next'
import SchedulingCoursesSearchBar from './Search'
import SchedulingCoursesList from './List'
import { useNavigate } from 'react-router-dom'
import { search as providerCourses } from 'store/provider/course'
import { search as clientCourses } from 'store/settings/course'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { LiaFileExportSolid } from 'react-icons/lia'

export default function SchedulingCoursesListIndex() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const academicYear = useSelector(state => state.session?.academicYear)

  const { query: providerCourseQuery, list: providerCourseList } = useSelector(state => state.provider.course)
  const { count, query: clientCourseQuery, list: clientCourseList } = useSelector(state => state.settings.course)

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  // useEffect(() => {
  //   providerCourseList || dispatch(providerCourses({ ...filterNullValues(providerCourseQuery), offset: 0 }))
  //   clientCourseList || dispatch(clientCourses({ ...filterNullValues(clientCourseQuery), offset: 0 }))
  // }, [])

  useEffect(() => {
    dispatch(providerCourses({ ...filterNullValues(providerCourseQuery), offset: 0 }))
    dispatch(clientCourses({ ...filterNullValues(clientCourseQuery), offset: 0 }))
  }, [academicYear])

  const handleChangePage = (event, page) => {
    const limit = clientCourseQuery?.limit || 10
    dispatch(clientCourses({ ...filterNullValues(clientCourseQuery), limit, offset: limit * page }))
  }

  const handleChangeRowsPerPage = event => {
    const limit = event.target.value
    dispatch(clientCourses({ ...filterNullValues(clientCourseQuery), offset: 0, limit }))
  }

  const basePath = '/settings/scheduling/courses/'

  const exportCSV = data => {
    if (data) {
      const headers = ['No', 'SCED Code', 'Subject Area', 'Course Name', 'Credits']

      const csvRows = data.map((course, index) => {
        return [
          index + 1,
          course?.code || ' ',
          course?.courseSubject?.name || ' ',
          course?.name || ' ',
          course?.credit,
        ].join(',')
      })

      const csvContent = 'data:text/csv;charset=utf-8,' + [headers.join(','), ...csvRows].join('\n')

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'course-list.csv')
      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
    }
  }

  const exportNow = () => {
    const filteredQuery = Object.fromEntries(
      Object.entries(clientCourseQuery).filter(([key, value]) => key && value !== null),
    )
    dispatch(clientCourses({ ...filteredQuery, limit: count, offset: 0, export: true })).then(response => {
      exportCSV(response?.payload?.rows)
    })
  }

  if (!(clientCourseList && providerCourseList)) return null

  return (
    <Box>
      <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Header title={t('Course List')} small />
        <Box>
          <Button
            startIcon={<LiaFileExportSolid />}
            size="small"
            variant="outlined"
            color="primary"
            onClick={exportNow}
            sx={{ mr: 2 }}
          >
            {t('Export')}
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={() => navigate(`${basePath}new`)}>
            {t('Add Course')}
          </Button>
        </Box>
      </Stack>
      <SchedulingCoursesSearchBar />
      <SchedulingCoursesList handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />
    </Box>
  )
}
