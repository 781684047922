import {
  Button,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import { useTranslation } from 'react-i18next'
import IconProvider from 'components/IconProvider'
import { useEffect, useState } from 'react'
import { FaSave } from 'react-icons/fa'
import Period from './Period'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { save as savePeriodAttendance } from 'store/settings/attendance'
import { save as teacherSavePeriodAttendance } from 'store/academic/attendance-period'
import { updateAttendanceTakenPeriods } from 'store/academic/schedule'

const tableHeadCellStyle = {
  fontWeight: '500 !important',
  color: 'white',
  width: '9em',
}

export default function index({ list, attendanceStatus, selectedDate, isAdmin, selectedCourseSession }) {
  const { t } = useTranslation()
  const [status, setStatus] = useState()
  const dispatch = useDispatch()

  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: {
      periods: list,
    },
  })

  function applyToAll() {
    if (status) {
      list?.forEach(item => {
        if (isAdmin || (!isAdmin && !item.perAdmin)) {
          item.periodStatus = status
        }
      })
    }
  }

  const periodArrayControl = useFieldArray({ control, name: 'periods', keyName: 'uuid' })

  useEffect(() => {
    applyToAll()
    reset({ periods: list })
  }, [status])

  const onSubmit = async formData => {
    const periodAttendanceList = []
    formData.periods.map(item =>
      periodAttendanceList.push({
        courseSessionEnrollmentId: item.courseSessionEnrollmentId,
        id: item.id,
        date: selectedDate,
        periodId: item.periodId,
        statusId: item.periodStatus,
      }),
    )

    const handleAttendanceResponse = data => {
      let isChecked = false
      if (data.payload?.periodAttendance?.some(item => item.status && item.status.id)) {
        isChecked = true
      }
      dispatch(updateAttendanceTakenPeriods({ periodId: data.payload?.periodAttendance?.[0]?.period?.id, isChecked }))
    }

    if (isAdmin) {
      dispatch(savePeriodAttendance({ periodAttendanceList })).then(data => {
        handleAttendanceResponse(data)
      })
    } else {
      dispatch(teacherSavePeriodAttendance({ courseSessionId: selectedCourseSession, periodAttendanceList })).then(
        data => {
          handleAttendanceResponse(data)
        },
      )
    }
  }

  const onErrors = errors => console.log(errors)
  return (
    <Paper
      data-component="AttendanceForm"
      component="form"
      onSubmit={handleSubmit(onSubmit, onErrors)}
      noValidate
      sx={{ width: '100%', p: 2, mb: 2 }}
    >
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.main' }}>
            <TableRow>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '15em' }}>{t('Last Name')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '15em' }}>{t('First Name')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, width: '19em' }}>
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t('One Click Attend...')}
                  placeholder="One Click Attend..."
                  onChange={e => {
                    setStatus(e.target.value)
                  }}
                  className="custom-text-field"
                  sx={{
                    '& .MuiSelect-select': {
                      color: 'white',
                    },
                    '& .MuiInputLabel-root': {
                      color: 'white',
                    },
                  }}
                >
                  <MenuItem value="0">Not Taken</MenuItem>
                  {attendanceStatus?.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {<IconProvider icon={item.ui?.icon} color={item.ui?.color} />} {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, textAlign: 'center' }}>{t('Prev. Period')}</TableHeadCell>
              <TableHeadCell sx={{ ...tableHeadCellStyle, textAlign: 'center' }}>{t('Daily')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {periodArrayControl.fields?.map((item, index) => (
              <Period
                key={item.id}
                path="periods"
                control={control}
                item={item}
                index={index}
                attendanceStatus={attendanceStatus}
                setValue={setValue}
                isAdmin={isAdmin}
                {...periodArrayControl}
              />
            ))}
          </TableBody>
        </Table>
        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 0.5, mb: 3 }}>
          <Button
            type="submit"
            color="primary"
            size="small"
            variant="contained"
            sx={{ mr: 2, cursor: 'pointer', opacity: list?.length ? 1 : 0 }}
            startIcon={<FaSave />}
          >
            {t('SAVE')}
          </Button>
        </Stack>
      </TableContainer>
    </Paper>
  )
}
