import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider, PersonProvider, useMember } from 'components/Providers'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguages, getRelationshipTypes } from 'store/lookup'
import { useEffect, useState } from 'react'
import { SubmitButton } from 'components/Buttons'
import InviteIcon from '@mui/icons-material/Send'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { destroy as deleteContact, save as saveContact } from 'store/member/contact'
import { omit } from 'lodash'
import { useNavigate } from 'react-router-dom'
import PhoneList from './PhoneList'
import AddressList from './AddressList'
import Confirm from 'components/Dialog'
import { DeleteIcon } from 'components/Icons'
import { sendInvitation } from 'store/person'
import * as snackbar from '../../../../utils/snackbar'

const titles = ['Mr.', 'Ms.', 'Mrs.']

const initialValues = {
  relationship: null,
  contact: {
    type: 'contact',
    title: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    preferredLanguage: null,
    addresses: [{ type: 'home', street: '', line2: '', city: '', state: '', zip: '', country: '' }],
    phones: [{ type: 'home', number: '' }],
  },
  password: '',
  phoneNotification: false,
  emailNotification: false,
  textNotification: false,
  pickup: false,
  emergency: false,
  hasParentalAccess: false,
  note: null,
}

export default function ContactsForm({ data }) {
  const dispatch = useDispatch()
  const [confirm, setConfirm] = useState(false)

  const member = useMember()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const relationshipTypes = useSelector(state => state.lookup.relationshipTypes)
  const languages = useSelector(state => state.lookup.languages)

  const searchParams = new URLSearchParams(window.location.search)
  const guardian = data?.isGuardian || searchParams.get('guardian') === 'true' ? true : false
  const seq = parseInt(searchParams.get('seq'))

  const [passwordVisibility, setPasswordVisibility] = useState(false)

  const basePath = `/member/${member?.membership?.type}/${member?.id}/contacts`

  useEffect(() => {
    relationshipTypes || dispatch(getRelationshipTypes())
    languages || dispatch(getLanguages())
  }, [dispatch])

  const schema = yup.object().shape({
    relationship: yup.string().required('Required'),
    emergency: yup.boolean(),
    contact: yup.object().shape({
      title: yup.string().nullable(),
      email: yup.string().email().nullable(),
      firstName: yup.string().required('Required'),
      lastName: yup.string().required('Required'),
      phones: yup.array().of(
        yup.object().shape({
          number: yup.string(),
          type: yup.string().when('number', {
            is: number => number && number.length > 0,
            then: yup.string().required('Type is required'),
            otherwise: yup.string(),
          }),
        }),
      ),
    }),
  })

  const { control, handleSubmit, getValues, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialValues,
      ...data,
      phoneNotification: data?.notification?.find(item => item === 'phone') ? true : false,
      emailNotification: data?.notification?.find(item => item === 'email') ? true : false,
      textNotification: data?.notification?.find(item => item === 'sms') ? true : false,
      seq: data?.seq || seq,
      isGuardian: guardian,
    },
  })

  const inviteHandle = () => {
    if (member.id) {
      dispatch(sendInvitation({ type: 'parent', personId: data.contact.id }))
    }
  }
  const onDestroy = async () => {
    setConfirm(false)
    const id = getValues()?.id
    dispatch(deleteContact({ type: member?.membership?.type, memberId: member?.id, id }))
    navigate(basePath)
  }

  const onSubmit = async formData => {
    let datas = omit(formData, [
      'phoneNotification',
      'emailNotification',
      'textNotification',
      'password',
      'personId',
      'contactId',
    ])
    let notifications = []
    formData.phoneNotification === true && notifications.push('phone')
    formData.emailNotification === true && notifications.push('email')
    formData.textNotification === true && notifications.push('sms')
    datas.notification = notifications
    datas.contact = omit(datas.contact, ['id', 'user'])

    const temp = []
    datas.contact.phones?.forEach(item => {
      if (JSON.stringify(item) != '{}') {
        temp.push(item)
      }
    })
    datas.contact.phones = temp

    const temp2 = []
    datas.contact.addresses?.forEach((item, index) => {
      let add = item
      if (index === 0) {
        add.type = 'home'
      }
      if (add.street?.length > 0) {
        if (add.city?.length < 1 || !add.city) add.city = ''
        if (add.state?.length < 1 || !add.state) add.state = ''
        if (add.zip?.length < 1 || !add.zip) add.zip = ''
        if (add.country?.length < 1 || !add.country) add.country = ''
        temp2.push(add)
      } else {
        if (item.id) {
          if (add.city?.street < 1 || !add.street) add.street = ''
          if (add.city?.length < 1 || !add.city) add.city = ''
          if (add.state?.length < 1 || !add.state) add.state = ''
          if (add.zip?.length < 1 || !add.zip) add.zip = ''
          if (add.country?.length < 1 || !add.country) add.country = ''
          temp2.push(add)
        }
      }
    })

    datas.contact.addresses = temp2

    if (!guardian) {
      datas.contact = omit(datas.contact, ['addresses'])
    }
    if (data) {
      datas.contact.id = data.contact.id
    }
    if (datas.contact.preferredLanguage === '') datas.contact.preferredLanguage = null

    if (!datas?.contact?.type) {
      datas.contact.type = 'contact'
    }

    const res = await dispatch(saveContact({ type: member?.membership?.type, memberId: member?.id, ...datas }))
    if (res.payload) {
      navigate(basePath)
    } else {
      console.error(res.errors)
    }
  }

  const onErrors = errors => console.log(errors)

  const phoneArrayControl = useFieldArray({ control, name: 'contact.phones', keyName: 'uuid' })
  const addressArrayControl = useFieldArray({ control, name: 'contact.addresses', keyName: 'uuid' })

  // const inviteHandle = () => {}

  const phones = useWatch({ control, name: 'contact.phones' })
  const email = useWatch({ control, name: 'contact.email' })

  useEffect(() => {
    if (!email) {
      setValue('emailNotification', false)
    }
    if (!phones[0]?.number) {
      setValue('emergency', false)
    }
    if (!phones.find(item => item.type === 'mobile')) {
      setValue('textNotification', false)
    }
    if (!phones.find(item => item.number !== undefined && item.number !== '')) {
      setValue('phoneNotification', false)
    }
  }, [phones, email])

  if (!(languages && relationshipTypes)) return null

  return (
    <BasePathProvider value={`${basePath}/profile`}>
      <Box
        data-component="MemberEditForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ py: 2, overflowY: 'scroll', border: 'none' }}
      >
        {/* <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {guardian ? 'Parent/Guardian Information' : 'Additional Contact Information'}
          </Typography>
          <Header sx={{ pt: 2, pb: 3, pr: 0 }} close /> */}

        <Header
          title={guardian ? 'Parent/Guardian Information' : 'Additional Contact Information'}
          small
          close
          borderBottom
        />
        <Box sx={{ px: 3, pt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Controller
                name="relationship"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      fullWidth
                      select
                      label={t('Relationship')}
                      error={!!fieldState.error}
                      {...field}
                    >
                      {relationshipTypes &&
                        relationshipTypes.map(item => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="contact.title"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <Autocomplete
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disablePortal
                      {...field}
                      size="small"
                      fullWidth
                      freeSolo
                      options={titles || []}
                      onChange={(event, value) => {
                        value ? setValue('contact.title', value) : null
                      }}
                      onInputChange={(event, value, reason) => {
                        switch (reason) {
                          case 'reset':
                            break
                          case 'clear':
                            setValue('contact.title', null)
                            break
                          case 'input':
                            setValue('contact.title', value)
                            break
                          default:
                            break
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...field}
                          fullWidth
                          label={t('Title')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="contact.firstName"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField size="small" fullWidth label={t('First Name')} error={!!fieldState.error} {...field} />
                  )
                }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Controller
                name="contact.lastName"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <TextField size="small" fullWidth label={t('Last Name')} error={!!fieldState.error} {...field} />
                  )
                }}
              />
            </Grid>
            {guardian && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="contact.email"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        label={t('Email')}
                        error={!!fieldState.error}
                        {...field}
                        onChange={event => {
                          event.target.value === ''
                            ? setValue('contact.email', null)
                            : setValue('contact.email', event.target.value)
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => inviteHandle()} edge="end">
                                <InviteIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {guardian && (
              <Grid item xs={6} md={6}>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        size="small"
                        fullWidth
                        label={t('Password')}
                        type={passwordVisibility ? 'text' : 'password'}
                        error={!!fieldState.error}
                        {...field}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setPasswordVisibility(!passwordVisibility)}
                              >
                                {!passwordVisibility ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )
                  }}
                />
              </Grid>
            )}
            {guardian && (
              <PersonProvider person={data?.contact}>
                <Grid item xs={12}>
                  <AddressList control={control} person={data?.id} path="contact.addresses" {...addressArrayControl} />
                </Grid>
              </PersonProvider>
            )}
            <Grid item xs={12}>
              <PhoneList
                control={control}
                personId={data?.id}
                path="contact.phones"
                minCount={guardian ? 2 : 1}
                {...phoneArrayControl}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="contact.preferredLanguage"
                control={control}
                render={({ field: { ...field }, fieldState }) => {
                  return (
                    <Autocomplete
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      disablePortal
                      {...field}
                      size="small"
                      fullWidth
                      options={languages?.map(item => item.id) || []}
                      onChange={(event, value) => {
                        value ? setValue('contact.preferredLanguage', value) : null
                      }}
                      getOptionLabel={option => {
                        return (
                          (languages.find(item => item.id === option) &&
                            `${languages.find(item => item.id === option)?.name} - ${
                              languages.find(item => item.id === option)?.originName
                            }`) ||
                          option
                        )
                      }}
                      onInputChange={(event, value, reason) => {
                        switch (reason) {
                          case 'reset':
                            break
                          case 'clear':
                            setValue('contact.preferredLanguage', null)
                            break
                          case 'input':
                            break
                          default:
                            break
                        }
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          {...field}
                          fullWidth
                          label={t('Preferred Language')}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth sx={{ p: 1, pl: 2, borderRadius: '4px', border: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <Typography>Notifications</Typography>
                <Stack flexDirection={'column'} sx={{ ml: 2 }}>
                  {guardian && (
                    <Controller
                      name="phoneNotification"
                      control={control}
                      render={({ field: { value, ...field }, fieldState }) => {
                        return (
                          <FormControlLabel
                            error={!!fieldState.error}
                            control={<Checkbox color="primary" checked={value} />}
                            label={t('Phone')}
                            labelPlacement="end"
                            disabled={
                              phones.find(item => item.number !== undefined && item.number !== '') ? false : true
                            }
                            {...field}
                          />
                        )
                      }}
                    />
                  )}
                  {guardian && (
                    <Controller
                      name="emailNotification"
                      control={control}
                      render={({ field: { value, ...field }, fieldState }) => {
                        return (
                          <FormControlLabel
                            error={!!fieldState.error}
                            control={<Checkbox color="primary" checked={value} />}
                            label={t('Email')}
                            labelPlacement="end"
                            disabled={email ? false : true}
                            {...field}
                          />
                        )
                      }}
                    />
                  )}
                  {guardian && (
                    <Controller
                      name="textNotification"
                      control={control}
                      render={({ field: { value, ...field }, fieldState }) => {
                        return (
                          <FormControlLabel
                            error={!!fieldState.error}
                            control={<Checkbox color="primary" checked={value} />}
                            label={t('Text')}
                            labelPlacement="end"
                            disabled={
                              phones.find(
                                item => item.type === 'mobile' && item.number !== '' && item.number !== undefined,
                              )
                                ? false
                                : true
                            }
                            {...field}
                          />
                        )
                      }}
                    />
                  )}

                  <Controller
                    name="pickup"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          error={!!fieldState.error}
                          control={
                            <Checkbox
                              color="primary"
                              checked={value}
                              onChange={e => {
                                field.onChange(e)
                                if (!e.target.checked) {
                                  snackbar.warning('Please upload a COURT ORDER!')
                                }
                              }}
                            />
                          }
                          label={t('Allow Pickup')}
                          labelPlacement="end"
                          {...field}
                        />
                      )
                    }}
                  />

                  <Controller
                    name="emergency"
                    control={control}
                    render={({ field: { value, ...field }, fieldState }) => {
                      return (
                        <FormControlLabel
                          error={!!fieldState.error}
                          control={<Checkbox color="primary" checked={value} />}
                          label={t('Emergency Call')}
                          labelPlacement="end"
                          disabled={phones[0]?.number ? false : true}
                          {...field}
                        />
                      )
                    }}
                  />
                  {guardian && (
                    <Controller
                      name="hasParentalAccess"
                      control={control}
                      render={({ field: { value, ...field }, fieldState }) => {
                        return (
                          <FormControlLabel
                            error={!!fieldState.error}
                            control={<Checkbox color="primary" checked={value} />}
                            label={t('SIS Access')}
                            labelPlacement="end"
                            {...field}
                          />
                        )
                      }}
                    />
                  )}
                </Stack>
              </FormControl>
            </Grid>

            {guardian && (
              <Grid item xs={12} md={12}>
                <Controller
                  name="note"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        label={t('Additional Notes')}
                        error={!!fieldState.error}
                        {...field}
                      />
                    )
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ my: 4 }}>
            <Button
              size="small"
              variant="contained"
              color="error"
              onClick={() => setConfirm(true)}
              sx={{ mr: 2 }}
              disabled={data?.id ? false : true}
            >
              <DeleteIcon /> {t('DELETE CONTACT')}
            </Button>
            <SubmitButton size="small" color="primary" title={t('SAVE')} onClick={handleSubmit(onSubmit, onErrors)} />
          </Stack>
        </Box>
      </Box>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onDestroy} />
    </BasePathProvider>
  )
}
