import { Box, Button, Grid, InputAdornment, MenuItem, Stack, TextField } from '@mui/material'
import { CloseIcon, SearchIcon } from 'components/Icons'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getClients, getJobPositions, getJobTitles } from 'store/lookup'
import { LiaFileExportSolid } from 'react-icons/lia'
import { search, setSearchPersonQuery } from 'store/person/index'
import { debounce, omit } from 'lodash'

const baseUrl = `/person/`

export default function SearchBar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { query, count } = useSelector(state => state.person2)
  const { clients } = useSelector(state => state.lookup)
  const jobTitles = useSelector(state => state.lookup.jobTitle)
  const jobPositions = useSelector(state => state.lookup.jobPosition)

  useEffect(() => {
    jobTitles || dispatch(getJobTitles())
  }, [jobTitles])

  useEffect(() => {
    jobPositions || dispatch(getJobPositions())
  }, [jobPositions])

  useEffect(() => {
    clients || dispatch(getClients())
  }, [])

  const filterNullValues = obj => {
    let temp = {}
    Object.keys(obj).forEach(key => {
      obj[key] !== null ? (temp[key] = obj[key]) : null
    })
    return temp
  }

  const applyFilter = async (key, value) => {
    if ((typeof value === 'string' && value.trim().length > 2) || (+value && value > 0)) {
      dispatch(search({ ...filterNullValues(query), [key]: value, offset: 0 }))
    } else if (!value || value === '' || typeof value !== 'string') {
      dispatch(search({ ...filterNullValues(omit(query, key)), offset: 0 }))
    }
  }

  const delayedSearch = useMemo(() => {
    return debounce((que, callback) => callback('que', que), 500)
  }, [dispatch])

  const exportCSV = data => {
    if (data) {
      const headers = ['No', 'Full Name', 'State ID', 'Extension', 'Email', 'Job Title', 'District', 'Campus']

      const csvRows = data.map((person, index) => {
        const membership = person.memberships[0]
        const campusEnrollment = membership?.campusEnrollments[0]

        return [
          index + 1,
          [person.firstName, person.middleName, person.lastName].filter(Boolean).join(' '),
          membership?.stateId || ' ',
          membership?.phoneExtension || ' ',
          person?.email,
          jobTitles?.find(jt => jt.id === membership?.jobTitleId)?.name || ' ',
          membership?.district?.abbreviation || membership?.district?.name || ' ',
          campusEnrollment?.campus?.name || ' ',
        ].join(',')
      })

      const csvContent = 'data:text/csv;charset=utf-8,' + [headers.join(','), ...csvRows].join('\n')

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'employees.csv')
      document.body.appendChild(link)

      link.click()
      document.body.removeChild(link)
    }
  }

  const exportNow = () => {
    const filteredQuery = Object.fromEntries(Object.entries(query).filter(([key, value]) => key && value !== null))
    dispatch(search({ ...filteredQuery, limit: count, offset: 0, export: true })).then(response => {
      exportCSV(response?.payload?.rows)
    })
  }

  if (!(clients && jobPositions)) return null

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ pr: 2, pt: 2 }} columnGap={2}>
        <Button onClick={exportNow} startIcon={<LiaFileExportSolid />} variant="outlined" color="primary">
          {t('Export')}
        </Button>
        <Button onClick={() => navigate(`${baseUrl}new`)} variant="contained" color="primary">
          {t('Add')}
        </Button>
      </Stack>
      <Stack direction="row" spacing={2} sx={{ pl: 0, pr: 1, pt: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={4.5} md={4.5}>
            <TextField
              size="medium"
              fullWidth
              placeholder={t('Name, email, etc...')}
              value={query?.que || ''}
              onChange={e => {
                if (query?.que !== e.target.value) {
                  if (e.target.value?.length > 0) {
                    dispatch(setSearchPersonQuery({ ...query, que: e.target.value }))
                  } else {
                    dispatch(setSearchPersonQuery({ ...query, que: null }))
                  }
                }
                if (e.target.value?.length > 0) {
                  delayedSearch(e.target.value, applyFilter)
                } else {
                  delayedSearch(null, applyFilter)
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <CloseIcon
                    onClick={() => {
                      dispatch(setSearchPersonQuery({ ...query, que: null }))
                      delayedSearch(null, applyFilter)
                    }}
                    sx={{ '&:hover': { cursor: 'pointer' }, fontSize: 'small' }}
                  />
                ),
              }}
            />
          </Grid>
          <Grid item xs={2.5} md={2.5}>
            <TextField
              select
              size="medium"
              fullWidth
              value={+query?.district || 'all'}
              label={t('District')}
              color="primary"
              InputLabelProps={{ shrink: true }}
              onChange={e => {
                if (query?.district !== e.target.value) {
                  dispatch(setSearchPersonQuery({ ...query, district: +e.target.value || null }))
                  applyFilter('district', +e.target.value || null)
                }
              }}
            >
              <MenuItem value={'all'}>{t('All Districts')}</MenuItem>
              {clients.map((client, index) => (
                <MenuItem key={index} value={+client.client?.id}>
                  {client?.client?.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2.5} md={2.5}>
            <TextField
              select
              value={+query?.jobPositionId || 'All Positions'}
              color="primary"
              size="medium"
              fullWidth
              label={t('Position')}
              onChange={e => {
                if (query?.jobPositionId !== e.target.value) {
                  dispatch(setSearchPersonQuery({ ...query, jobPositionId: +e.target.value || null }))
                  applyFilter('jobPositionId', +e.target.value || null)
                }
              }}
            >
              <MenuItem value={'All Positions'}>{t('All Positions')}</MenuItem>
              {jobPositions.map((item, index) => (
                <MenuItem key={index} value={+item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2.5} md={2.5}>
            <TextField
              select
              size="medium"
              fullWidth
              value={query?.status || 'active'}
              color="primary"
              label={t('Status')}
              onChange={e => {
                if (query?.status !== e.target.value) {
                  dispatch(setSearchPersonQuery({ ...query, status: e.target.value }))
                  applyFilter('status', e.target.value)
                }
              }}
            >
              <MenuItem value={'active'}>{t('Active')}</MenuItem>
              <MenuItem value={'inactive'}>{t('Inactive')}</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  )
}
