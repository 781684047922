import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get as getStateDatas, save as saveStateDatas } from 'store/member/state-data'
import { useEffect } from 'react'
import StaffFormUsNj from './Forms/us/nj/staff-form'
import StudentFormUsNj from './Forms/us/nj/student-form'
import EmptyForm from './Forms/empty'
import { getLanguages, getStateDataType } from 'store/lookup'
import { useMember } from 'components/Providers'
import { Box, Drawer, Grid } from '@mui/material'
import PersonalInfoCard from './PersonalInfoCard'
import SPEDInfoCard from './SPEDInfoCard'
import ELLInfoCard from './ELLInfoCard'
import StaffCard from './StaffCard'

export default function StateData() {
  const { memberId, membershipType, part } = useParams()
  const navigate = useNavigate()
  const member = useMember()
  const dispatch = useDispatch()
  const languages = useSelector(state => state.lookup.languages)
  const { country, state } = useSelector(state => state.session.district?.address)
  const membershipStateDataType = useSelector(state => state.lookup.stateDataType)?.filter(
    item => item.type === membershipType,
  )

  const basePath = `/member/${member.membership.type}/${member.id}/state-data`

  const sharedStateDataType = useSelector(state => state.lookup.stateDataType)?.filter(item => item.type === 'shared')

  useEffect(() => {
    member?.stateData || dispatch(getStateDatas({ type: membershipType, memberId }))
  }, [member?.id])

  useEffect(() => {
    membershipStateDataType || dispatch(getStateDataType({ state: state.toLowerCase(), type: membershipType }))
    languages || dispatch(getLanguages())
  }, [])

  const saveForm = async data => {
    await dispatch(saveStateDatas({ type: membershipType, memberId, ...data }))
  }

  const getFormComponent = () => {
    const key = [country.toLowerCase(), state.toLowerCase(), membershipType].join('.')
    switch (key) {
      case 'us.nj.student':
        return StudentFormUsNj
      case 'us.nj.staff':
        return StaffFormUsNj
      default:
        return EmptyForm
    }
  }

  const StateDataForm = getFormComponent()

  if (!(country && state && member && membershipStateDataType && languages)) return null

  return (
    <Box sx={{ m: 2 }}>
      <Grid container spacing={2}>
        {member.membership.type === 'staff' && (
          <Grid item xs={12} md={12}>
            <StaffCard member={member} membershipStateDataType={membershipStateDataType} languages={languages} />
          </Grid>
        )}
        {member.membership.type !== 'staff' && (
          <>
            <Grid item xs={12} md={12}>
              <PersonalInfoCard
                member={member}
                membershipStateDataType={membershipStateDataType}
                languages={languages}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <ELLInfoCard
                member={member}
                membershipStateDataType={membershipStateDataType}
                sharedStateDataType={sharedStateDataType}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SPEDInfoCard member={member} />
            </Grid>
          </>
        )}
      </Grid>
      <Drawer
        PaperProps={{
          sx: {
            width: 800,
          },
        }}
        open={Boolean(part)}
        anchor="right"
        onClose={() => navigate(`${basePath}`)}
      >
        <StateDataForm
          stateDataType={membershipStateDataType}
          sharedStateDataType={sharedStateDataType}
          saveForm={saveForm}
        />
      </Drawer>
    </Box>
  )
}
