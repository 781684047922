import { useState, useEffect } from 'react'
import { Box, Tabs, Tab, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Link, Outlet, Route, Routes, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AvatarIcon from '@mui/icons-material/PersonOutlined'
import { Avatar } from '@mui/material'
import { capitalize } from 'lodash'
import Header from 'components/Header'
import Attendance from './Attendance'
import FinalGrades from './FinalGrades'
import Courses from './Courses/Courses'
import Schedules from './Schedule/Schedules'
import { useTranslation } from 'react-i18next'
import { MemberProvider } from 'components/Providers'
import 'utils/global.scss'

// const COURSE = 0
const GRADES = 0
const SCHEDULE = 1
const ATTENDANCE = 2

const Root = styled(Box)(({ theme }) => ({
  width: theme.breakpoints.values.lg,
  [theme.breakpoints.down('lg')]: {
    width: '100vw',
  },
}))

const getTabIndex = path => {
  const [tabName] = path.split('/')
  const i = ['grades', 'schedule', 'attendance'].indexOf(tabName)
  return i < 0 ? 0 : i
}

export default function Detail() {
  const theme = useSelector(state => state.session.appearance)
  const { studentId, '*': tabName } = useParams()
  const studentList = useSelector(state => state.academic.parentStudent?.list)
  const url = `/academic/parent/student/${studentId}`
  const [student, setStudent] = useState(null)
  const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
  const { t } = useTranslation()
  const member = studentList?.find(m => m.id.toString() === studentId)

  useEffect(() => {
    setTabIndex(getTabIndex(tabName))
  }, [tabName])

  useEffect(() => {
    if (parseInt(studentId)) {
      let student = studentList?.find(student => student.id === +studentId)
      if (student) setStudent(student)
    } else {
      setStudent({})
    }
  })

  return (
    <Root id="student-detail" sx={{ width: '100%' }}>
      <MemberProvider member={member}>
        <div style={{ display: 'flex' }}>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ p: 4, paddingLeft: '15px' }}
          >
            <Avatar
              sx={{ width: 80, height: 80, fontSize: 30 }}
              src={student?.memberships?.[0]?.profilePicture?.location}
            >
              <AvatarIcon />
            </Avatar>
            <Header
              sx={{ flex: 1 }}
              title={`${student?.firstName} ${student?.lastName}`}
              subtitle={capitalize(`${student?.memberships?.[0]?.schoolEnrollments[0]?.school?.name}`)}
            />
          </Stack>
        </div>

        <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            TabIndicatorProps={{ style: { background: 'primary', fontFamily: 'Roboto, sans-serif' } }}
          >
            <Tab
              sx={{ color: tabIndex === 0 && 'primary !important' }}
              className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Final Grades')}
              value={GRADES}
              component={Link}
              to={`${url}/grades`}
            />
            <Tab
              sx={{ color: tabIndex === 1 && 'primary !important' }}
              className={tabIndex === 1 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Schedule')}
              value={SCHEDULE}
              component={Link}
              to={`${url}/schedule`}
            />
            <Tab
              sx={{ color: tabIndex === 2 && 'primary !important' }}
              className={tabIndex === 2 ? `tab-item-selected` : `tab-item-${theme}`}
              label={t('Attendance')}
              value={ATTENDANCE}
              component={Link}
              to={`${url}/attendance`}
            />
            {/*<Tab label={t('Courses')} id={COURSE} component={Link} to={`${url}/course`} /> */}
          </Tabs>
        </Box>
        <Routes>
          <Route path="" element={<Outlet />}>
            <Route index element={<FinalGrades />} />
            <Route path="grades" element={<FinalGrades />} />
            <Route path="course" element={<Courses />} />
            <Route path="schedule" element={<Schedules />} />
            <Route path="attendance" element={<Attendance />} />
          </Route>
        </Routes>
      </MemberProvider>
    </Root>
  )
}
