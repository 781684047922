import {
  Button,
  Stack,
  CardContent,
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Divider,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { DatePicker } from '@mui/x-date-pickers'
import { save } from 'store/provider/academic-year'
import { useDispatch } from 'react-redux'
import { BasePathProvider, useBasePath } from 'components/Providers'
import Header from 'components/Header'
import FormSkeleton from './Skeleton'
import { useTranslation } from 'react-i18next'
import { orange } from '@mui/material/colors'
import { DeleteIcon } from 'components/Icons'
import { SaveAltIcon } from 'components/Icons'
import { useState, useEffect } from 'react'
import { get } from 'store/provider/academic-year'
import { useSelector } from 'react-redux'
import Confirm from 'components/Dialog'
import { destroy } from 'store/provider/academic-year'
import { omit } from 'lodash'

export default function AcademicYearEditForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const [confirm, setConfirm] = useState(false)
  const { academicYearId } = useParams()

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: academicYearId }))
    navigate(basePath)
  }

  const academicYear = useSelector(state => state.provider.academicYear.rows)?.find(x => x.id == academicYearId)

  const [isFullYear, setIsFullYear] = useState(academicYear?.isFullYear)

  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    isFullYear: yup.boolean(),
    startDate: yup.date().required('Required'),
    endDate: yup
      .date()
      .required('Required')
      .test('valid-dates', 'End date should be later than start date', function (value) {
        return this.parent.startDate < value
      }),
  })

  const { control, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: academicYear?.name || '',
      startDate: academicYear?.startDate ? dayjs(academicYear?.startDate) : null,
      endDate: academicYear?.endDate ? dayjs(academicYear?.endDate) : null,
    },
  })

  useEffect(() => {
    if (+academicYearId) {
      academicYearId && dispatch(get({ id: academicYearId }))
    }
    reset({
      name: academicYear?.name || '',
      startDate: academicYear?.startDate ? dayjs(academicYear?.startDate) : null,
      endDate: academicYear?.endDate ? dayjs(academicYear?.endDate) : null,
    })
  }, [dispatch, academicYearId])

  const onSubmit = async (data, event) => {
    event.stopPropagation()
    if (data.startDate && dayjs(data.startDate).isValid()) {
      data.startDate = dayjs(data.startDate).format('YYYY-MM-DD')
    }
    if (data.endDate && dayjs(data.endDate).isValid()) {
      data.endDate = dayjs(data.endDate).format('YYYY-MM-DD')
    }
    const payload = {
      id: academicYearId,
      startDate: data.startDate,
      endDate: data.endDate,
      name: data.name,
      isFullYear,
    }
    const res = await dispatch(save(academicYearId === 'new' ? omit(payload, 'id') : payload))
    if (res.payload) {
      navigate(basePath)
    }
  }
  if (academicYearId !== 'new' && !academicYear) {
    return <FormSkeleton />
  }
  const onError = errors => console.error(errors)
  return (
    <BasePathProvider value={`${basePath}/${academicYearId}`}>
      <Box>
        <Header title={academicYear ? t('Edit Academic Year') : t('Add Academic Year')} close small /> <Divider />
        <CardContent component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ minHeight: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={12}>
              <Typography variant="body2" color="textSecondary">
                Academic Year Type
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} style={{ marginTop: '-10px' }}>
              <Box display="flex" justifyContent="left">
                <Controller
                  control={control}
                  name="isFullYear"
                  render={({ field }) => (
                    <RadioGroup
                      row
                      sx={{ ml: 2 }}
                      value={field.value}
                      onChange={e => field.onChange(e.target.value === 'schoolYear')}
                    >
                      <FormControlLabel
                        value="schoolYear"
                        control={
                          <Radio
                            sx={{
                              color: 'primary.main',
                              '&.Mui-checked': {
                                color: orange[600],
                              },
                            }}
                            onClick={() => setIsFullYear(true)}
                            checked={isFullYear || isFullYear === undefined || isFullYear === null}
                          />
                        }
                        label={t('School Year')}
                      />
                      <FormControlLabel
                        value="summerSchool"
                        control={
                          <Radio
                            sx={{
                              color: orange[800],
                              '&.Mui-checked': {
                                color: orange[600],
                              },
                            }}
                            onClick={() => setIsFullYear(false)}
                            checked={isFullYear === false}
                          />
                        }
                        label={t('Summer School')}
                      />
                    </RadioGroup>
                  )}
                />
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <DatePicker
                      fullWidth
                      label={t('Start Date')}
                      value={dayjs(field.value)}
                      onChange={field.onChange}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!fieldState.error,
                          helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                        },
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <DatePicker
                      fullWidth
                      sx={{ width: '100%' }}
                      label={t('End Date')}
                      value={dayjs(field.value)}
                      onChange={field.onChange}
                      slotProps={{
                        textField: {
                          size: 'small',
                          error: !!fieldState.error,
                          helperText: fieldState.error ? (field.value ? 'Invalid date' : 'Required') : '',
                        },
                      }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      size="small"
                      required
                      fullWidth
                      label={t('Name')}
                      value={field.value}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>
          <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ my: 3 }}>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              size="small"
              disabled={!academicYear}
              onClick={() => setConfirm(true)}
              color="error"
            >
              <DeleteIcon />
              {t('Delete')}
            </Button>
            <Button type="submit" variant="contained" size="small" disabled={!formState.isDirty} color="primary">
              <SaveAltIcon />
              {t('Save')}
            </Button>
          </Stack>
        </CardContent>
      </Box>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </BasePathProvider>
  )
}
