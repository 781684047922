import { Box, Drawer } from '@mui/material'
import { BasePathProvider } from 'components/Providers'
import { useNavigate, useParams } from 'react-router-dom'
import IncidentListIndex from './List'
import DisciplineAddIncidentFormIndex from './Form'


const baseUrl = '/discipline/incidents'

export default function IncidentsPage() {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <Box>
      <BasePathProvider value={`${baseUrl}`}>
        <IncidentListIndex />
        <Drawer
          PaperProps={{
            sx: {
              width: 800,
            },
          }}
          open={Boolean(id)}
          anchor="right"
          onClose={() => navigate(baseUrl)}
        >
          <DisciplineAddIncidentFormIndex /> 
        </Drawer>
      </BasePathProvider>
    </Box>
  )
}
