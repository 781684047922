import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { AddPersonButton } from 'components/Buttons'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FaSave } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCourseLevel, getGradeLevelAndSections } from 'store/lookup'
import { search } from 'store/settings/course'

const credits = [0, 0.25, 0.5, 1.0, 1.25, 2.0, 2.5, 3.0, 3.5, 3.75, 4.0, 5.0, 6.0, 7.0, 10.0, 12.5, 15.0]

export default function SchedulingCourseSessionsFormSessionDetails({
  control,
  getValues,
  setValue,
  onSubmitSessionDetails,
}) {
  const { courseSessionId } = useParams()

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const theme = useSelector(state => state.session.appearance)
  const { query, list: courseList } = useSelector(state => state.settings.course)
  const { list: courseSessions } = useSelector(state => state.settings.courseSession)
  const courseLevel = useSelector(state => state.lookup.courseLevel)
  const gradeLevelSectionFromStore = useSelector(state => state.lookup.gradeLevelAndSections)
  const academicYearMarkingPeriods = useSelector(state => state.session?.academicYear?.markingPeriods)

  const [gradeLevelSections, setGradeLevelSections] = useState()

  useEffect(() => {
    courseList || dispatch(search({ ...query, offset: 0 }))
    courseLevel || dispatch(getCourseLevel())
    gradeLevelSectionFromStore || dispatch(getGradeLevelAndSections())
    if (+courseSessionId) {
      const courseGradeLevelId = courseSessions.find(item => item.id === +courseSessionId)?.course?.gradeLevelId
      setGradeLevelSections(gradeLevelSectionFromStore?.filter(item => item.id === courseGradeLevelId))
    } else {
      setGradeLevelSections(gradeLevelSectionFromStore)
    }
  }, [])

  const filterGradeLevelSections = courseId => {
    const courseGradeLevelId = courseList.find(item => item.id === courseId)?.gradeLevel?.id
    if (courseGradeLevelId) {
      setGradeLevelSections(gradeLevelSectionFromStore?.filter(item => item.id === courseGradeLevelId))
    } else {
      setGradeLevelSections(gradeLevelSectionFromStore)
    }
  }

  if (!(courseList && courseLevel)) return null

  // const gradeLevelSections = gradeLevelSectionFromStore.filter(item => item.id === )

  return (
    <Box sx={{ pt: 3, pl: 1 }}>
      <Typography variant="h6">{t('Session Details')}</Typography>
      <Grid container sx={{ pt: 1 }} spacing={2}>
        <Grid item xs={12} md={12}>
          {courseSessionId !== 'new' && +courseSessionId && (
            <TextField
              select
              fullWidth
              size="small"
              label={t('Course Name')}
              value={courseSessions.find(cs => cs.id === +courseSessionId)?.course?.id}
              disabled={true}
            >
              <MenuItem value={courseSessions.find(cs => cs.id === +courseSessionId)?.course?.id}>
                {courseSessions.find(cs => cs.id === +courseSessionId)?.course?.name}
              </MenuItem>
            </TextField>
          )}
          {courseSessionId === 'new' && (
            <Controller
              name="courseSession.course.id"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label={t('Course Name')}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    {...field}
                    disabled={courseSessionId !== 'new' && true}
                  >
                    {courseList.map((item, index) => (
                      <MenuItem key={index} value={item.id} onClick={() => filterGradeLevelSections(item.id)}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.name"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t('Session Name')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormControl sx={{ m: 0 }}>
            <Typography
              sx={{
                fontSize: '12px',
                color: theme === 'dark' ? 'rgba(255, 255, 255, 0.60)' : 'rgba(0, 0, 0, 0.60)',
              }}
            >
              {t('Marking Periods')}
            </Typography>

            <FormGroup row>
              {academicYearMarkingPeriods.map((item, index) => (
                <Controller
                  key={index}
                  name={`courseSession.markingPeriods.${index}.id`}
                  control={control}
                  render={() => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={e => {
                              if (!e.target.checked) {
                                setValue(`courseSession.markingPeriods.${index}.id`, null, { shouldDirty: true })
                              } else {
                                setValue(`courseSession.markingPeriods.${index}.id`, item?.id, { shouldDirty: true })
                              }
                            }}
                            checked={+getValues(`courseSession.markingPeriods.${index}.id`) === +item?.id}
                          />
                        }
                        label={item?.name}
                        color="primary"
                      />
                    )
                  }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.credit"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t('Credit')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  {credits.map((credit, index) => (
                    <MenuItem key={index} value={credit}>
                      {credit}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.courseLevelId"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t('Course Level')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  {courseLevel.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.suffix} - {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.gradeSection"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t('Grade Section')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  {gradeLevelSections?.map(item =>
                    item?.section?.map(sec => (
                      <MenuItem key={sec} value={`${item.id} ${sec}`}>
                        {item.name} {sec}
                      </MenuItem>
                    )),
                  )}
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="classroom"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  select
                  fullWidth
                  size="small"
                  label={t('Classroom')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                >
                  <MenuItem value={'test'}>Test</MenuItem>
                </TextField>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Controller
            name="courseSession.quota"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <TextField
                  fullWidth
                  size="small"
                  label={t('Max Seats')}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  {...field}
                />
              )
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Stack flexDirection={'row'} justifyContent={'flex-end'} alignItems={'flex-end'}>
            <AddPersonButton
              onClick={onSubmitSessionDetails}
              size="small"
              variant="contained"
              color="primary"
              title={t('Save')}
              startIcon={<FaSave />}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
