import client from '../../utils/api'
import { createSlice } from '@reduxjs/toolkit'
import { createApiAction } from '../../utils/redux-tool'

const name = 'disciplineIncident'
const initialState = {
  list: null,
  incidentItems: null
}

export const search = createApiAction('disciplineIncident/search', client.search('/api/discipline/incident'))
export const getIncidentItems = createApiAction('disciplineIncident/incidentItem', client.search('/api/discipline/incident-item'))

export const destroy = createApiAction(
  'disciplineIncident/delete',
  client.destroy('/api/discipline/incident/:id'),
)

export const get = createApiAction(
  'disciplineIncident/get',
  client.retrieve(`/api/discipline/incident/:id`),
)

export const save = createApiAction(
  'disciplineIncident/save',
  client.save('/api/discipline/incident'),
)

const update = (state, action) => {  
  if (Array.isArray(action.payload)) {
      action.payload.forEach(item => {
        const found = state.list?.find(rec => rec.id === item.id)
        state.list = found
          ? state.list.map(rec => (found.id === rec.id ? item : rec))
          : [...state.list, item]
      })
  } else {
    const found = state.list?.find(rec => rec.id === action.payload.id)
    state.list = found
      ? state.list.map(item => (found.id === item.id ? action.payload : item))
      : [...state.list, action.payload]
  }
}

const sessionSlice = createSlice({
  name,
  initialState,
  reducers: {
    clearIncidents: state => {
      state.list = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(search.fulfilled, (state, action) => {
        state.list = action.payload.rows
      })
      .addCase(getIncidentItems.fulfilled, (state, action) => {
        state.incidentItems = action.payload
      })
      .addCase(get.fulfilled, update)
      .addCase(save.fulfilled, update)
      .addCase(destroy.fulfilled, (state, action) => {
        state.list = state.list.filter(item => item.id !== action.payload.id)
      })
  },
})

export const { clearIncidents } = sessionSlice.actions
export default sessionSlice.reducer
