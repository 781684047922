import { Box, Tabs, Tab } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'


const INCIDENTS = 0
const CLASSPOINTS = 1
const REFERRALS = 2

const getTabIndex = path => {
    const [tabName] = path.split('/')
    const i = ['incidents', 'class-points', 'referrals'].indexOf(tabName)
    return i < 0 ? 0 : i
}

export default function DisciplineTabs() {
    const basePath = `/discipline`
    const theme = useSelector(state => state.session.appearance)
    const { t } = useTranslation()
    const { '*': tabName } = useParams()
    const [tabIndex, setTabIndex] = useState(getTabIndex(tabName))
    useEffect(() => {
      setTabIndex(getTabIndex(tabName))
    }, [tabName])

    return (
        <Box>
            <Box>
                <Tabs
                    value={tabIndex}
                    TabIndicatorProps={{ style: { background: 'primary.main' } }}
                >
                    <Tab 
                      sx={{color: tabIndex === 0 && 'primary.main'}}
                      className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
                      label={t('Incidents')}
                      value={INCIDENTS}
                      component={Link}
                      to={`${basePath}/incidents`}
                    />
                    <Tab 
                      sx={{color: tabIndex === 0 && 'primary.main'}}
                      className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
                      label={t('Class Points')}
                      value={CLASSPOINTS}
                      component={Link}
                      to={`${basePath}/class-points`}
                    />
                    <Tab 
                      sx={{color: tabIndex === 0 && 'primary.main'}}
                      className={tabIndex === 0 ? `tab-item-selected` : `tab-item-${theme}`}
                      label={t('Referrals')}
                      value={REFERRALS}
                      component={Link}
                      to={`${basePath}/referrals`}
                    />
                </Tabs>
            </Box>
        </Box>
    )
}
