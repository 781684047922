import { Avatar, Grid, Paper, Stack, Typography } from '@mui/material'
import { stringToColor } from 'components/Avatar'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { impersonate } from 'store/session'
import { FaUniversity } from 'react-icons/fa'
import { search } from 'store/provider/school'

export default function SchoolsPage() {
  const navigate = useNavigate()
  const { district } = useSelector(state => state.session)
  const { list: clientList } = useSelector(state => state.provider.school)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(search())
  }, [dispatch, district?.id])

  const switchToCampus = async (districtId, campusId) => {
    try {
      await dispatch(impersonate({ districtId, campusId }))
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  const sortLevels = items => {
    const letters = []
    const numbers = []
    items?.forEach(item => {
      if (isNaN(item)) {
        letters.push(item)
      } else {
        numbers.push(item)
      }
    })
    letters.sort()
    numbers.sort((a, b) => a - b)
    return letters.concat(numbers)
  }

  const getGradeLevelRange = items => {
    if (!items || items.length === 0) {
      return null
    } else if (items && items.length === 1) {
      return items[1]
    } else if (items && items.length > 1) {
      const sortedItems = sortLevels(items)
      return `${sortedItems[0]}-${sortedItems[items.length - 1]}`
    }
  }

  const sortByGradeLevels = items => {
    if (!items || items.length === 0) return null
    let temp = []
    items?.forEach(item => {
      temp.push({ ...item, gradeLevelIds: sortLevels(item.gradeLevelIds) })
    })
    temp.sort((a, b) => {
      const gradeA = a.gradeLevelIds[0]
      const gradeB = b.gradeLevelIds[0]

      if (!gradeA || !gradeB) {
        return 0
      }

      if (isNaN(gradeA) && isNaN(gradeB)) {
        return gradeB.localeCompare(gradeA)
      } else if (isNaN(gradeA)) {
        return -1
      } else if (isNaN(gradeB)) {
        return 1
      } else {
        return parseInt(gradeA) - parseInt(gradeB)
      }
    })
    return temp
  }

  if (!clientList) return null

  let clients = []

  clientList.forEach(item => {
    item.client.schools.forEach(school => {
      let tempCampuses = []
      school?.campuses.forEach(campus => {
        if (
          campus.academicYears[0] &&
          campus.academicYears[0]?.campusTargetedEnrollments &&
          campus.academicYears[0]?.campusTargetedEnrollments[0] &&
          campus.academicYears[0]?.campusTargetedEnrollments[0]?.targetedEnrollments.length > 0
        ) {
          const gradeLevels = sortLevels(
            campus.academicYears[0]?.campusTargetedEnrollments[0]?.targetedEnrollments.map(t => t.gradeLevelId),
          )
          tempCampuses.push({
            id: campus.id,
            name: campus.name,
            gradeLevelIds: gradeLevels,
            districtId: item.client.id,
          })
        } else {
          tempCampuses.push({ id: campus.id, name: campus.name, gradeLevelIds: null, districtId: item.client.id })
        }
      })
      clients.push({ id: school.id, name: school.name, campuses: tempCampuses, providerId: item.id })
    })
  })

  return (
      <Grid container  spacing={1} sx={{ pt: '10px',}}>
        {clients.map((item, index) => (
          <Grid key={index} item xs={12} md={6} sm={12} xl={4} sx={{ pt: '0px',}}>
            <Paper
              key={index}
              sx={{
                px: 2,
                py: 1,
                minHeight: '230px',
                background: item?.name && 'primary.contrast',
                boxShadow: !item?.name && 'none',
                borderRadius: '5px',
                borderTop: item?.name && `5px solid ${stringToColor(item?.name)}`,
              }}
            >
              <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} sx={{ px: 1}}>
                {item?.file?.location ? (
                  <Avatar sx={{ bgcolor: 'transparent' }} src={item?.file?.location} />
                ) : (
                  <FaUniversity style={{ width: '20px', height: '20px' }} />
                )}
                <Typography variant="subtitle1" sx={{ textAlign: 'center !important',  }}>
                  {clientList.find(c => c?.id === item?.providerId)?.client?.name}
                </Typography>
              </Stack>

              <Grid container sx={{ mt: 1 }}>
                {item?.campuses &&
                  sortByGradeLevels(item?.campuses)?.map((campus, ind) => (
                    <>
                      <Paper elevation={0} 
                      sx={{width: '100%', 
                           display: 'flex', 
                           px: 1,
                           justifyContent: 'space-between', 
                           cursor: 'pointer',
                           '&:hover': {
                             bgcolor: 'rgba(255, 146, 21, 0.04)',
                           },
                          }}                           
                        onClick={() => {
                              switchToCampus(campus?.districtId, campus.id)
                            }}
                      >
                        <Typography
                          key={ind}
                          sx={{ my: 1, }}
                        >
                          {campus.name}
                        </Typography>
                        <Typography sx={{ my: 1 }}>{getGradeLevelRange(campus.gradeLevelIds)}</Typography>

                      </Paper>
                    </>
                  ))}
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
  )
}
