import { useDispatch } from 'react-redux'
import DocumentListIndex from './List'
import { BasePathProvider, MemberProvider, useMember } from 'components/Providers'
import * as action from 'store/member/document'
import { useEffect, useState } from 'react'
import { Drawer } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
/* import DocumentListSkeleton from './List/ListSkeleton'
 */import Header from 'components/Header'
import DocumentFormIndex from './Form'
import { isInteger } from 'lodash'

export default function DocumentsPage() {
  const dispatch = useDispatch()
  const member = useMember()
  const { membershipType, memberId, documentId } = useParams()
  const [document, setDocument] = useState(null)

  const navigate = useNavigate()

  const basePath = `/member/${member?.membership.type}/${member?.id}/documents`

  useEffect(() => {
    member && !member.documents && dispatch(action.search({ type: member.membership?.type, memberId: member.id }))
  }, [dispatch, member])
 
  useEffect(() => {
    if (isInteger(+documentId)) {
      dispatch(action.retrieve({ id: documentId, type: membershipType, memberId })).then(res => {
        setDocument(res.payload)
      })
    } else {
      setDocument({})
    }
  }, [dispatch, documentId])

  return (
    <BasePathProvider value={`${basePath}`}>
      <MemberProvider member={member}>
        <Header small title={'Documents'} add />
        <DocumentListIndex documents={member?.documents} />
        <Drawer
          PaperProps={{
            sx: {
              width: 600,
            },
          }}
          open={Boolean(documentId)}
          anchor="right"
          onClose={() => navigate(`${basePath}`)}
        >
          <DocumentFormIndex document={document} />
        </Drawer>
      </MemberProvider>
    </BasePathProvider>
  )
}
