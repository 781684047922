import { Autocomplete, TextField } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllStudents } from "store/lookup";

export default function StudentSearchBar({
  onSelect = () => {},
  label = 'Select Student',
  size = 'small',
  value = null,
  disabled = false
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { academicYear } = useSelector(state => state.session);
  const allStudents = useSelector(state => state.lookup.allStudents.rows);

  useEffect(() => {
    allStudents || dispatch(getAllStudents({ limit: 1000 }))
  }, [academicYear, Boolean(allStudents)])

  const selectStudent = (event, value) => {
    onSelect(value);
  };

  return (
    <Autocomplete
      disablePortal
      fullWidth
      size={size}
      options={allStudents}
      value={value}
      onChange={selectStudent}
      getOptionLabel={option => `${option?.person?.lastName}, ${option?.person?.firstName}`}
      renderInput={params => (
        <>
          <TextField
            {...params}
            fullWidth
            label={t(label)}
            disabled={disabled}
          />
        </>
      )}
      disabled={disabled}
    />
  );
}
