import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Autocomplete, Box, Grid, MenuItem, Stack, TextField, Toolbar } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { getCountries, getLanguages } from 'store/lookup'
import { useEffect, useLayoutEffect } from 'react'
import { SubmitButton } from 'components/Buttons'
import _ from 'lodash'
import { BasePathProvider, useBasePath, useMember } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Header'

const initialData = {
  jobCode: '',
  yearsInNj: '',
  yearsInLea: '',
  ageGroupTaught: '',
  credentialType: '',
  evaluationOfStaff: '',
  countyCodeAssigned: '',
  jobCodeSubcategory: '',
  schoolCodeAssigned: '',
  districtCodeAssigned: '',
  numberOfClassesTaught: '',
  yearsOfPriorExperience: '',
  languageSpokenByTeacher: '',
  titleIProgramStaffCategory: '',
  lepInstructorCredentialType: '',
  highestLevelOfEducationCompleted: '',
  seProgramContractedServicesCategory: '',
}

export default function StaffForm({ saveForm, stateDataType }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const languages = useSelector(state => state.lookup.languages)
  const counties = useSelector(state => state.lookup.countyList)
  const member = useMember()
  const basePath = useBasePath()
  const { t } = useTranslation()

  useLayoutEffect(() => {
    dispatch(getCountries({ country: 'us', state: 'nj' }))
    languages || dispatch(getLanguages())
  }, [member?.id])

  const schema = yup.object().shape({
    evaluationOfStaff: yup.string().nullable(),
  })

  const { control, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    let data = {}
    _.defaultsDeep(
      data,
      member?.stateData || {
        jobCode: null,
        yearsInNj: null,
        yearsInLea: null,
        ageGroupTaught: null,
        credentialType: null,
        evaluationOfStaff: null,
        countyCodeAssigned: null,
        jobCodeSubcategory: null,
        schoolCodeAssigned: null,
        districtCodeAssigned: null,
        numberOfClassesTaught: null,
        yearsOfPriorExperience: null,
        languageSpokenByTeacher: null,
        titleIProgramStaffCategory: null,
        lepInstructorCredentialType: null,
        highestLevelOfEducationCompleted: null,
        seProgramContractedServicesCategory: null,
      },
      initialData,
    )
    reset(data)
  }, [member?.stateData?.fetchedAt])

  const onSubmit = async formData => {
    await saveForm(formData)
    navigate(`${basePath}/state-data`)
  }

  const onError = errors => console.error(errors)

  if (!languages || !counties || !stateDataType) return null

  const ageGroupTaughtOption = stateDataType.filter(item => item.field === 'ageGroupTaught')
  const credentialTypeOption = stateDataType.filter(item => item.field === 'credentialType')
  const districtCodeAssignedOption = stateDataType.filter(item => item.field === 'districtCodeAssigned')
  const evaluationOfStaffOption = stateDataType.filter(item => item.field === 'evaluationOfStaff')
  const highestLevelOfEducationCompletedOption = stateDataType.filter(
    item => item.field === 'highestLevelOfEducationComp',
  )
  const jobCodeOption = stateDataType.filter(item => item.field === 'jobCode')
  const jobCodeSubcategoryOption = stateDataType.filter(item => item.field === 'jobCodeSubcategory')
  const lepInstructorCredentialTypeOption = stateDataType.filter(item => item.field === 'lepInstructorCredentialType')
  const numberOfClassesTaughtOption = stateDataType.filter(item => item.field === 'numberOfClassesTaught')
  const schoolCodeAssignedOption = stateDataType.filter(item => item.field === 'schoolCodeAssigned')
  const seProgramContractedServicesCategoryOption = stateDataType.filter(
    item => item.field === 'seProgramContServicesCat',
  )
  const titleIProgramStaffCategoryOption = stateDataType.filter(item => item.field === 'titleIProgramStaffCategory')

  return (
    <BasePathProvider value={`${basePath}/state-data/`}>
      <Box component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ mb: 4 }}>
        <Toolbar />
        <Header title={t("Staff Information")} small close />
        <Grid container spacing={2} sx={{ px: 2 }}>
          <Grid item sm={12} xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="evaluationOfStaff"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Evaluation of Staff')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        size="small"
                        fullWidth
                        value={value || ''}
                        {...field}
                      >
                        {evaluationOfStaffOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="titleIProgramStaffCategory"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Title I Program Staff Category')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {titleIProgramStaffCategoryOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="seProgramContractedServicesCategory"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('SE Program Contracted Services Category')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {seProgramContractedServicesCategoryOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="yearsOfPriorExperience"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        label={t('Years of Prior Experience')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="yearsInLea"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        label={t('Years in LEA')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="districtCodeAssigned"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        disablePortal
                        {...field}
                        size="small"
                        fullWidth
                        options={districtCodeAssignedOption?.map(c => c.code) || []}
                        onChange={(event, value) => {
                          setValue('districtCodeAssigned', value || '')
                        }}
                        value={member?.stateData?.districtCodeAssigned || ''}
                        getOptionLabel={option => {
                          return districtCodeAssignedOption.find(c => c.code === option)?.description || ''
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('District Code Assigned')}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="jobCode"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        disablePortal
                        size="small"
                        fullWidth
                        options={jobCodeOption?.map(c => c.code) || []}
                        onChange={(event, value) => {
                          setValue('jobCode', value || '')
                        }}
                        value={member?.stateData?.jobCode || ''}
                        getOptionLabel={option => {
                          return jobCodeOption.find(c => c.code === option)?.description || ''
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('Job Code')}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="ageGroupTaught"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Age Group Taught')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {ageGroupTaughtOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="credentialType"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Credential Type')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {credentialTypeOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="languageSpokenByTeacher"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        size="small"
                        fullWidth
                        options={languages?.map(c => c.id) || []}
                        onChange={(event, value) => {
                          setValue('languageSpokenByTeacher', value || '')
                        }}
                        value={member?.stateData?.languageSpokenByTeacher || ''}
                        getOptionLabel={option => {
                          if (languages?.find(c => c.id === option)) {
                            return `${languages?.find(c => c.id === option)?.name} - ${
                              languages.find(c => c.id === option)?.originName
                            }`
                          } else {
                            return ''
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('Language Spoken by Teacher')}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="highestLevelOfEducationCompleted"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Highest Level of Education Completed')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {highestLevelOfEducationCompletedOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="lepInstructorCredentialType"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('LEP Instructor Credential Type')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {lepInstructorCredentialTypeOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="yearsInNj"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        label={t('Years in NJ')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="countyCodeAssigned"
                  control={control}
                  render={({ field: { ...field }, fieldState }) => {
                    return (
                      <Autocomplete
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...field}
                        size="small"
                        fullWidth
                        options={counties?.map(c => c.county_name) || []}
                        getOptionLabel={option => {
                          return counties.find(c => c.county_name === option)?.county_name || ''
                        }}
                        onChange={(event, value) => {
                          setValue('countyCodeAssigned', value || '')
                        }}
                        value={member?.stateData?.countyCodeAssigned || ''}
                        renderInput={params => (
                          <TextField
                            {...params}
                            {...field}
                            fullWidth
                            label={t('County Code Assigned')}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                          />
                        )}
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="schoolCodeAssigned"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('School Code Assigned')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {schoolCodeAssignedOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="jobCodeSubcategory"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Job Code Subcategory')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {jobCodeSubcategoryOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
              <Grid item sm={12} xs={12} md={12}>
                <Controller
                  name="numberOfClassesTaught"
                  control={control}
                  render={({ field: { value, ...field }, fieldState }) => {
                    return (
                      <TextField
                        select
                        label={t('Number of Classes Taught')}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        value={value || ''}
                        {...field}
                        size="small"
                        fullWidth
                      >
                        {numberOfClassesTaughtOption.map(item => (
                          <MenuItem key={item.code} value={item.code}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </TextField>
                    )
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={12}>
            <Stack alignItems={'flex-end'} justifyContent={'flex-end'} sx={{ mt: 3 }}>
              <SubmitButton onClick={handleSubmit(onSubmit)} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </BasePathProvider>
  )
}
