import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import TableHeadCell from 'components/TableHeadCell'
import SettingsSchoolManagementListItem from './ListItem'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { DeleteIcon, EditIcon } from 'components/Icons'
import Confirm from 'components/Dialog'
import { destroy } from 'store/settings/campus'
import { useDispatch } from 'react-redux'

const basePath = '/setting/school-management'

export default function SettingsSchoolManagementList({ districts, campuses, schools }) {
  const { t } = useTranslation()

  const [campusList, setCampusList] = useState()
  const [selected, setSelected] = useState()

  useEffect(() => {
    if (selected) {
      setCampusList(campuses.filter(campus => campus.district.id === selected))
    }
  }, [selected, campuses])

  return (
    <>
      <Box sx={{ px: 2, pb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '15%' }}>{t('Code')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '50%' }}>{t('Name')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '25%' }}>{t('Abbreviation')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '10%' }}>{t('Actions')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {districts.map((item, index) => (
              <SettingsSchoolManagementListItem
                key={index}
                item={item}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Divider />
      <Campuses list={campusList} selected={selected} schools={schools} />
    </>
  )
}

function Campuses({ list, selected, schools }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [confirm, setConfirm] = useState({ status: false, id: null })

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: confirm?.id }))
  }

  if (!list) return null

  return (
    <Box>
      <Divider sx={{ borderWidth: 2}} />
      <Box sx={{ px: 2, pb: 2 }}>
        <Stack sx={{ mt: 2 }} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Box sx={{ px: 1.5 }}>
            <Typography variant="subtitle1">{t('Campuses')}</Typography>
          </Box>
          <Box>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => navigate(`${basePath}/district/${selected}/campus/new`)}
            >
              {t('Add')}
            </Button>
          </Box>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '15%' }}>{t('Building Code')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '20%' }}>{t('School')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '20%' }}>{t('Campus Name')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '35%' }}>{t('Address')}</TableHeadCell>
              <TableHeadCell sx={{ fontWeight: '500 !important', width: '10%' }}>{t('Action')}</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item, index) => (
              <TableRow hover key={index}>
                <TableCell>{item?.buildingCode}</TableCell>
                <TableCell>{schools.find(school => school.id === item?.schoolId)?.name}</TableCell>
                <TableCell>{item?.name}</TableCell>
                <TableCell>{`${item?.address?.street}, ${item?.address?.city}, ${item?.address?.state}`}</TableCell>
                <TableCell>
                  <Stack flexDirection={'row'}>
                    <IconButton component={NavLink} to={`${basePath}/district/${selected}/campus/${item.id}`}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setConfirm({ status: true, id: item.id })
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Confirm open={confirm?.status} onClose={() => setConfirm({ status: false, id: null })} onConfirm={onConfirm} />
    </Box>
  )
}
