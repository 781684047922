import {
  Button,
  Stack,
  CardContent,
  Grid,
  TextField,
  Box,
  Typography,
  Divider,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { save } from 'store/provider/marking-period'
import { useDispatch, useSelector } from 'react-redux'
import { BasePathProvider, useBasePath } from 'components/Providers'
import Header from 'components/Header'
import Skeleton from './Skeleton'
import MarkingPeriodList from '../MarkingPeriods/MarkingPeriodList'
import { useTranslation } from 'react-i18next'
import { DeleteIcon } from 'components/Icons'
import { SaveAltIcon } from 'components/Icons'
import { useState, useEffect } from 'react'
import { get } from 'store/provider/marking-period'
import Confirm from 'components/Dialog'
import { destroy } from 'store/provider/marking-period'
import { omit } from 'lodash'
import * as snackbar from 'utils/snackbar'
import { orange } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function MarkingPeriodProviderEditForm({ academicYear, districts, campuses, schools }) {
  const { academicYearId, markingPeriodId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const basePath = useBasePath()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [errors, setErrors] = useState(null)
  const [destination, setDestination] = useState('district')

  const markingPeriodProvider = useSelector(state => state.provider.academicYear.rows)
    ?.find(x => x.id == academicYearId)
    .markingPeriodProvider?.find(x => x.id == markingPeriodId)

  useEffect(() => {
    if (markingPeriodProvider?.destinationIds?.length) {
      markingPeriodProvider?.destinationIds.map(id => {
        const district = districts?.find(district => district.id === id)
        const campus = campuses?.find(campus => campus.id === id)
        if (campus) {
          setDestination('campus')
        }
        if (district) {
          setDestination('district')
        }
      })
    }
  }, [markingPeriodProvider?.destinationIds, campuses?.length, districts?.length])

  const onConfirm = () => {
    setConfirm(false)
    dispatch(destroy({ id: markingPeriodId, academicYearId }))
    navigate(basePath)
  }
  const handleClick = () => {
    setOpen(true)
  }

  const handleDelete = e => {
    const list = getValues()?.destinationIds?.filter(item => item !== e)
    setValue('destinationIds', [...list])
    setValue('', '', { shouldDirty: true })
  }
  const newMarkingPeriodProvider = academicYear.isFullYear
    ? {
        academicYearId,
        destinationIds: [],
        markingPeriods: [
          {
            name: 'MP 1',
            endDate: null,
            startDate: null,
          },
          {
            name: 'MP 2',
            endDate: null,
            startDate: null,
          },
          {
            name: 'MP 3',
            endDate: null,
            startDate: null,
          },
          {
            name: 'MP 4',
            endDate: null,
            startDate: null,
          },
        ],
      }
    : {
        academicYearId,
        destinationIds: [],
        markingPeriods: [
          {
            name: 'Summer',
            endDate: null,
            startDate: null,
          },
        ],
      }

  const schema = yup.object().shape({
    name: yup.string().required('Required'),
    destinationIds: yup.array().of(yup.number().integer()),
    markingPeriods: yup
      .array()
      .of(
        yup.object().shape({
          name: yup.string(),
          startDate: yup.date().required('Required'),
          endDate: yup
            .date()
            .required('Required')
            .test('valid-dates', 'End date should be later than start date', function (value) {
              return this.parent.startDate < value
            }),
        }),
      )
      .test('valid-periods', 'Each period should start after the previous one ends', function (value) {
        for (let i = 1; i < value.length; i++) {
          if (value[i].startDate <= value[i - 1].endDate) {
            setErrors(true)
            return false
          }
        }
        return true
      }),
  })

  const { control, handleSubmit, reset, getValues, setValue, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: markingPeriodProvider?.name,
      destinationIds: markingPeriodProvider?.destinationIds,
      markingPeriods: markingPeriodProvider?.markingPeriods,
    },
  })

  useEffect(() => {
    if (markingPeriodId && markingPeriodId !== 'new') {
      dispatch(get({ id: markingPeriodId, academicYearId }))
    }
    reset({
      name: markingPeriodProvider?.name,
      destinationIds: markingPeriodProvider?.destinationIds,
      markingPeriods: markingPeriodProvider?.markingPeriods,
    })
  }, [reset, academicYearId, markingPeriodId])

  if (errors) {
    snackbar.error('Each period should start after the previous one ends')
  }

  const markingPeriodsControl = useFieldArray({ control, name: 'markingPeriods', keyName: 'uuid' })

  const onSubmit = async (data, event) => {
    event.stopPropagation()
    const markingPeriods = data.markingPeriods.map(period => ({
      ...period,
      startDate: dayjs(period.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(period.endDate).format('YYYY-MM-DD'),
    }))

    let destinationIds = null
    if (destination === 'district') {
      destinationIds = data?.destinationIds?.filter(id => districts.some(obj => obj.id === id)) || []
    } else if (destination === 'campus') {
      destinationIds = data?.destinationIds?.filter(id => campuses.some(obj => obj.id === id)) || []
    }

    const payload = {
      id: markingPeriodId,
      name: data.name,
      academicYearId,
      destinationIds,
      destination,
      markingPeriods,
    }

    const res = await dispatch(save(markingPeriodId === 'new' ? omit(payload, 'id') : payload))
    if (res.payload) {
      navigate(basePath)
    }
  }
  const onError = errors => console.error(errors)
  if (markingPeriodId !== 'new' && !markingPeriodProvider) {
    return <Skeleton />
  }

  return (
    <BasePathProvider value={`${basePath}/${academicYearId}`}>
      <Box>
        <Header title={markingPeriodProvider ? t('Edit Marking Periods') : t('Add Marking Periods')} close small />
        <Divider />
        <CardContent component="form" onSubmit={handleSubmit(onSubmit, onError)} noValidate sx={{ minHeight: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      autoFocus
                      fullWidth
                      size="small"
                      label={t('Name')}
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                      {...field}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: '-10px' }}>
              <Typography variant="caption" color="textSecondary">
                {t('Apply Marking Periods')}
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} style={{ marginTop: '-20px' }}>
              <Box display="flex" justifyContent="left">
                <Controller
                  control={control}
                  name="destination"
                  render={({ field }) => (
                    <RadioGroup
                      row
                      sx={{ ml: 2 }}
                      value={field.value}
                      onChange={e => field.onChange(e.target.value === 'schoolYear')}
                    >
                      <FormControlLabel
                        value="district"
                        control={
                          <Radio
                            sx={{
                              color: orange[800],
                              '&.Mui-checked': {
                                color: orange[600],
                              },
                            }}
                            onClick={() => setDestination('district')}
                            checked={destination === 'district'}
                          />
                        }
                        label={t('District(s)')}
                      />
                      <FormControlLabel
                        value="campus"
                        control={
                          <Radio
                            sx={{
                              color: orange[800],
                              '&.Mui-checked': {
                                color: orange[600],
                              },
                            }}
                            onClick={() => setDestination('campus')}
                            checked={destination === 'campus'}
                          />
                        }
                        label={t('Campus(es)')}
                      />
                    </RadioGroup>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} style={{ marginTop: '-5px' }}>
              <Controller
                name="destinationIds"
                control={control}
                render={({ field: { value, ...field }, fieldState }) => {
                  return (
                    <FormControl sx={{ width: '100%', mt: 1 }}>
                      <InputLabel id="multiple-chip-label" sx={{ width: 300, mb: 1, top: '-7px' }}>
                        {destination === 'district' ? t('Select District') : t('Select Campus')}
                      </InputLabel>
                      <Select
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        size="small"
                        multiple
                        label={destination === 'district' ? t('Select District') : t('Select Campus')}
                        error={!!fieldState.error}
                        value={value || []}
                        {...field}
                        renderValue={selected => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected?.map(select => {
                              const found =
                                destination === 'district'
                                  ? districts?.find(d => d.id === select)
                                  : campuses?.find(d => d.id === select)
                              return found ? (
                                <Chip
                                  key={select}
                                  variant="outlined"
                                  label={`${found.name}`}
                                  onClick={() => handleClick(select)}
                                  onDelete={() => handleDelete(select)}
                                  onMouseDown={event => {
                                    event.stopPropagation()
                                  }}
                                />
                              ) : null
                            })}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {destination === 'district'
                          ? districts?.map(item => (
                              <MenuItem key={item?.id} value={item?.id}>
                                <Checkbox checked={value?.indexOf(item?.id) > -1} color="primary" />
                                <ListItemText primary={item?.name} />
                              </MenuItem>
                            ))
                          : schools?.map(item => {
                              const items = item.campuses.map((p, index) => {
                                return (
                                  <MenuItem key={index} value={p.id}>
                                    <Checkbox checked={value?.indexOf(p?.id) > -1} color="primary" />
                                    <ListItemText primary={p?.name} />
                                  </MenuItem>
                                )
                              })

                              return [
                                <MenuItem key={item} value={item.id}>
                                  {`${item.name}`}
                                </MenuItem>,
                                ...items,
                              ]
                            })}
                      </Select>
                      <IconButton
                        aria-label="clear all"
                        onClick={() => {
                          field.onChange([])
                        }}
                        sx={{
                          position: 'absolute',
                          right: 30,
                          top: 0,
                          bottom: 0,
                          margin: 'auto',
                          width: 40,
                          height: 40,
                        }}
                      >
                        <div>
                          <ClearIcon sx={{ fontSize: 25, mt: 0.5 }} />
                        </div>
                      </IconButton>
                    </FormControl>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body1" sx={{ my: 1 }}>
                {t('Marking Period Dates')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MarkingPeriodList
                control={control}
                markingPeriodProvider={markingPeriodId !== 'new' ? markingPeriodProvider : newMarkingPeriodProvider}
                errors={errors}
                path="markingPeriods"
                {...markingPeriodsControl}
              />
            </Grid>
          </Grid>
          <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ my: 3 }}>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              size="small"
              disabled={!markingPeriodProvider}
              onClick={() => setConfirm(true)}
              color="error"
            >
              <DeleteIcon />
              {t('Delete')}
            </Button>
            <Button type="submit" variant="contained" size="small" color="primary" disabled={!formState.isDirty}>
              <SaveAltIcon />
              {t('Save')}
            </Button>
          </Stack>
        </CardContent>
      </Box>
      <Confirm open={confirm} onClose={() => setConfirm(false)} onConfirm={onConfirm} />
    </BasePathProvider>
  )
}
