import { Route, Routes } from 'react-router-dom'
import IncidentsPage from './Page'

export default function Incidents() {
  return (
    <Routes>
        <Route path="/" element={<IncidentsPage />} />
        <Route path="/:id" element={<IncidentsPage />} />
    </Routes>
  )
}
