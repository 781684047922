import { Box, Grid, MenuItem, Stack, TextField } from '@mui/material'
import Header from 'components/Header'
import { BasePathProvider } from 'components/Providers'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { SaveAltIcon } from 'components/Icons'
import { SubmitButton } from 'components/Buttons'
import { createCampusEnrollment, updateCampusEnrollment } from 'store/person/enrollment'
import { useDispatch } from 'react-redux'

const initialValues = {
  campusId: null,
  startDate: null,
  endDate: null,
  jobTitleId: null,
  permissionId: null,
}

const jobTitles = [
  {
    id: 1,
    name: 'J1',
  },
]

const permissions = [
  {
    id: 1,
    name: 'Per 1',
  },
]

export default function EnrollmentsForm({ campusEnrollments, data, campuses }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { personId } = useParams()
  const basePath = `/person/${personId}/campus/`

  const schema = yup.object().shape({
    campusId: yup.number().required(t('required')),
  })

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: data
      ? {
          ...data,
        }
      : { ...initialValues, startDate: dayjs(new Date().now) },
  })

  const onSubmit = async formData => {
    if (data?.id && data?.id !== 'new') {
      const res = await dispatch(
        updateCampusEnrollment({
          personId: +personId,
          id: +data?.id,
          startDate: formData.startDate,
          endDate: formData.endDate,
        }),
      )
      if (res.payload) {
        navigate(basePath)
      }
    } else {
      const res = await dispatch(
        createCampusEnrollment({
          personId: +personId,
          campusId: formData.campusId,
          startDate: formData.startDate,
          endDate: null,
        }),
      )
      if (res.payload) {
        navigate(basePath)
      }
    }
  }

  const onErrors = errors => console.log(errors)

  if (!campuses) return null

  let campusList = []

  if (data?.id && data?.id !== 'new') {
    campusList = campuses
  } else {
    campuses.forEach(camp => {
      const temp = campusEnrollments.find(ce => ce.campusId === camp.id && ce.endDate === null)
      if (!temp) campusList.push(camp)
    })
  }

  return (
    <BasePathProvider value={`${basePath}`}>
      <Box
        data-component="CampusEnrollmentForm"
        component="form"
        onSubmit={handleSubmit(onSubmit, onErrors)}
        noValidate
        sx={{ overflowY: 'scroll', border: 'none' }}
      >
        <Header title={t('Campus Information')} small close borderBottom />
        <Grid container spacing={2} sx={{ p: 2 }}>
          <Grid item xs={12} md={12}>
            <Controller
              name="campusId"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    disabled={data ? true : false}
                    size="small"
                    fullWidth
                    select
                    label={t('Campuses')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {campusList.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Controller
              name="startDate"
              control={control}
              render={({ field, fieldState }) => {
                const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                return (
                  <DatePicker
                    sx={datePickerSx}
                    slotProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                      textField: { size: 'small' },
                      field: {
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      },
                    }}
                    label={t('Start Date')}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    value={field.value && dayjs(field.value)}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="endDate"
              control={control}
              render={({ field, fieldState }) => {
                const datePickerSx = fieldState.error ? { label: { color: 'red' } } : {}
                return (
                  <DatePicker
                    sx={datePickerSx}
                    slotProps={{
                      actionBar: {
                        actions: ['clear'],
                      },
                      textField: { size: 'small' },
                      field: {
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                      },
                    }}
                    label={t('End Date')}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                    value={field.value && dayjs(field.value)}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="jobTitleId"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField size="small" fullWidth select label={t('Job Title')} error={!!fieldState.error} {...field}>
                    {jobTitles.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Controller
              name="permissionId"
              control={control}
              render={({ field: { ...field }, fieldState }) => {
                return (
                  <TextField
                    size="small"
                    fullWidth
                    select
                    label={t('Permissions')}
                    error={!!fieldState.error}
                    {...field}
                  >
                    {permissions.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
              }}
            />
          </Grid>
        </Grid>
        <Stack justifyContent={'flex-end'} flexDirection={'row'} sx={{ mr: 2 }}>
          <SubmitButton
            color="primary"
            icon={<SaveAltIcon />}
            title={t('SAVE')}
            onClick={handleSubmit(onSubmit, onErrors)}
          />
        </Stack>
      </Box>
    </BasePathProvider>
  )
}
