import { Grid, Table, TableBody, TableHead, TableRow, Typography, Box, Link } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { Link as NavLink } from 'react-router-dom'

export default function FinalGrade({
  courseSessionEnrollmentGrades,
  markingPeriods,
  hasCourseSessionViewPermission,
  gpas,
  gradingScales,
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  const StyledTableHeadCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      borderBottom: '1px solid primary',
      width: '5em',
    },
  }))

  const StyledEmptyTableBodyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: theme.palette.mode === 'dark' ? '#222222' : theme.palette.action.disabled,
      borderRight: '1px solid #fff',
    },
  }))
  const StyledTableBodyCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width: '5em',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      borderBottom: '1px solid primary',
    },
  }))

  if (!(courseSessionEnrollmentGrades && markingPeriods)) return null

  const findGradeLetter = grade => {
    if (grade || grade === 0) {
      return gradingScales?.find(item => grade >= item.minGrade && grade <= item.maxGrade)?.letterGrade || ''
    } else return null
  }

  return (
    <Box container spacing={2} sx={{ margin: 2 }}>
      <Grid item xs={12} md={12}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell xs={1} style={{ width: '23em' }} className="font-weight-500">
                {t('Course Name')}
              </StyledTableHeadCell>
              <StyledTableHeadCell xs={1} className="font-weight-500">
                {t('Credit')}
              </StyledTableHeadCell>
              {markingPeriods.map(mp => (
                <StyledTableHeadCell key={mp.id} xs={1} className="font-weight-500">
                  {mp.name}
                </StyledTableHeadCell>
              ))}
              <StyledTableHeadCell xs={1} style={{ borderLeft: '1px solid primary' }} className="font-weight-500">
                {t('Final Grade')}
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courseSessionEnrollmentGrades?.map(item => (
              <StyledTableRow key={item.id}>
                <StyledTableBodyCell xs={1} sx={{ width: '23em' }}>
                  {hasCourseSessionViewPermission ? (
                    <Link
                      component={NavLink}
                      to={`/settings/course-session/${item.courseSession.id}`}
                      underline="hover"
                      color="inherit"
                    >
                      {`${item.courseSession.course.name} - ${item.courseSession.name}`}
                    </Link>
                  ) : (
                    <>{`${item.courseSession.course.name} - ${item.courseSession.name}`}</>
                  )}
                </StyledTableBodyCell>
                <StyledTableBodyCell xs={1}>
                  {item.courseSession.credit || item.courseSession.course.credit}
                </StyledTableBodyCell>
                {markingPeriods?.map(mp =>
                  item.markingPeriodGradeList.find(el => el.markingPeriodId === mp.id)?.grade === undefined ? (
                    <StyledEmptyTableBodyCell key={mp.id}>
                      {item.markingPeriodGradeList.find(el => el.markingPeriodId === mp.id)?.grade || ''}
                    </StyledEmptyTableBodyCell>
                  ) : (
                    <StyledTableBodyCell key={mp.id}>
                      {findGradeLetter(item.markingPeriodGradeList.find(el => el.markingPeriodId === mp.id)?.grade)}{' '}
                      {(item.markingPeriodGradeList.find(el => el.markingPeriodId === mp.id)?.grade ||
                        item.markingPeriodGradeList.find(el => el.markingPeriodId === mp.id)?.grade === 0) &&
                        `(${item.markingPeriodGradeList.find(el => el.markingPeriodId === mp.id)?.grade})`}
                    </StyledTableBodyCell>
                  ),
                )}
                <StyledTableBodyCell
                  sx={{
                    borderLeft: '1px solid primary',
                  }}
                >
                  {(item.grade || item.grade === 0) && findGradeLetter(Math.round(item.grade))}
                  {((item.grade || item.grade === 0) && `(${Math.round(item.grade)})`) || ''}
                </StyledTableBodyCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableRow sx={{ borderBottom: 'none' }}>
            <StyledTableBodyCell xs={1} style={{ width: '23em' }} sx={{ borderBottom: 'none' }}>
              <Typography className="font-weight-500">{t('Average GPA and Credit Total')}:</Typography>
            </StyledTableBodyCell>
            <StyledTableBodyCell xs={1} sx={{ borderBottom: 'none' }}>
              <Typography className="font-weight-500">
                {courseSessionEnrollmentGrades
                  .reduce((acc, item) => acc + (item.courseSession.credit || item.courseSession.course.credit), 0)
                  .toFixed(2)}
              </Typography>
            </StyledTableBodyCell>
            {markingPeriods.map((item, index) => (
              <StyledTableBodyCell key={index} xs={1} sx={{ borderBottom: 'none' }}>
                <Typography className="font-weight-500">
                  {(!isNaN(gpas.markingPeriods.find(mp => mp.id === item.id)?.gpa) &&
                    gpas.markingPeriods.find(mp => mp.id === item.id)?.gpa) ||
                    ''}
                </Typography>
              </StyledTableBodyCell>
            ))}
            <StyledTableBodyCell xs={1} style={{ borderLeft: '1px solid primary' }} sx={{ borderBottom: 'none' }}>
              <Typography className="font-weight-500">{!isNaN(gpas.gpa) && gpas.gpa}</Typography>
            </StyledTableBodyCell>
          </TableRow>
        </Table>
      </Grid>
    </Box>
  )
}
